import {
  Button,
  Card,
  Collapse,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  acceptQuestion,
  rejectQuestion,
  selectQuestionById
} from '../../store/slices/outcomeSlice';
import SmallQuestionCard from '../cards/SmallQuestionCard';

export default function SetResolvableModal({ shown, close, questionId }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const [resolvable, setResolvable] = useState(null);
  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );
  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [rejectionComment, setRejectionComment] = useState('');
  const userId = userData.id;
  const theme = useTheme();
  const closeModal = () => {
    close();
  };

  const changeRejectionComment = (event) =>
    setRejectionComment(event.target.value);

  const canAcceptResolvable =
    [questionId, userId, resolvable, checkTokenStatus()].every(Boolean) &&
    updateRequestStatus === 'idle';

  const acceptResolvable = async () => {
    if (canAcceptResolvable) {
      let isMounted = true;
      try {
        setUpdateRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: questionId,
          resolvable: resolvable,
          auth_token: token
        };
        await dispatch(acceptQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setResolvable(null);
              close();
            }
          });
        close();
      } catch (err) {
        setErrorMessage(
          `Failed to accept resolvability of question: ${err.message}`
        );
      } finally {
        if (isMounted) {
          setUpdateRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (resolvable === null) {
      setErrorMessage(
        'Please select either resolvable or not resolvable before confirming.'
      );
    } else {
      setErrorMessage('Question resolvability could not be set.');
    }
  };

  const canRejectResolvable =
    [questionId, userId, rejectionComment, checkTokenStatus()].every(Boolean) &&
    resolvable === false &&
    updateRequestStatus === 'idle';

  const rejectResolvable = async () => {
    if (canRejectResolvable) {
      let isMounted = true;
      try {
        setUpdateRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: questionId,
          resolvable: resolvable,
          commentText: rejectionComment,
          auth_token: token
        };
        await dispatch(rejectQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setResolvable(null);
              close();
            }
          });
        close();
      } catch (err) {
        setErrorMessage(
          `Failed to reject resolvability of question: ${err.message}`
        );
      } finally {
        if (isMounted) {
          setUpdateRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (resolvable === null) {
      setErrorMessage(
        'Please select either resolvable or not resolvable before confirming.'
      );
    } else if (!rejectionComment) {
      setErrorMessage(
        'Please provide a rejection comment in order to reject the question.'
      );
    } else {
      setErrorMessage('Question resolvability could not be set.');
    }
  };

  const confirmResolvable = async () => {
    if (resolvable === true) {
      acceptResolvable();
    } else if (resolvable === false) {
      rejectResolvable();
    } else {
      setErrorMessage(
        'Please select either resolvable or not resolvable before confirming.'
      );
    }
  };

  return shown ? (
    <div
      className="modal-backdrop"
      onClick={() => {
        // close modal when outside of modal is clicked
      }}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontWeight: 700, fontSize: '1.1rem' }}
            className="text-center">
            Set Resolvability of this Question
          </Typography>
          {errorMessage && (
            <Typography color="error">{errorMessage}</Typography>
          )}
          <div className="flex justify-center">
            <div className="break-words text-xs font-medium m-1 my-2">
              <ul className="list-disc">
                <li>
                  <Typography sx={{ fontSize: '0.8rem' }}>
                    Please indicate whether this question is resolvable.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ fontSize: '0.8rem' }}>
                    The resolvability of a question determines whether it can be
                    used in a forecasting tournament or not.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ fontSize: '0.8rem' }}>
                    The question must be unambiguously resolvable and include a
                    source to an authority that can determine the resolution.
                  </Typography>
                </li>
                <li>
                  <Typography sx={{ fontSize: '0.8rem' }}>
                    The wording of the question must pass the 'clairvoyance
                    test', which means that if you knew all relevant future
                    information, there can be no ambiguity about whether the
                    scenario described has occured.
                  </Typography>
                </li>
              </ul>
            </div>
          </div>

          <div className="break-words font-medium mt-1">
            <Typography sx={{ fontWeight: 400 }} color="primary.main">
              Question
            </Typography>
            <div className="border-2 rounded">
              <SmallQuestionCard questionId={questionId} />
            </div>
          </div>
          <div className="flex justify-center mt-2">
            <div className="w-full m-1 mx-4">
              <Button
                sx={{
                  borderRadius: '0.5rem',
                  p: '0.125rem'
                }}
                onClick={() => setResolvable(true)}
                className={`relative inline-flex items-center justify-center p-0.5 w-full overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white ${
                  resolvable ? 'ring-4 ring-cyan-300' : ''
                }`}>
                <span
                  className={`relative px-4 py-2 w-full transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0 ${
                    resolvable
                      ? 'bg-gradient-to-br from-cyan-500 to-blue-500 text-white'
                      : `bg-[${theme.palette.background.paper}]`
                  }`}>
                  Resolvable
                </span>
              </Button>
            </div>
            <div className="w-full m-1 mx-4">
              <Button
                sx={{
                  borderRadius: '0.5rem',
                  p: '0.125rem'
                }}
                onClick={() => setResolvable(false)}
                className={`relative inline-flex items-center justify-center p-0.5 w-full overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-amber-700 to-red-500 group-hover:from-amber-700 group-hover:to-red-500 hover:text-white ${
                  resolvable === false ? 'ring-4 ring-red-400' : ''
                }`}>
                <span
                  className={`relative px-4 py-2 w-full transition-all ease-in duration-75 rounded-md group-hover:bg-opacity-0 ${
                    resolvable === false
                      ? 'bg-gradient-to-br from-amber-700 to-red-500 text-white'
                      : `bg-[${theme.palette.background.paper}]`
                  }`}>
                  Not Resolvable
                </span>
              </Button>
            </div>
          </div>
          <Collapse className="w-full" in={resolvable === false}>
            <div className="mt-2">
              <h3 className="text-sm font-semibold">Rejection Comment</h3>
              <TextField
                multiline
                variant="outlined"
                placeholder="Justify why this question is being rejected."
                value={rejectionComment}
                onChange={(event) => changeRejectionComment(event)}
                minRows="3"
                className="resize-none w-full my-2 p-2 rounded border-solid border-2"
                sx={{ fontSize: '0.855rem' }}></TextField>
            </div>
          </Collapse>
          <div className="flex justify-end mt-2">
            <div className="mx-2">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'gray',
                  ':hover': { backgroundColor: '#757575' }
                }}
                onClick={closeModal}
                className="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded">
                Cancel
              </Button>
            </div>
            <div className="mx-2">
              <Button
                variant="contained"
                onClick={confirmResolvable}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  ) : null;
}
