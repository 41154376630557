import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import {
  setOutcomeFilters,
  toggleOutcomeFilters
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';

export default function OutcomeStatusFilter({ className }) {
  const dispatch = useDispatch();
  const { userData } = useContext(AuthContext);
  const generationChecked = useSelector(
    (state) => state.outcomes.outcomes.filters.generation
  );
  const moderationChecked = useSelector(
    (state) => state.outcomes.outcomes.filters.moderation
  );
  const evaluationChecked = useSelector(
    (state) => state.outcomes.outcomes.filters.evaluation
  );
  const forecastingChecked = useSelector(
    (state) => state.outcomes.outcomes.filters.forecasting
  );
  const closedChecked = useSelector(
    (state) => state.outcomes.outcomes.filters.closed
  );
  const statusSetting = useSelector((state) =>
    selectSettingByName(state, 'Default Outcome Status')
  );
  const moderationSettings = useSelector((state) =>
      selectSettingByName(state, 'Moderation Phase')
  );

  const toggleOutcomeFilter = (payload) => (dispatch, getState) => {
    dispatch(toggleOutcomeFilters(
      payload
    ));
    
    const currentState = getState().outcomes;
    const updatedPayload = currentState.outcomes.filters;
    window.sessionStorage.setItem("status-selections", JSON.stringify(updatedPayload));
  }

  useEffect(() => {
    if (window.sessionStorage.getItem("status-selections")) {
      dispatch(setOutcomeFilters(JSON.parse(window.sessionStorage.getItem("status-selections"))))
    } else if (statusSetting) {
      let payload = {};
      for (const option of statusSetting.options) {
        payload[option.name] = option.selected;
      }
      dispatch(setOutcomeFilters(payload));
    }
  }, [statusSetting]);

  const filterClick = (event) => {
    switch (event.target.value) {
      case 'generation':
        dispatch(toggleOutcomeFilter({ toggleGeneration: true }));
        break;
      case 'moderation':
        dispatch(toggleOutcomeFilter({ toggleModeration: true }));
        break;
      case 'evaluation':
        dispatch(toggleOutcomeFilter({ toggleEvaluation: true }));
        break;
      case 'forecasting':
        dispatch(toggleOutcomeFilter({ toggleForecasting: true }));
        break;
      case 'closed':
        dispatch(toggleOutcomeFilter({ toggleClosed: true }));
        break;
      default:
        break;
    }
  };

  return (
    <div className={`flex text-lg items-center ${className}`}>
      <FormGroup sx={{ display: 'flex', justifyContent: 'center' }} row>
        <FormControlLabel
          sx={{ mx: 0.3 }}
          control={
            <Checkbox
              checked={!!generationChecked}
              onChange={(event) => filterClick(event)}
              value={'generation'}
              style={{ marginRight: '0px' }}
              sx={{
                color: 'statuses.dark1',
                '&.Mui-checked': {
                  color: 'statuses.mid1'
                },
                p: 0.5
              }}
            />
          }
          label="Generation"
        />
        {(userData.role === 'Admin' || userData.role === 'Moderator') &&
        moderationSettings ? (
          moderationSettings.active && (
            <FormControlLabel
              sx={{ mx: 0.3 }}
              control={
                <Checkbox
                  checked={!!moderationChecked}
                  onChange={(event) => filterClick(event)}
                  value={'moderation'}
                  style={{ marginRight: '0px' }}
                  sx={{
                    color: 'statuses.dark9',
                    '&.Mui-checked': {
                      color: 'statuses.mid9'
                    },
                    p: 0.5
                  }}
                />
              }
              label="Moderation"
            />
          )
        ) : (
          <></>
        )}
        <FormControlLabel
          sx={{ mx: 0.3 }}
          control={
            <Checkbox
              checked={!!evaluationChecked}
              onChange={(event) => filterClick(event)}
              value={'evaluation'}
              style={{ marginRight: '0px' }}
              sx={{
                color: 'statuses.dark2',
                '&.Mui-checked': {
                  color: 'statuses.mid2'
                },
                p: 0.5
              }}
            />
          }
          label="Evaluation"
        />
        <FormControlLabel
          sx={{ mx: 0.3 }}
          control={
            <Checkbox
              checked={!!forecastingChecked}
              onChange={(event) => filterClick(event)}
              value={'forecasting'}
              style={{ marginRight: '0px' }}
              sx={{
                color: 'statuses.dark3',
                '&.Mui-checked': {
                  color: 'statuses.mid3'
                },
                p: 0.5
              }}
            />
          }
          label="Forecasting"
        />
        <FormControlLabel
          sx={{ mx: 0.3 }}
          control={
            <Checkbox
              checked={!!closedChecked}
              onChange={(event) => filterClick(event)}
              value={'closed'}
              style={{ marginRight: '0px' }}
              sx={{
                color: 'statuses.dark4',
                '&.Mui-checked': {
                  color: 'statuses.mid4'
                },
                p: 0.5
              }}
            />
          }
          label="Closed"
        />
      </FormGroup>
    </div>
  );
}
