import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { FiInfo } from "react-icons/fi"

export default function InfoTooltip({ text }) {
    return (
        <div>
            <Tooltip title={text} placement="bottom">
                <div><FiInfo stroke="#9ca3af"/></div>
            </Tooltip>
        </div>
    )
}
