import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { AuthContext, checkTokenStatus } from '../../App';
import CreateCommentModal from '../../components/modals/CreateCommentModal';
import AddEvaluationModal from '../modals/AddEvaluationModal';
import ConfirmationModal from '../modals/ConfirmationModal';
import SetDuplicateModal from '../modals/SetDuplicateModal';
import SetRelevantModal from '../modals/SetRelevantModal';
import SetResolutionImpactModal from '../modals/SetResolutionImpactModal';
import SetResolvableModal from '../modals/SetResolvableModal';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteQuestion,
  fetchOutcomes,
  selectEvaluationByQuestionUser,
  selectOutcomeById,
  selectQuestionById,
  selectResolutionEvaluationByQuestionUser,
  updateQuestion,
  updateSelectedQuestion
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';

import { SsidChartOutlined } from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import ukLocale from 'date-fns/locale/en-GB';
import { BsPlusSlashMinus } from 'react-icons/bs';

import { BiDuplicate } from 'react-icons/bi';
import { FaAsterisk, FaSpellCheck } from 'react-icons/fa';
import {
  FiCheck,
  FiDelete,
  FiEdit,
  FiLink,
  FiStar,
  FiTarget
} from 'react-icons/fi';
import { GoComment } from 'react-icons/go';
import { MdSpeed } from 'react-icons/md';
import { TbLayoutAlignMiddle } from 'react-icons/tb';

import {
  Button,
  Card,
  Divider,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { ClipLoader } from 'react-spinners';
import RoleBadgeIcon from '../icons/RoleBadgeIcon';

export default function ClickableQuestionCard({
  questionId,
  isLoggedIn,
  isVotingDisabled,
  setIsVotingDisabled,
  detail
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const userId = userData.id;
  const navigate = useNavigate();
  const theme = useTheme();
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );
  const usersData = useSelector((state) =>
    state.users.entities
  );
  const [questionText, setQuestionText] = useState(null);
  const [updateQuestionRequestStatus, setUpdateQuestionRequestStatus] =
    useState('idle');
  const [deleteQuestionRequestStatus, setDeleteQuestionRequestStatus] =
    useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [showAddComment, setShowAddComment] = useState(false);
  const [showAddEvaluation, setShowAddEvaluation] = useState(false);
  const [showSetResolvableModal, setShowSetResolvableModal] = useState(false);
  const [showSetRelevantModal, setShowSetRelevantModal] = useState(false);
  const [showSetDuplicateModal, setShowSetDuplicateModal] = useState(false);
  const [editCard, setEditCard] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const evaluation = useSelector((state) =>
    selectEvaluationByQuestionUser(state, questionId, userId)
  );
  const outcome = useSelector((state) =>
    selectOutcomeById(state, question.outcome_id)
  );
  const outcomeStatus = useSelector(
    (_state) => _state.outcomes.outcomes.status
  );
  const [resolutionCriteria, setResolutionCriteria] = useState(null);
  const [source, setSource] = useState(null);
  const [relevanceReason, setRelevanceReason] = useState(null);
  const [resolutionDate, setResolutionDate] = useState(null);
  const [resolutionSubmitter, setResolutionSubmitter] = useState(null);
  const [showResolutionImpactModal, setShowResolutionImpactModal] =
    useState(false);

  useEffect(() => {
    // check if auth token is valid
    if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      setIsVotingDisabled(true);
      return <Navigate to={'/login'} />;
    } else {
      setIsLoggedIn(true);
    }
  }, [setIsLoggedIn, setIsVotingDisabled]);

  useEffect(() => {
    let isMounted = true;
    function fetchOutcomeData() {
      if (isMounted) {
        if (outcomeStatus === 'idle') {
          const token = localStorage.getItem('auth_token');
          dispatch(fetchOutcomes({ auth_token: token }));
        }
      }
    }
    fetchOutcomeData();
    return () => {
      isMounted = false;
    };
  }, [outcomeStatus, dispatch, outcome]);

  const openQuestionView = (id) => {
    navigate(`/questions/${id}`);
  };

  const createComment = () => {
    setShowAddComment(true);
  };

  const changeQuestionText = (event) => {
    setQuestionText(event.target.value);
  };
  const changeSource = (event) => setSource(event.target.value);
  const changeResolutionCriteria = (event) =>
    setResolutionCriteria(event.target.value);
  const changeRelevanceReason = (event) =>
    setRelevanceReason(event.target.value);

  const clickAddEvaluation = () => {
    setShowAddEvaluation(true);
  };

  const replyClick = () => {
    dispatch(updateSelectedQuestion(question.id));
    createComment();
  };

  const impactEvaluations = useSelector((state) =>
    selectResolutionEvaluationByQuestionUser(state, questionId, userId)
  );

  const resolutionSettings = useSelector((state) =>
    selectSettingByName(state, 'Resolution Impact Decider')
  );

  useEffect(() => {
    if (question.question_text) {
      setQuestionText(question.question_text);
    }
  }, [question.question_text]);

  useEffect(() => {
    if (question.resolution_criteria) {
      setResolutionCriteria(question.resolution_criteria);
    }
  }, [question.resolution_criteria]);

  useEffect(() => {
    if (question.source) {
      setSource(question.source);
    }
  }, [question.source]);

  useEffect(() => {
    if (question.relevance_reason) {
      setRelevanceReason(question.relevance_reason);
    }
  }, [question.relevance_reason]);

  useEffect(() => {
    if (question.resolution_date) {
      setResolutionDate(parseISO(question.resolution_date));
    }
  }, [question.resolution_date]);

  const canUpdateQuestion =
    [
      question.id,
      questionText ||
        resolutionCriteria ||
        source ||
        resolutionDate ||
        relevanceReason,
      checkTokenStatus()
    ].every(Boolean) && updateQuestionRequestStatus === 'idle';

  const canDeleteQuestion =
    [question.id, checkTokenStatus()].every(Boolean) &&
    deleteQuestionRequestStatus === 'idle';

  const deleteQuestionData = async () => {
    if (canDeleteQuestion) {
      let isMounted = true;
      try {
        setDeleteQuestionRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: question.id,
          auth_token: token
        };
        await dispatch(deleteQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              if (editCard) {
                setEditCard(!editCard);
              }
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to delete question: ${err.message}`);
      } finally {
        if (isMounted) {
          setDeleteQuestionRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Question could not be deleted.');
    }
  };

  const updateQuestionData = async () => {
    if (canUpdateQuestion) {
      let isMounted = true;
      try {
        setUpdateQuestionRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: question.id,
          auth_token: token
        };
        if (questionText) {
          payload.question_text = questionText;
        }
        if (resolutionCriteria) {
          payload.resolution_criteria = resolutionCriteria;
        }
        if (source) {
          payload.source = source;
        }
        if (resolutionDate) {
          payload.resolution_date = resolutionDate;
        }
        if (relevanceReason) {
          payload.relevance_reason = relevanceReason;
        }
        if (
          question.status === 'Rejected' &&
          question.created_by.username === userData.username
        ) {
          payload.reset_pending = true;
        }

        await dispatch(updateQuestion(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setEditCard(!editCard);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to update question: ${err.message}`);
      } finally {
        if (isMounted) {
          setUpdateQuestionRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Question could not be updated.');
    }
  };

  const timeSincePosted = () => {
    return moment(question.created_at, 'YYYY-MM-DDThh:mm:ss').fromNow();
  };

  const isEdited = () => {
    return question.modified_at ? true : false;
  };

  const getStatusColour = () => {
    let status =
      question.status !== 'Completed' ? question.status : completedStatusCalc();
    switch (status) {
      case 'Pending':
        return theme.palette.statuses.mid1;
      case 'Rejected':
        return theme.palette.statuses.mid5;
      case 'Not Submitted':
        return theme.palette.statuses.mid2;
      case 'Accepted':
        return theme.palette.statuses.mid3;
      case 'Submitted':
        return theme.palette.statuses.mid3;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Duplicate':
        return theme.palette.statuses.mid6;
      default:
        return theme.palette.statuses.mid7;
    }
  };

  const completedStatusCalc = () => {
    if (outcome !== undefined) {
      if (outcome.status === 'Forecasting') {
        return 'Submitted';
      } else {
        return 'Closed';
      }
    } else {
      return 'Closed';
    }
  };

  const categoryTextColor = (category) => {
    switch (category) {
      case 'Very Low':
        return 'text-red-500';
      case 'Low':
        return 'text-orange-500';
      case 'Medium':
        return 'text-blue-500';
      case 'High':
        return 'text-teal-500';
      case 'Very High':
        return 'text-green-500';
      default:
        return 'bg-blue-500';
    }
  };

  const evaluationScoreCategoryTextColor = (category) => {
    switch (category) {
      case 'Furthest':
        return 'text-red-500';
      case 'Far':
        return 'text-orange-500';
      case 'Average':
        return 'text-blue-500';
      case 'Close':
        return 'text-teal-500';
      case 'Correct':
        return 'text-green-500';
      default:
        return 'bg-blue-500';
    }
  };

  let content;
  let canUserEvaluate =
    outcomeStatus === 'succeeded'
      ? !isVotingDisabled &&
        !evaluation &&
        outcome.status === 'Evaluation' &&
        question.created_by.username !== userData.username
      : false;
  let canRenderCard =
    outcomeStatus === 'succeeded' &&
    question &&
    outcome &&
    (outcome.status !== 'Generation' ||
      question.created_by.username === userData.username ||
      userData.role === 'Moderator' ||
      userData.role === 'Admin' ||
      userData.role === 'Observer' ||
      outcome.created_by.username === userData.username);

  if (usernameSettings === undefined) {
    content = null;
  } else if (outcomeStatus === 'loading') {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (canRenderCard) {
    content = (
      <Card
        id={'questionCard' + questionId}
        sx={{
          transition: '0.3s',
          boxShadow: '0 7px 20px -9px rgba(0,0,0,0.3)',
          '&:hover': {
            boxShadow: '0 14px 37px -9.125px rgba(0,0,0,0.3)',
            bgcolor: 'hover'
          },
          borderLeftWidth: '5px',
          borderLeftColor: getStatusColour()
        }}
        className={`QuestionCard rounded shadow-lg mx-10 my-5 cursor-pointer duration-300`}
        onClick={() => openQuestionView(questionId)}>
        <div className="px-5 pt-3">
          <div className="break-words">
            <>
              <div className="flex items-start justify-between mb-1 max-h-42 overflow-y-auto">
                {editCard ? (
                  <TextField
                    type="text"
                    variant="outlined"
                    value={questionText}
                    sx={{ mx: 0.6 }}
                    inputProps={{ style: { fontSize: '0.875rem' } }}
                    onChange={(event) => changeQuestionText(event)}
                    className="w-full"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                ) : (
                  <Typography sx={{ fontWeight: 600, fontSize: '1.1rem' }}>
                    <span className="whitespace-pre-line">
                      {question.question_text}
                    </span>
                  </Typography>
                )}

                <Typography
                  sx={{
                    borderColor: getStatusColour(),
                    backgroundColor: getStatusColour(),
                    py: 0,
                    px: 0.3,
                    fontSize: '0.9rem'
                  }}
                  className={`border-2 rounded text-white font-normal text-xs px-1 ml-1`}>
                  {question.status !== 'Completed'
                    ? question.status
                    : completedStatusCalc()}
                </Typography>
              </div>
            </>
            <Divider />
            <div className="QuestionInfo flex items-center justify-start ml-2">
              <Typography
                sx={{ fontSize: '0.95rem', mr: 0.6 }}
                color="text.secondary">
                Posted {timeSincePosted()}
              </Typography>
              {(userData.role === 'Admin' ||
                userData.role === 'Moderator' ||
                usernameSettings.active) && (
                <div className="flex items-center">
                  <Typography
                    sx={{ mr: 0.6, fontSize: '0.95rem' }}
                    color="text.secondary">
                    by
                  </Typography>
                  <Typography
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/profile/${question.created_by.username}`);
                    }}
                    sx={{ fontSize: '0.95rem' }}
                    className="hover:underline hover:cursor-pointer"
                    color="primary.main">
                    {question.created_by.username}
                  </Typography>
                  <div className="pb-1">
                  <RoleBadgeIcon role={usersData[question.created_by.id].role} />
                </div>
                </div>
              )}
              {isEdited() && (
                <div className="mr-1">
                  <Tooltip title="Edited">
                    <div>
                      <FaAsterisk className="py-1" />
                    </div>
                  </Tooltip>
                </div>
              )}
              {question.resolvable && (
                <div className="mr-1">
                  <Tooltip title="Is Resolvable">
                    <div>
                      <FiCheck />
                    </div>
                  </Tooltip>
                </div>
              )}
              {question.relevant && (
                <div className="mr-1">
                  <Tooltip title="Is Relevant" placement="bottom">
                    <div>
                      <FiCheck />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </div>
        {question.status === 'Duplicate' && (
          <div className="px-5">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                openQuestionView(question.parent_question_id);
              }}>
              Duplicate Question
            </Button>
          </div>
        )}

        {!detail && (
          <div className="flex pb-1 pt-1 items-center justify-between">
            <div className="ml-5 flex flex-wrap">
              {canUserEvaluate && (
                <div className="flex items-center mr-2">
                  <Button
                    disabled={!isLoggedIn}
                    onClick={(e) => {
                      e.stopPropagation();
                      clickAddEvaluation();
                    }}>
                    <MdSpeed className="mr-1 mb-0.5" />
                    Add Evaluation
                  </Button>
                </div>
              )}

              {question.question_score && (
                <div className="flex items-center mr-2">
                  <FiStar className="mr-1 pb-0.5" fill="black" />
                  <Typography sx={{ mr: 0.6 }}>Score:</Typography>
                  <Typography
                    sx={{ fontWeight: 500 }}
                    className={`${categoryTextColor(
                      question.question_score_category.name
                    )}`}>
                    {question.question_score_category.name +
                      ' (' +
                      (question.question_score * 100).toFixed(2) +
                      '%)'}
                  </Typography>
                </div>
              )}

              {evaluation &&
                evaluation.category &&
                outcome.status !== 'Forecasting' &&
                outcome.status !== 'Closed' && (
                  <div className="flex items-center mr-2">
                    <MdSpeed className="mr-1 mb-0.5" />
                    <Typography sx={{ mr: 0.6 }}>Your Evaluation: </Typography>
                    <Typography
                      className={`${categoryTextColor(
                        evaluation.category.name
                      )}`}>
                      {evaluation.category.name}
                    </Typography>
                  </div>
                )}

              {evaluation &&
                evaluation.evaluation_score !== null &&
                evaluation.evaluation_score !== undefined && (
                  <div className="flex items-center mr-2">
                    <FiStar className="mr-1 pb-0.5" />
                    <Typography sx={{ mr: 0.6 }}>
                      Your {evaluation.evaluation_score_type} Evaluation Score:{' '}
                    </Typography>
                    <Typography
                      className={`${evaluationScoreCategoryTextColor(
                        evaluation.evaluation_score_category.name
                      )}`}>
                      {evaluation.evaluation_score_category.name +
                        ' (' +
                        evaluation.evaluation_score +
                        ')'}
                    </Typography>
                  </div>
                )}

              {question.average_evaluation !== undefined &&
                question.average_evaluation !== 0 &&
                question.average_evaluation_category &&
                (evaluation ||
                  question.created_by.username === userData.username ||
                  outcome.created_by.username === userData.username ||
                  userData.role === 'Admin' ||
                  userData.role === 'Moderator') &&
                outcome.status !== 'Forecasting' &&
                outcome.status !== 'Closed' && (
                  <div className="flex items-center mr-2">
                    <TbLayoutAlignMiddle className="mr-1" />
                    <Typography sx={{ mr: 0.6 }}>
                      Community Evaluation:{' '}
                    </Typography>
                    <Typography
                      className={`${categoryTextColor(
                        question.average_evaluation_category.name
                      )}`}>
                      {question.average_evaluation_category.name +
                        ' (' +
                        Math.round(question.average_evaluation * 10) / 10 +
                        ')'}
                    </Typography>
                  </div>
                )}
              {(evaluation ||
                question.created_by.username === userData.username ||
                question.status === 'Submitted' ||
                question.status === 'Rejected' ||
                question.status === 'Closed' ||
                question.status === 'Completed' ||
                userData.role === 'Moderator' ||
                userData.role === 'Admin') && (
                <div className="flex items-center mr-2">
                  <Button
                    disabled={!isLoggedIn}
                    onClick={(e) => {
                      e.stopPropagation();
                      replyClick();
                    }}>
                    <GoComment className="mr-1 pl-0.5" />
                    <span>Add a comment</span>
                  </Button>
                </div>
              )}
              {impactEvaluations &&
                impactEvaluations.resolution_impact !== undefined &&
                resolutionSettings.options.find(
                  (setting) => setting.name === 'questioner'
                ).selected === true &&
                outcome.status !== 'Forecasting' &&
                outcome.status !== 'Closed' && (
                  <div className="flex items-center mr-2">
                    <SsidChartOutlined sx={{ fontSize: '1.1rem', mr: 0.6 }} />
                    <Typography sx={{ mr: 0.6 }}>
                      Your Resolution Impact Evaluation:{' '}
                    </Typography>
                    <Typography
                      className={`${
                        impactEvaluations.resolution_impact
                          ? 'text-green-500'
                          : 'text-red-500'
                      }`}>
                      {impactEvaluations.resolution_impact
                        ? 'Positive'
                        : 'Negative'}
                    </Typography>
                  </div>
                )}

              {!impactEvaluations &&
                userData.role === 'Questioner' &&
                question.created_by.username !== userData.username &&
                resolutionSettings.options.find(
                  (setting) => setting.name === 'questioner'
                ).selected === true &&
                outcome.status === 'Evaluation' && (
                  <div className="flex items-center mr-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setResolutionSubmitter('questioner');
                        setShowResolutionImpactModal(true);
                      }}>
                      <BsPlusSlashMinus className="mr-1 pl-0.5 pb-0.5" />
                      Add Resolution Impact Evaluation
                    </Button>
                  </div>
                )}

              {question.evaluations.length === 0 &&
                (outcome.status === 'Generation' ||
                  outcome.status === 'Moderation') &&
                !evaluation &&
                (question.created_by.username === userData.username ||
                  userData.role === 'Admin' ||
                  userData.role === 'Moderator') && (
                  <div className="flex items-center mr-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();

                        setEditCard(!editCard);
                      }}
                      className={'inline-flex items-center'}
                      size="small">
                      <FiEdit className="mr-1 pb-0.5" />
                      {editCard ? 'Cancel' : 'Edit'}
                    </Button>
                  </div>
                )}
              {question.created_by.username === userData.username &&
                outcome.status === 'Generation' && (
                  <div className="flex items-center mr-2">
                    <Button
                      onClick={() => {
                        setShowDeleteConfirm(true);
                      }}
                      className={'inline-flex items-center'}
                      size="small">
                      <FiDelete className="mr-1 pl-0.5 pb-0.5" />
                      Delete
                    </Button>
                  </div>
                )}
              {(question.resolvable === null ||
                question.resolvable === undefined) &&
                (outcome.status === 'Generation' ||
                  outcome.status === 'Moderation' ||
                  outcome.status === 'Evaluation') &&
                (userData.role === 'Moderator' ||
                  userData.role === 'Admin') && (
                  <div className="flex items-center mr-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowSetResolvableModal(true);
                      }}
                      className={'inline-flex items-center'}
                      size="small">
                      <FaSpellCheck className="mr-1 pl-0.5 pb-0.5" />
                      Resolvable?
                    </Button>
                  </div>
                )}
              {(question.relevant === null ||
                question.relevant === undefined) &&
                (outcome.status === 'Generation' ||
                  outcome.status === 'Moderation' ||
                  outcome.status === 'Evaluation') &&
                (outcome.created_by.username === userData.username ||
                  userData.role === 'Moderator' ||
                  userData.role === 'Admin') && (
                  <div className="flex items-center mr-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowSetRelevantModal(true);
                      }}
                      className={'inline-flex items-center'}
                      size="small">
                      <FiTarget className="mr-1 pl-0.5 pb-0.5" />
                      Relevant?
                    </Button>
                  </div>
                )}
              {(question.relevant === null ||
                question.relevant === undefined) &&
                (outcome.status === 'Generation' ||
                  outcome.status === 'Moderation' ||
                  outcome.status === 'Evaluation') &&
                (outcome.created_by.username === userData.username ||
                  userData.role === 'Moderator' ||
                  userData.role === 'Admin') && (
                  <div className="flex items-center mr-2">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowSetDuplicateModal(true);
                      }}
                      className={'inline-flex items-center'}
                      size="small">
                      <BiDuplicate className="mr-1 pl-0.5 pb-0.5" />
                      Duplicate?
                    </Button>
                  </div>
                )}
              {editCard && (
                <div className="flex justify-end px-4">
                  <Button
                    variant="contained"
                    onClick={(e) => {
                      e.stopPropagation();
                      updateQuestionData();
                    }}>
                    {question.status === 'Rejected' &&
                    question.created_by.username === userData.username
                      ? 'Resubmit'
                      : 'Save'}
                  </Button>
                </div>
              )}
            </div>
            <Tooltip title="Permalink" placement="bottom">
              <button
                className="text-grey-darkest ml-auto mr-5 text-xs rounded inline-flex items-center justify-center"
                onClick={() => openQuestionView(questionId)}>
                <FiLink className="mr-1" />
              </button>
            </Tooltip>
          </div>
        )}

        {showAddComment && (
          <CreateCommentModal
            shown={showAddComment}
            close={() => {
              setShowAddComment(false);
            }}
            afterSubmit={() => {
              setShowAddComment(false);
            }}
            questionId={question.id}
          />
        )}

        {showResolutionImpactModal && (
          <SetResolutionImpactModal
            shown={showResolutionImpactModal}
            close={() => {
              setShowResolutionImpactModal(false);
            }}
            questionId={questionId}
            submitter={resolutionSubmitter}
          />
        )}

        {showAddEvaluation && (
          <AddEvaluationModal
            shown={showAddEvaluation}
            close={() => {
              setShowAddEvaluation(false);
            }}
            questionId={questionId}
            outcomeId={outcome.id}
          />
        )}

        {showSetResolvableModal && (
          <SetResolvableModal
            shown={showSetResolvableModal}
            close={() => {
              setShowSetResolvableModal(false);
            }}
            questionId={questionId}
          />
        )}

        {showSetRelevantModal && (
          <SetRelevantModal
            shown={showSetRelevantModal}
            close={() => {
              setShowSetRelevantModal(false);
            }}
            questionId={questionId}
          />
        )}
        {showSetDuplicateModal && (
          <SetDuplicateModal
            shown={showSetDuplicateModal}
            close={() => {
              setShowSetDuplicateModal(false);
            }}
            questionId={questionId}
          />
        )}

        {showDeleteConfirm && (
          <ConfirmationModal
            shown={showDeleteConfirm}
            close={() => {
              setShowDeleteConfirm(false);
            }}
            confirm={() => {
              setShowDeleteConfirm(false);
              deleteQuestionData();
            }}
            confirmationMessage="Do you really want to delete this question? This process cannot be undone"
          />
        )}
      </Card>
    );
  } else {
    content = null;
  }
  return <section>{content}</section>;
}
