import {
  AccountCircle,
  Comment,
  Create,
  Description,
  DynamicForm,
  HelpCenter,
  Home,
  Leaderboard,
  Person,
  Poll,
  Psychology,
  Settings,
  Timeline,
  Groups
} from '@mui/icons-material';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function HierarchicalBreadcrumbs({
  outcomes,
  outcome,
  outcomeId,
  question,
  questionId,
  comment,
  commentId,
  submit,
  users,
  leaderboard,
  forecasting,
  profile,
  settings,
  questionnaire,
  personality,
  demographics,
  openMinded,
  userGroups,
}) {
  const navigate = useNavigate();

  const openOutcomesView = () => {
    navigate(`/outcomes`);
  };

  const openOutcomeView = () => {
    navigate(`/outcomes/${outcomeId}`);
  };

  const openQuestionView = () => {
    navigate(`/questions/${questionId}`);
  };

  const openCommentView = () => {
    navigate(`/questions/${questionId}/comment/${commentId}`);
  };

  const openUsersView = () => {
    navigate(`/users`);
  };

  const openLeaderboardView = () => {
    navigate(`/leaderboard`);
  };

  const openForecastingView = () => {
    navigate(`/forecasting`);
  };

  const openSettingsView = () => {
    navigate(`/admin-settings`);
  };

  // const openUseGroupsView = () => {
  //   navigate(`/user-groups`);
  // };

  const isLastCrumb = (crumb) => {
    if (crumb === 'comment') {
      if (comment) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'question') {
      if (!comment) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'outcome') {
      if (!question) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'submit') {
      if (submit) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'users') {
      if (users) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'leaderboard') {
      if (leaderboard) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'forecasting') {
      if (forecasting) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'outcomes') {
      if (
        !outcome &&
        !question &&
        !submit &&
        !users &&
        !leaderboard &&
        !forecasting &&
        !profile &&
        !settings &&
        !questionnaire
      ) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'questionnaire') {
      if (!personality && !demographics && !openMinded) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'personality') {
      if (personality) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'demographics') {
      if (demographics) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'openMinded') {
      if (openMinded) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'settings') {
      if (!userGroups) {
        return true;
      } else {
        return false;
      }
    } else if (crumb === 'userGroups') {
      if (userGroups) {
        return true;
      } else {
        return false;
      }
    }
  };

  const breadcrumbs = [];
  if (outcomes) {
    if (isLastCrumb('outcomes')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="1">
          <Home sx={{ mr: 0.5 }} fontSize="inherit" />
          Outcomes
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openOutcomesView}
          key="1">
          <Home sx={{ mr: 0.5 }} fontSize="inherit" />
          Outcomes
        </Link>
      );
    }
  }
  if (outcome) {
    if (isLastCrumb('outcome') || outcomeId === undefined) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="2">
          <Description sx={{ mr: 0.5 }} fontSize="inherit" />
          Outcome
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openOutcomeView}
          key="2">
          <Description sx={{ mr: 0.5 }} fontSize="inherit" />
          Outcome
        </Link>
      );
    }
  }
  if (submit) {
    if (isLastCrumb('submit')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="2">
          <Create sx={{ mr: 0.5 }} fontSize="inherit" />
          Submit an Outcome
        </Typography>
      );
    }
  }
  if (question) {
    if (isLastCrumb('question') || questionId === undefined) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="3">
          <HelpCenter sx={{ mr: 0.5 }} fontSize="inherit" />
          Question
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openQuestionView}
          key="3">
          <HelpCenter sx={{ mr: 0.5 }} fontSize="inherit" />
          Question
        </Link>
      );
    }
  }
  if (comment) {
    if (isLastCrumb('comment') || commentId === undefined) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="4">
          <Comment sx={{ mr: 0.5 }} fontSize="inherit" />
          Comment
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openCommentView}
          key="4">
          <Comment sx={{ mr: 0.5 }} fontSize="inherit" />
          Comment
        </Link>
      );
    }
  }
  if (users) {
    if (isLastCrumb('users')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="5">
          <Person sx={{ mr: 0.5 }} fontSize="inherit" />
          Users
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openUsersView}
          key="5">
          <Person sx={{ mr: 0.5 }} fontSize="inherit" />
          Users
        </Link>
      );
    }
  }
  if (leaderboard) {
    if (isLastCrumb('leaderboard')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="6">
          <Leaderboard sx={{ mr: 0.5 }} fontSize="inherit" />
          Leaderboard
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openLeaderboardView}
          key="6">
          <Leaderboard sx={{ mr: 0.5 }} fontSize="inherit" />
          Leaderboard
        </Link>
      );
    }
  }
  if (forecasting) {
    if (isLastCrumb('forecasting')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="7">
          <Timeline sx={{ mr: 0.5 }} fontSize="inherit" />
          Forecasting
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Link
          underline="hover"
          sx={{
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          color="inherit"
          onClick={openForecastingView}
          key="7">
          <Timeline sx={{ mr: 0.5 }} fontSize="inherit" />
          Forecasting
        </Link>
      );
    }
  }
  if (profile) {
    breadcrumbs.push(
      <Typography
        sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
        color="text.primary"
        key="7">
        <AccountCircle sx={{ mr: 0.5 }} fontSize="inherit" />
        User Profile
      </Typography>
    );
  }
  if (settings) {
    if (isLastCrumb('settings')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="7">
          <Settings sx={{ mr: 0.5 }} fontSize="inherit" />
          Settings
        </Typography>
      );
    } else {
        breadcrumbs.push(
          <Link
            underline="hover"
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
            color="inherit"
            onClick={openSettingsView}
            key="7">
            <Settings sx={{ mr: 0.5 }} fontSize="inherit" />
            Settings
          </Link>
        );
    }
  }
  if (questionnaire) {
    if (isLastCrumb('questionnaire')) {
      breadcrumbs.push(
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
          color="text.primary"
          key="8">
          <DynamicForm sx={{ mr: 0.5 }} fontSize="inherit" />
          Questionnaire
        </Typography>
      );
    } else {
      breadcrumbs.push(
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
          color="text.primary"
          key="8">
          <DynamicForm sx={{ mr: 0.5 }} fontSize="inherit" />
          Questionnaires
        </Typography>
      );
    }
  }
  if (personality) {
    breadcrumbs.push(
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
        color="text.primary"
        key="10">
        <Psychology sx={{ mr: 0.5 }} fontSize="inherit" />
        Personality
      </Typography>
    );
  }
  if (demographics) {
    breadcrumbs.push(
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
        color="text.primary"
        key="9">
        <Poll sx={{ mr: 0.5 }} fontSize="inherit" />
        Demographics
      </Typography>
    );
  }
  if (openMinded) {
    breadcrumbs.push(
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
        color="text.primary"
        key="11">
        <Psychology sx={{ mr: 0.5 }} fontSize="inherit" />
        Open Minded
      </Typography>
    );
  }
  if (userGroups) {
    breadcrumbs.push(
      <Typography
        sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
        color="text.primary"
        key="12">
        <Groups sx={{ mr: 0.5 }} fontSize="inherit" />
        User Groups
      </Typography>
    );
  }

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">{breadcrumbs}</Breadcrumbs>
    </div>
  );
}