import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { AuthContext, checkTokenStatus } from '../../App';

import moment from 'moment';
import { useSelector } from 'react-redux';
import {
    selectEvaluationByQuestionUser,
    selectOutcomeById,
    selectQuestionById
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';

import { parseISO } from 'date-fns';

import { FaAsterisk } from 'react-icons/fa';
import { FiCheck, FiLink, FiStar } from 'react-icons/fi';
import { MdSpeed } from 'react-icons/md';
import { TbLayoutAlignMiddle } from 'react-icons/tb';

import {
    Button,
    Card,
    Collapse,
    Divider,
    Tooltip,
    Typography,
    useTheme
} from '@mui/material';
import { BsChevronDoubleDown, BsChevronDoubleUp } from 'react-icons/bs';

export default function SmallQuestionCard({ questionId }) {
    const { setIsLoggedIn, userData } = useContext(AuthContext);
    const userId = userData.id;
    const navigate = useNavigate();
    const theme = useTheme();
    const [collapsed, setCollapsed] = useState(true);
    const usernameSettings = useSelector((state) =>
        selectSettingByName(state, 'View Usernames')
    );

    const question = useSelector((state) =>
        selectQuestionById(state, questionId)
    );
    const evaluation = useSelector((state) =>
        selectEvaluationByQuestionUser(state, questionId, userId)
    );
    const outcome = useSelector((state) =>
        selectOutcomeById(state, question.outcome_id)
    );

    const timeSincePosted = () => {
        return moment(question.created_at, 'YYYY-MM-DDThh:mm:ss').fromNow();
    };

    const isEdited = () => {
        return question.modified_at ? true : false;
    };

    const getStatusColour = () => {
        let status =
            question.status !== 'Completed' ? question.status : completedStatusCalc();
        switch (status) {
            case 'Pending':
                return theme.palette.statuses.mid1;
            case 'Rejected':
                return theme.palette.statuses.mid5;
            case 'Not Submitted':
                return theme.palette.statuses.mid2;
            case 'Accepted':
                return theme.palette.statuses.mid3;
            case 'Submitted':
                return theme.palette.statuses.mid3;
            case 'Closed':
                return theme.palette.statuses.mid4;
            case 'Duplicate':
                return theme.palette.statuses.mid6;
            default:
                return theme.palette.statuses.mid7;
        }
    };

    const completedStatusCalc = () => {
        if (outcome !== undefined) {
            if (outcome.status === 'Forecasting') {
                return 'Submitted';
            } else {
                return 'Closed';
            }
        } else {
            return 'Closed';
        }
    };

    const categoryTextColor = (category) => {
        switch (category) {
            case 'Very Low':
                return 'text-red-500';
            case 'Low':
                return 'text-orange-500';
            case 'Medium':
                return 'text-blue-500';
            case 'High':
                return 'text-teal-500';
            case 'Very High':
                return 'text-green-500';
            default:
                return 'bg-blue-500';
        }
    };

    const evaluationScoreCategoryTextColor = (category) => {
        switch (category) {
            case 'Furthest':
                return 'text-red-500';
            case 'Far':
                return 'text-orange-500';
            case 'Average':
                return 'text-blue-500';
            case 'Close':
                return 'text-teal-500';
            case 'Correct':
                return 'text-green-500';
            default:
                return 'bg-blue-500';
        }
    };

    if (usernameSettings === undefined) {
        return null;
    } else {
        return (
            <Card id={'questionCard' + questionId} className={`QuestionCard w-full`}>
                <div className="px-3 pt-2">
                    <div className="break-words">
                        <div className="">
                            <div className="flex justify-start items-start max-h-42 overflow-y-auto">
                                <Typography sx={{ fontWeight: 'bold', lineHeight: 1.3 }}>
                                    <span className="whitespace-pre-line">
                                        {question.question_text}
                                    </span>
                                </Typography>
                                <Typography
                                    sx={{
                                        borderColor: getStatusColour(),
                                        backgroundColor: getStatusColour(),
                                        py: 0,
                                        px: 0.3,
                                        ml: 'auto',
                                        fontSize: '0.9rem'
                                    }}
                                    className={`border-2 rounded text-white font-normal text-xs px-1`}>
                                    {question.status !== 'Completed'
                                        ? question.status
                                        : completedStatusCalc()}
                                </Typography>
                            </div>
                        </div>
                        <Divider sx={{ my: 0.6 }} />
                        <div className="QuestionInfo flex items-center justify-start ml-2">
                            <Typography
                                sx={{ fontSize: '0.875rem', mr: 0.6 }}
                                color="text.secondary">
                                Posted {timeSincePosted()}
                            </Typography>
                            {(userData.role === 'Admin' ||
                                userData.role === 'Moderator' ||
                                usernameSettings.active) && (
                                    <div className="flex items-center">
                                        <Typography
                                            sx={{ mr: 0.6, fontSize: '0.875rem' }}
                                            color="text.secondary">
                                            by
                                        </Typography>
                                        <Typography
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(`/profile/${question.created_by.username}`);
                                            }}
                                            sx={{ fontSize: '0.875rem' }}
                                            className="hover:underline hover:cursor-pointer"
                                            color="primary.main">
                                            {question.created_by.username}
                                        </Typography>
                                    </div>
                                )}
                            {isEdited() && (
                                <div className="mr-1">
                                    <Tooltip title="Edited">
                                        <div>
                                            <FaAsterisk className="py-1" />
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                            {question.resolvable && (
                                <div className="mr-1">
                                    <Tooltip title="Is Resolvable">
                                        <div>
                                            <FiCheck />
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                            {question.relevant && (
                                <div className="mr-1">
                                    <Tooltip title="Is Relevant" placement="bottom">
                                        <div>
                                            <FiCheck />
                                        </div>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                        <Collapse in={!collapsed}>
                            <div className="flex flex-wrap py-1">
                                <div className="flex-col pr-2">
                                    <Typography
                                        sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
                                        color="text.secondary">
                                        Resolution Criteria
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: '0.875rem' }}
                                        className="whitespace-pre-line font-medium text-xs">
                                        {question.resolution_criteria}
                                    </Typography>
                                </div>
                                <div className="flex-col pr-2">
                                    <Typography
                                        sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
                                        color="text.secondary">
                                        Source Link
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: '0.875rem' }}
                                        className=" font-medium text-xs">
                                        {question.source}
                                    </Typography>
                                </div>
                                <div className="flex-col pr-2">
                                    <Typography
                                        sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
                                        color="text.secondary">
                                        Resolution Date
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: '0.875rem' }}
                                        className=" font-medium text-xs">
                                        {moment(question.resolution_date).format(
                                            'dddd, MMMM Do YYYY'
                                        )}
                                    </Typography>
                                </div>
                                <div className="flex-col pr-2">
                                    <Typography
                                        sx={{ fontSize: '0.875rem', fontWeight: 'bold' }}
                                        color="text.secondary">
                                        Relevance Description
                                    </Typography>
                                    <Typography
                                        sx={{ fontSize: '0.875rem' }}
                                        className=" font-medium text-xs">
                                        {question.relevance_reason}
                                    </Typography>
                                </div>
                            </div>
                        </Collapse>
                        <div className="flex justify-end">
                            <Button
                                sx={{ px: 1, minWidth: 0 }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setCollapsed(!collapsed);
                                }}>
                                {collapsed ? (
                                    <BsChevronDoubleDown size={15} />
                                ) : (
                                    <BsChevronDoubleUp size={15} />
                                )}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="flex pb-1 pt-1 items-center justify-between">
                    <div className="ml-5 flex">
                        {question.question_score && (
                            <div className="flex items-center mr-2">
                                <FiStar className="mr-1 pb-0.5" fill="black" />
                                <Typography sx={{ mr: 0.6 }}>
                                    {question.question_score_type} Question Score:
                                </Typography>
                                <Typography
                                    sx={{ fontWeight: 500 }}
                                    className={`${categoryTextColor(
                                        question.question_score_category.name
                                    )}`}>
                                    {question.question_score_category.name +
                                        ' (' +
                                        (question.question_score * 100).toFixed(2) +
                                        '%)'}
                                </Typography>
                            </div>
                        )}
                        {evaluation && evaluation.category &&
                        (outcome.status !== 'Forecasting') && (
                            <div className="flex items-center mr-2">
                                <MdSpeed className="mr-1 mb-0.5" />
                                <Typography sx={{ mr: 0.6 }}>Your Evaluation: </Typography>
                                <Typography
                                    className={`${categoryTextColor(evaluation.category.name)}`}>
                                    {evaluation.category.name}
                                </Typography>
                            </div>
                        )}
                        {evaluation &&
                            evaluation.evaluation_score !== null &&
                            evaluation.evaluation_score !== undefined && (
                                <div className="flex items-center mr-2">
                                    <FiStar className="mr-1 pb-0.5" />
                                    <Typography sx={{ mr: 0.6 }}>
                                        Your {evaluation.evaluation_score_type} Evaluation Score:{' '}
                                    </Typography>
                                    <Typography
                                        className={`${evaluationScoreCategoryTextColor(
                                            evaluation.evaluation_score_category.name
                                        )}`}>
                                        {evaluation.evaluation_score_category.name +
                                            ' (' +
                                            evaluation.evaluation_score +
                                            ')'}
                                    </Typography>
                                </div>
                            )}

                        {question.average_evaluation !== undefined &&
                            question.average_evaluation !== 0 &&
                            question.average_evaluation_category &&
                            (evaluation ||
                                question.created_by.username === userData.username ||
                                outcome.created_by.username === userData.username ||
                                userData.role === 'Admin' ||
                                userData.role === 'Moderator') &&
                            (outcome.status !== 'Forecasting') && (
                                <div className="flex items-center mr-2">
                                    <TbLayoutAlignMiddle className="mr-1" />
                                    <Typography sx={{ mr: 0.6 }}>
                                        Community Evaluation:{' '}
                                    </Typography>
                                    <Typography
                                        className={`${categoryTextColor(
                                            question.average_evaluation_category.name
                                        )}`}>
                                        {question.average_evaluation_category.name +
                                            ' (' +
                                            Math.round(question.average_evaluation * 10) / 10 +
                                            ')'}
                                    </Typography>
                                </div>
                            )}
                    </div>
                </div>
            </Card>
        );
    }
}
