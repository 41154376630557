import moment from 'moment';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Fullscreen, FullscreenExit, Groups } from '@mui/icons-material';
import {
  Button,
  Card,
  Collapse,
  Link,
  Typography,
  useTheme
} from '@mui/material';
import { useState } from 'react';
import { FiCalendar, FiHash } from 'react-icons/fi';
import { AuthContext } from '../../App';
import { selectSettingByName } from '../../store/slices/settingsSlice';
import RoleBadgeIcon from '../icons/RoleBadgeIcon';
import ForecastDashboard from '../other/charts/ForecastDashboard';

export default function OutcomeCardQuestionView({ outcome }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const [dashboardOpen, setDashboardOpen] = useState(true);
  const { userData } = useContext(AuthContext);
  const usersData = useSelector((state) => state.users.entities);

  const getStatusText = () => {
    if (outcome.status === 'Generation') {
      return 'Started';
    } else if (outcome.status === 'Evaluation') {
      return 'Evaluation Started';
    } else if (outcome.status === 'Forecasting') {
      return 'Forecasting Started';
    } else {
      return 'Closed';
    }
  };

  const timeBetweenStatusChange = () => {
    if (outcome.status === 'Closed') {
      return moment(outcome.end_at, 'YYYY-MM-DD').fromNow();
    } else if (outcome.status === 'Generation') {
      return moment(outcome.created_at, 'YYYY-MM-DDThh:mm:ss').fromNow();
    } else if (outcome.status === 'Evaluation') {
      return moment(outcome.generation_end_date, 'YYYY-MM-DD').fromNow();
    } else {
      return moment(outcome.question_deadline, 'YYYY-MM-DD').fromNow();
    }
  };

  const getEndingStatusText = () => {
    if (outcome.status === 'Generation') {
      return 'Generation Deadline';
    } else if (outcome.status === 'Evaluation') {
      return 'Evaluation Deadline';
    } else if (outcome.status === 'Forecasting') {
      return 'Ending';
    } else {
      return '';
    }
  };

  const timeTillFinish = () => {
    if (outcome.status === 'Generation') {
      return moment(outcome.generation_end_date, 'YYYY-MM-DD').fromNow();
    } else if (outcome.status === 'Evaluation') {
      return moment(outcome.question_deadline, 'YYYY-MM-DD').fromNow();
    } else if (outcome.status === 'Forecasting') {
      return moment(outcome.end_at, 'YYYY-MM-DD').fromNow();
    } else {
      return '';
    }
  };

  const getBorderColour = () => {
    switch (outcome.status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };

  if (usernameSettings === undefined) {
    return null;
  } else {
    return (
      <div className="OutcomeCardQuestionView">
        <Card
          sx={{
            boxShadow: '0 7px 20px -9px rgba(0,0,0,0.3)',
            borderLeftWidth: '5px',
            borderLeftColor: getBorderColour()
          }}
          className={`m-5 px-5 py-4 break-words`}>
          <div className="OutcomeText font-medium max-h-36 overflow-y-auto">
            <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
              <span className="whitespace-pre-line">{outcome.title}</span>
            </Typography>
          </div>
          <div className="OutcomeInfo flex flex-wrap items-center justify-start">
            <Typography
              sx={{ mx: 0.6, fontSize: '0.95rem' }}
              color="text.secondary">
              {getStatusText()} {timeBetweenStatusChange()}
            </Typography>
            {(outcome.status === 'Generation' ||
              outcome.status === 'Evaluation' ||
              outcome.status === 'Forecasting') && (
              <Typography
                sx={{ mx: 0.6, fontSize: '0.95rem' }}
                color="text.secondary">
                {getEndingStatusText()} {timeTillFinish()}
              </Typography>
            )}

            {(userData.role === 'Admin' ||
              userData.role === 'Moderator' ||
              usernameSettings.active) && (
              <div className="flex items-center">
                <Typography
                  sx={{
                    ml: 0.6,
                    fontSize: '0.95rem',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  color="text.secondary">
                  Created by
                </Typography>
                <Typography
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/profile/${outcome.created_by.username}`);
                  }}
                  sx={{ mx: 0.6, fontSize: '0.95rem' }}
                  className="hover:underline hover:cursor-pointer"
                  color="primary.main">
                  {outcome.created_by.username}
                </Typography>
                <div className="pb-1">
                  <RoleBadgeIcon
                    role={usersData[outcome.created_by.id]?.role}
                  />
                </div>
              </div>
            )}
            {outcome.groups?.length > 0 && (
              <div className="sm:flex hidden items-center p-1">
                <Groups
                  fontSize="inherit"
                  sx={{ mr: 0.6, color: 'text.secondary' }}
                />
                <Typography
                  sx={{
                    mr: 0.6,
                    fontSize: '0.875rem'
                  }}
                  color="text.secondary">
                  <span className="font-bold"></span>
                  {outcome.groups.map((group, index) => (
                    <Link
                      key={group.id}
                      sx={{
                        color: 'text.secondary',
                        textDecoration: 'None',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        navigate(`/user-groups/${group.id}`);
                      }}>
                      {index > 0 ? ', ' : ''}
                      {group.name}
                    </Link>
                  ))}
                </Typography>
              </div>
            )}
          </div>
          <div className="max-h-36 overflow-y-auto">
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: '1rem',
                p: 1
              }}>
              <span className="whitespace-pre-line">{outcome.description}</span>
            </Typography>
          </div>
          <div className="sm:flex hidden items-center p-1">
            {(outcome.status === 'Generation' ||
              outcome.status === 'Evaluation') && (
              <div className="flex items-center mr-2">
                <FiCalendar className="mr-1 mb-0.5" />
                <Typography
                  sx={{
                    mr: 0.6,
                    fontSize: '0.875rem'
                  }}>{`Outcome Ends: ${moment(outcome.end_at).format(
                  'dddd, MMMM Do YYYY'
                )}`}</Typography>
              </div>
            )}
            {(outcome.status === 'Generation' ||
              outcome.status === 'Evaluation') && (
              <div className="sm:flex items-center mr-2 hidden">
                <FiHash size="20" className="mr-1 mb-0.5" />
                <Typography
                  sx={{
                    fontSize: '0.875rem'
                  }}>{`The top ${outcome.question_submission_limit} rated Questions will be submitted to the Forecasting Tournament`}</Typography>
              </div>
            )}
          </div>
          <div className="flex justify-start w-full">
            <Collapse
              in={
                outcome.status !== 'Generation' &&
                outcome.status !== 'Evaluation' &&
                dashboardOpen
              }
              sx={{ width: '100%' }}
              className="w-auto">
              <div className="flex items-start w-full">
                <ForecastDashboard
                  outcomeId={outcome.id}
                  width={'large'}
                  forecastingStartDate={outcome.question_deadline}
                />

                <Button onClick={() => setDashboardOpen(false)}>
                  <FullscreenExit />
                </Button>
              </div>
            </Collapse>
          </div>
          {!dashboardOpen && (
            <div className="flex justify-start">
              <Button onClick={() => setDashboardOpen(true)} variant="outlined">
                Expand Dashboard
                <Fullscreen />
              </Button>
            </div>
          )}
        </Card>
      </div>
    );
  }
}
