import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { hivemindAPI } from '../HivemindAPI';

const outcomesAdapter = createEntityAdapter({
  sortComparer: (a, b) => sort_by_created_modified(a, b)
});
const questionsAdapter = createEntityAdapter({
  sortComparer: (a, b) => sort_by_created_modified(a, b)
});
const commentsAdapter = createEntityAdapter({
  sortComparer: (a, b) => sort_by_created_modified(a, b)
});
const evaluationsAdapter = createEntityAdapter();

const resolutionEvaluationsAdapter = createEntityAdapter();

const forecastsAdapter = createEntityAdapter();

const outcomeInitialState = outcomesAdapter.getInitialState({
  status: 'idle',
  error: null,
  sort: { key: 'Newest', order: 'asc', type: 'created' },
  filters: {
    own: false,
    user: { enabled: false, username: '' },
    generation: true,
    evaluation: false,
    forecasting: false,
    closed: false
  }
});
const questionInitialState = questionsAdapter.getInitialState({
  status: 'idle',
  error: null
});
const commentInitialState = commentsAdapter.getInitialState();
const evaluationsInitialState = evaluationsAdapter.getInitialState();
const resolutionEvaluationsInitialState =
  resolutionEvaluationsAdapter.getInitialState();
const forecastInitialState = forecastsAdapter.getInitialState({
  status: 'idle',
  error: null
});
const initialState = {
  outcomes: outcomeInitialState,
  questions: questionInitialState,
  comments: commentInitialState,
  evaluations: evaluationsInitialState,
  resolutionEvaluations: resolutionEvaluationsInitialState,
  forecasts: forecastInitialState,
  selectedOutcome: null,
  selectedQuestion: null
};

export const fetchOutcomes = createAsyncThunk(
  'outcomes/fetchOutcomes',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      '/api/outcomes',
      {},
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchQuestions = createAsyncThunk(
  'outcomes/fetchQuestions',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      '/api/questions',
      {},
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchQuestionsByOutcome = createAsyncThunk(
  'outcomes/fetchQuestionsByOutcome',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/questions`,
      {
        outcome_id: payload.outcomeId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchQuestionsByActivity = createAsyncThunk(
  'outcomes/fetchQuestionsByActivity',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/questions/user/activity`,
      {
        user_id: payload.userId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchQuestionById = createAsyncThunk(
  'outcomes/fetchQuestionById',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/questions`,
      {
        id: payload.questionId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchAllForecasts = createAsyncThunk(
  'outcomes/fetchAllForecasts',
  async () => {
    const response = await hivemindAPI.fetch(`/api/forecasts`);
    return response.json();
  }
);

export const fetchForecastsByQuestion = createAsyncThunk(
  'outcomes/fetchForecastsByQuestion',
  async (questionId) => {
    const response = await hivemindAPI.fetch(`/api/forecasts`, {
      question_id: questionId
    });
    return response.json();
  }
);

export const fetchForecastsByOutcome = createAsyncThunk(
  'outcomes/fetchForecastsByOutcome',
  async (outcomeId) => {
    const response = await hivemindAPI.fetch(`/api/forecasts`, {
      outcome_id: outcomeId
    });
    return response.json();
  }
);

export const addNewOutcome = createAsyncThunk(
  'outcomes/addNewOutcome',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/outcomes',
      {
        title: payload.title,
        description: payload.description,
        question_deadline: payload.question_deadline,
        end_date: payload.end_date,
        question_submission_limit: payload.question_submission_limit
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewQuestion = createAsyncThunk(
  'outcomes/addNewQuestion',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/questions',
      {
        question_text: payload.question_text,
        resolution_criteria: payload.resolution_criteria,
        source: payload.source,
        resolution_date: payload.resolution_date,
        relevance_reason: payload.relevance_reason,
        outcome_id: payload.outcome_id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewComment = createAsyncThunk(
  'outcomes/addNewComment',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/comments',
      {
        question_id: payload.question_id,
        text: payload.text
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewForecastLink = createAsyncThunk(
  'outcomes/addNewForecastLink',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/questions/forecast_link',
      {
        question_id: payload.question_id,
        forecasting_source_link: payload.forecasting_source_link
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewForecast = createAsyncThunk(
  'outcomes/addNewForecast',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/forecasts',
      {
        probability: payload.probability,
        question_id: payload.questionId,
        forecast_week: payload.forecastWeek,
        forecast_day: payload.forecastDay
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewForecasts = createAsyncThunk(
  'outcomes/addNewForecasts',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/forecasts',
      {
        probability: payload.probabilities,
        question_id: payload.questionIds,
        forecast_date: payload.forecastDates
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewCommentReply = createAsyncThunk(
  'outcomes/addNewCommentReply',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/comments',
      {
        parent_comment_id: payload.parentCommentId,
        text: payload.text
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateOutcome = createAsyncThunk(
  'outcomes/updateOutcome',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/outcomes',
      {
        id: payload.id,
        new_title: payload.new_title,
        new_description: payload.description,
        new_question_submission_limit: payload.question_submission_limit,
        new_question_deadline: payload.question_deadline,
        new_end_date: payload.end_date
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const deleteOutcome = createAsyncThunk(
  'outcomes/deleteOutcome',
  async (payload) => {
    const response = await hivemindAPI.delete(
      '/api/outcomes',
      {
        id: payload.outcomeId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateQuestion = createAsyncThunk(
  'outcomes/updateQuestion',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/questions',
      {
        id: payload.id,
        question_text: payload.question_text ? payload.question_text : null,
        question_score:
          payload.question_score !== null ? payload.question_score : null,
        resolution_criteria:
          payload.resolution_criteria !== null
            ? payload.resolution_criteria
            : null,
        source: payload.source !== null ? payload.source : null,
        resolution_date:
          payload.resolution_date !== null ? payload.resolution_date : null,
        relevance_reason:
          payload.relevance_reason !== null ? payload.relevance_reason : null,
        reset_pending:
          payload.reset_pending !== null ? payload.reset_pending : null
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const acceptQuestion = createAsyncThunk(
  'outcomes/acceptQuestion',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/${payload.id}/accept`,
      {
        resolvable: payload.resolvable !== null ? payload.resolvable : null,
        relevant: payload.relevant !== null ? payload.relevant : null
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const rejectQuestion = createAsyncThunk(
  'outcomes/rejectQuestion',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/${payload.id}/reject`,
      {
        resolvable: payload.resolvable !== null ? payload.resolvable : null,
        relevant: payload.relevant !== null ? payload.relevant : null,
        comment_text: payload.commentText
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const setQuestionAsDuplicate = createAsyncThunk(
  'outcomes/setQuestionAsDuplicate',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/${payload.id}/duplicate`,
      {
        duplicate_of_id: payload.duplicateOfId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const setQuestionResolutionImpact = createAsyncThunk(
  'outcomes/setQuestionResolutionImpact',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/${payload.id}/resolution_impact`,
      {
        resolution_impact: payload.resolutionImpact
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const makeResolutionEvaluation = createAsyncThunk(
  'outcomes/makeResolutionEvaluation',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/evaluations/resolution`,
      {
        resolution_evaluation: payload.resolutionImpact,
        question_id: payload.id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const deleteQuestion = createAsyncThunk(
  'outcomes/deleteQuestion',
  async (payload) => {
    await hivemindAPI.delete(
      '/api/questions',
      {
        id: payload.id
      },
      payload.auth_token
    );
    return payload.id;
  }
);

export const deleteComment = createAsyncThunk(
  'outcomes/deleteComment',
  async (payload) => {
    const response = await hivemindAPI.delete(
      '/api/comments',
      {
        id: payload.commentId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const deleteForecast = createAsyncThunk(
  'outcomes/deleteForecast',
  async (payload) => {
    const response = await hivemindAPI.delete(
      '/api/forecasts',
      {
        id: payload.forecastId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateComment = createAsyncThunk(
  'outcomes/updateComment',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/comments',
      {
        id: payload.id,
        text: payload.text
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addEvaluation = createAsyncThunk(
  'outcomes/addEvaluation',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/questions/evaluations',
      {
        question_id: payload.questionId,
        evaluation_category: payload.evaluationCategory
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const deleteEvaluation = createAsyncThunk(
  'outcomes/deleteevaluation',
  async (payload) => {
    const response = await hivemindAPI.delete(
      '/api/questions/evaluations',
      {
        question_id: payload.questionId,
        user_id: payload.userId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const confirmForecastsComplete = createAsyncThunk(
  'outcomes/confirmForecastsComplete',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/${payload.questionId}/complete_forecasts`,
      {},
      payload.auth_token
    );
    return response.json();
  }
);

export const outcomeSlice = createSlice({
  name: 'outcomes',
  initialState,
  reducers: {
    updateSelectedOutcome: {
      reducer(state, action) {
        state.selectedOutcome = action.payload;
      },
      prepare(id) {
        return {
          payload: {
            id: id
          }
        };
      }
    },
    updateSelectedQuestion: {
      reducer(state, action) {
        state.selectedQuestion = action.payload;
      },
      prepare(id) {
        return {
          payload: {
            id: id
          }
        };
      }
    },
    deselectOutcome: {
      reducer(state) {
        state.selectedOutcome = null;
      }
    },
    deselectQuestion: {
      reducer(state) {
        state.selectedQuestion = null;
      }
    },
    setOutcomesSort: {
      reducer(state, action) {
        state.outcomes.sort = {
          key: action.payload.key
            ? action.payload.key
            : state.outcomes.sort.key,
          order: action.payload.order
            ? action.payload.order
            : state.outcomes.sort.order,
          type: action.payload.type
            ? action.payload.type
            : state.outcomes.sort.type
        };
      }
    },
    setOutcomeFilters: {
      reducer(state, action) {
        if (action.payload.own !== undefined) {
          state.outcomes.filters.own = action.payload.own;
        }
        if (action.payload.user !== undefined) {
          state.outcomes.filters.user = action.payload.user;
        }
        if (action.payload.generation !== undefined) {
          state.outcomes.filters.generation = action.payload.generation;
        }
        if (action.payload.moderation !== undefined) {
          state.outcomes.filters.moderation = action.payload.moderation;
        }
        if (action.payload.evaluation !== undefined) {
          state.outcomes.filters.evaluation = action.payload.evaluation;
        }
        if (action.payload.forecasting !== undefined) {
          state.outcomes.filters.forecasting = action.payload.forecasting;
        }
        if (action.payload.closed !== undefined) {
          state.outcomes.filters.closed = action.payload.closed;
        }
      }
    },
    toggleOutcomeFilters: {
      reducer(state, action) {
        state.outcomes.filters = {
          own: action.payload.toggleOwn
            ? !state.outcomes.filters.own
            : state.outcomes.filters.own,
          user: {
            enabled: action.payload.toggleUserEnabled
              ? !state.outcomes.filters.user.enabled
              : state.outcomes.filters.user.enabled,
            username:
              typeof action.payload.userUsername === 'string'
                ? action.payload.userUsername
                : state.outcomes.filters.user.username
          },
          generation: action.payload.toggleGeneration
            ? !state.outcomes.filters.generation
            : state.outcomes.filters.generation,
          moderation: action.payload.toggleModeration
            ? !state.outcomes.filters.moderation
            : state.outcomes.filters.moderation,
          evaluation: action.payload.toggleEvaluation
            ? !state.outcomes.filters.evaluation
            : state.outcomes.filters.evaluation,
          forecasting: action.payload.toggleForecasting
            ? !state.outcomes.filters.forecasting
            : state.outcomes.filters.forecasting,
          closed: action.payload.toggleClosed
            ? !state.outcomes.filters.closed
            : state.outcomes.filters.closed
        };
      }
    },
    setQuestionFilter: {
      reducer(state, action) {
        state.outcomes.entities[action.payload.outcomeId].questionFilter =
          action.payload.questionFilter;
      }
    },
    toggleQuestionFilter: {
      reducer(state, action) {
        state.outcomes.entities[action.payload.outcomeId].questionFilter = {
          pending: action.payload.pending
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .pending
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .pending,
          accepted: action.payload.accepted
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .accepted
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .accepted,
          rejected: action.payload.rejected
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .rejected
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .rejected,
          duplicate: action.payload.duplicate
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .duplicate
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .duplicate,
          submitted: action.payload.submitted
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .submitted
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .submitted,
          notSubmitted: action.payload.notSubmitted
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .notSubmitted
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .notSubmitted,
          closed: action.payload.closed
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .closed
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .closed
        };
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOutcomes.pending, (state, action) => {
        state.outcomes.status = 'loading';
      })
      .addCase(fetchOutcomes.fulfilled, (state, action) => {
        state.outcomes.status = 'succeeded';
        let outcomes = action.payload.data.map((outcome) => {
          outcome.questionStatus = 'idle';
          outcome.questionFilter = {
            pending: true,
            accepted: true,
            rejected: false,
            submitted: false,
            notSubmitted: false,
            closed: false,
            duplicate: false
          };
          return outcome;
        });
        outcomesAdapter.upsertMany(state.outcomes, outcomes);
      })
      .addCase(fetchOutcomes.rejected, (state, action) => {
        state.outcomes.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchQuestions.pending, (state, action) => {
        state.questions.status = 'loading';
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.questions.status = 'succeeded';
        reduceQuestions(state, action.payload.data);
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.questions.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchQuestionsByOutcome.pending, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcomeId].questionStatus =
          'loading';
      })
      .addCase(fetchQuestionsByOutcome.fulfilled, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcomeId].questionStatus =
          'succeeded';
        reduceQuestions(state, action.payload.data);
      })
      .addCase(fetchQuestionsByOutcome.rejected, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcomeId].questionStatus =
          'failed';
        state.questions.error = action.error.message;
      })
      .addCase(fetchQuestionById.fulfilled, (state, action) => {
        reduceQuestions(state, [action.payload.data]);
      })
      .addCase(fetchQuestionById.rejected, (state, action) => {
        state.questions.error = action.error.message;
      })
      .addCase(fetchQuestionsByActivity.fulfilled, (state, action) => {
        reduceQuestions(state, action.payload.data);
      })
      .addCase(fetchQuestionsByActivity.rejected, (state, action) => {
        state.questions.error = action.error.message;
      })
      .addCase(fetchAllForecasts.pending, (state, action) => {
        state.forecasts.status = 'loading';
      })
      .addCase(fetchAllForecasts.fulfilled, (state, action) => {
        state.forecasts.status = 'succeeded';
        forecastsAdapter.upsertMany(state.forecasts, action.payload.data);
      })
      .addCase(fetchAllForecasts.rejected, (state, action) => {
        state.forecasts.error = action.error.message;
      })
      .addCase(fetchForecastsByOutcome.fulfilled, (state, action) => {
        forecastsAdapter.upsertMany(state.forecasts, action.payload.data);
      })
      .addCase(fetchForecastsByOutcome.rejected, (state, action) => {
        state.forecasts.error = action.error.message;
      })
      .addCase(fetchForecastsByQuestion.fulfilled, (state, action) => {
        forecastsAdapter.upsertMany(state.forecasts, action.payload.data);
      })
      .addCase(fetchForecastsByQuestion.rejected, (state, action) => {
        state.forecasts.error = action.error.message;
      })
      .addCase(addNewOutcome.fulfilled, (state, action) => {
        let outcome = action.payload.data;
        outcome.questionStatus = 'idle';
        outcome.questionFilter = {
          pending: true,
          accepted: true,
          rejected: false,
          submitted: false,
          notSubmitted: false,
          closed: false,
          duplicate: false
        };
        outcomesAdapter.addOne(state.outcomes, outcome);
        if (state.outcomes.status !== 'succeeded') {
          state.outcomes.status = 'succeeded';
        }
      })
      .addCase(updateOutcome.fulfilled, (state, action) => {
        const updatedOutcome = action.payload.data;
        outcomesAdapter.updateOne(state.outcomes, {
          id: updatedOutcome.id,
          changes: updatedOutcome
        });
      })
      .addCase(deleteOutcome.fulfilled, (state, action) => {
        let outcomeId = action.meta.arg.outcomeId;
        outcomesAdapter.removeOne(state.outcomes, outcomeId);
      })
      .addCase(addNewQuestion.fulfilled, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcome_id].questionStatus =
          'succeeded';
        questionsAdapter.addOne(state.questions, action.payload.data);
      })
      .addCase(updateQuestion.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data;
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        let questionId = action.meta.arg.id;
        questionsAdapter.removeOne(state.questions, questionId);
      })
      .addCase(acceptQuestion.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data;
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
      })
      .addCase(rejectQuestion.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data.question;
        const rejectionComment = action.payload.data.comment;
        rejectionComment.parentQuestionId = updatedQuestion.id;
        rejectionComment.comment_type =
          action.meta.arg.resolvable !== undefined
            ? 'resolvability rejection'
            : 'relevance rejection';
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
        commentsAdapter.addOne(state.comments, rejectionComment);
      })
      .addCase(setQuestionAsDuplicate.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data;
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
      })
      .addCase(setQuestionResolutionImpact.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data;
        if (updatedQuestion.outcome_owner_resolution_impact !== undefined) {
          updatedQuestion.outcome_owner_resolution_impact =
            updatedQuestion.outcome_owner_resolution_impact === 'True'
              ? true
              : false;
        }
        questionsAdapter.upsertOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
      })
      .addCase(makeResolutionEvaluation.fulfilled, (state, action) => {
        const updatedResolutionEvaluation = action.payload.data;
        // if (updatedResolutionEvaluation.resolution_impact !== undefined) {
        //   updatedResolutionEvaluation.resolution_impact =
        //     updatedResolutionEvaluation.resolution_impact === 'True'
        //       ? true
        //       : false;
        // }
        updatedResolutionEvaluation.question_id =
          updatedResolutionEvaluation.parent_question.id;
        updatedResolutionEvaluation.user_id =
          updatedResolutionEvaluation.user.id;
        resolutionEvaluationsAdapter.upsertOne(
          state.resolutionEvaluations,
          updatedResolutionEvaluation
        );
      })
      .addCase(addNewForecastLink.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data;
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
      })
      .addCase(addNewComment.fulfilled, (state, action) => {
        let comment = action.payload.data;
        comment.parentQuestionId = action.meta.arg.question_id;
        commentsAdapter.addOne(state.comments, comment);
      })
      .addCase(addNewCommentReply.fulfilled, (state, action) => {
        let comment = action.payload.data;
        comment.parentId = action.meta.arg.parentCommentId;
        commentsAdapter.addOne(state.comments, comment);
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        commentsAdapter.removeOne(state.comments, action.meta.arg.commentId);
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        const updatedComment = action.payload.data;
        if (updatedComment.active !== undefined) {
          updatedComment.active =
            updatedComment.active === 'True' ? true : false;
        }
        commentsAdapter.updateOne(state.comments, {
          id: updatedComment.id,
          changes: updatedComment
        });
      })
      .addCase(addNewForecast.fulfilled, (state, action) => {
        forecastsAdapter.upsertOne(state.forecasts, action.payload.data);
      })
      .addCase(addNewForecasts.fulfilled, (state, action) => {
        forecastsAdapter.upsertMany(state.forecasts, action.payload.data);
      })
      .addCase(deleteForecast.fulfilled, (state, action) => {
        forecastsAdapter.removeOne(state.forecasts, action.meta.arg.forecastId);
      })
      .addCase(addEvaluation.fulfilled, (state, action) => {
        let evaluation = action.payload.data;
        evaluation.question_id = action.meta.arg.questionId;
        evaluationsAdapter.addOne(state.evaluations, evaluation);
      })
      .addCase(deleteEvaluation.fulfilled, (state, action) => {
        let questionId = action.meta.arg.questionId;
        let userId = action.meta.arg.userId;
        let evaluation = state.evaluations.entities.find(
          (evaluation) =>
            evaluation.user_id === userId &&
            evaluation.question_id === questionId
        );
        evaluationsAdapter.removeOne(state.evaluations, evaluation.id);
      })
      .addCase(confirmForecastsComplete.fulfilled, (state, action) => {
        reduceQuestions(state, [action.payload.data]);
      })
      .addCase(confirmForecastsComplete.rejected, (state, action) => {
        state.questions.error = action.error.message;
      });

    function reduceQuestions(state, questionData) {
      questionsAdapter.upsertMany(state.questions, questionData);
      let evaluations = [];
      let resolutionEvaluations = [];
      let commentArrays = [];
      questionData.forEach((question) => {
        if (question.outcome_owner_resolution_impact !== undefined) {
          question.outcome_owner_resolution_impact =
            question.outcome_owner_resolution_impact === 'True' ? true : false;
        }
        evaluations.push(
          ...question.evaluations.map((evaluation) => {
            evaluation.question_id = question.id;
            return evaluation;
          })
        );
        resolutionEvaluations.push(
          ...question.resolution_evaluations.map((evaluation) => {
            evaluation.question_id = question.id;
            return evaluation;
          })
        );
        commentArrays.push(
          ...question.comments.map((comment) => {
            comment.parentQuestionId = question.id;
            if (comment.active !== undefined) {
              comment.active = comment.active === 'True' ? true : false;
            }
            if (comment.replies) {
              addQuestionReferenceToReplies(comment.replies, question.id);
            }
            return comment;
          })
        );
      });
      evaluationsAdapter.upsertMany(state.evaluations, evaluations);
      resolutionEvaluationsAdapter.upsertMany(
        state.resolutionEvaluations,
        resolutionEvaluations
      );
      let normalisedComments = [];
      commentArrays.forEach((comment) => {
        comment = relationiseCommentReplies(comment);
        normalisedComments.push(...flattenComment(comment));
      });
      commentsAdapter.upsertMany(state.comments, normalisedComments);
    }
  }
});

export const {
  updateSelectedOutcome,
  updateSelectedQuestion,
  deselectOutcome,
  deselectQuestion,
  setOutcomesSort,
  setOutcomeFilters,
  setQuestionFilter,
  toggleQuestionFilter,
  toggleOutcomeFilters
} = outcomeSlice.actions;

export default outcomeSlice.reducer;

export const {
  selectAll: selectAllOutcomes,
  selectById: selectOutcomeById,
  selectIds: selectOutcomeIds
} = outcomesAdapter.getSelectors((state) => state.outcomes.outcomes);

export const {
  selectAll: selectAllQuestions,
  selectById: selectQuestionById,
  selectIds: selectQuestionIds
} = questionsAdapter.getSelectors((state) => state.outcomes.questions);

export const {
  selectAll: selectAllComments,
  selectById: selectCommentById,
  selectIds: selectCommentIds
} = commentsAdapter.getSelectors((state) => state.outcomes.comments);

export const {
  selectAll: selectAllEvaluations,
  selectById: selectEvaluationById,
  selectIds: selectEvaluationIds
} = evaluationsAdapter.getSelectors((state) => state.outcomes.evaluations);

export const {
  selectAll: selectAllResolutionEvaluations,
  selectById: selectResolutionEvaluationById,
  selectIds: selectResolutionEvaluationIds
} = resolutionEvaluationsAdapter.getSelectors(
  (state) => state.outcomes.resolutionEvaluations
);

export const {
  selectAll: selectAllForecasts,
  selectById: selectForecastById,
  selectIds: selectForecastIds
} = forecastsAdapter.getSelectors((state) => state.outcomes.forecasts);

export const selectQuestionIdsByOutcome = (state, outcomeId) => {
  const questions = selectAllQuestions(state);
  const questionIds = questions
    .filter((question) => question.outcome_id === outcomeId)
    .map((question) => question.id);
  return questionIds;
};

export const selectQuestionsByOutcome = createSelector(
  [selectAllQuestions, (state, outcomeId) => outcomeId],
  (questions, outcomeId) =>
    questions.filter((question) => question.outcome_id === outcomeId)
);

export const selectResolutionImpactQuestionsOfOutcome = createSelector(
  [selectAllQuestions, (state, outcomeId) => outcomeId],
  (questions, outcomeId) =>
    questions.filter(
      (question) =>
        question.outcome_id === outcomeId &&
        (question.outcome_owner_resolution_impact !== undefined ||
          question.group_resolution_impact !== undefined)
    )
);

export const selectQuestionsByUserId = createSelector(
  [selectAllQuestions, (state, userId) => userId],
  (questions, userId) =>
    questions.filter((question) => question.created_by.id === userId)
);

export const selectOutcomesByUserId = createSelector(
  [selectAllOutcomes, (state, userId) => userId],
  (outcomes, userId) =>
    outcomes.filter((outcome) => outcome.created_by.id === userId)
);

export const selectStandardCommentIdsByQuestion = (state, questionId) => {
  const comments = selectAllComments(state);
  const commentIds = comments
    .filter(
      (comment) =>
        comment.parentQuestionId === questionId && !comment.comment_type
    )
    .map((comment) => comment.id);
  return commentIds;
};

export const selectRejectionCommentIdsByQuestion = (state, questionId) => {
  const comments = selectAllComments(state);
  const commentIds = comments
    .filter(
      (comment) =>
        comment.parentQuestionId === questionId &&
        comment.comment_type &&
        comment.comment_type.includes('rejection') &&
        comment.active
    )
    .map((comment) => comment.id);
  return commentIds;
};

export const selectInactiveRejectionCommentIdsByQuestion = (
  state,
  questionId
) => {
  const comments = selectAllComments(state);
  const commentIds = comments
    .filter(
      (comment) =>
        comment.parentQuestionId === questionId &&
        comment.comment_type &&
        comment.comment_type.includes('rejection') &&
        !comment.active
    )
    .map((comment) => comment.id);
  return commentIds;
};

export const selectReplyIdsByComment = (state, commentId) => {
  const comments = selectAllComments(state);
  const replyIds = comments
    .filter((comment) => comment.parentId === commentId)
    .map((comment) => comment.id);
  return replyIds;
};

export const selectCommentsByUserId = createSelector(
  [selectAllComments, (state, userId) => userId],
  (comments, userId) =>
    comments.filter((comment) => comment.created_by.id === userId)
);

export const selectOutcomeByTitle = createSelector(
  [selectAllOutcomes, (state, title) => title],
  (outcomes, title) =>
    outcomes.find(
      (outcome) => getPlainText(outcome.title) === getPlainText(title)
    )
);

export const selectOutcomesByStatus = createSelector(
  [selectAllOutcomes, (state, status) => status],
  (outcomes, status) => outcomes.filter((outcome) => outcome.status === status)
);

export const selectQuestionsByStatus = createSelector(
  [selectAllQuestions, (state, status) => status],
  (questions, status) =>
    questions.filter((questions) => questions.status === status)
);

export const selectForecastsByOutcome = createSelector(
  [
    (state) => state.outcomes,
    selectAllForecasts,
    selectAllQuestions,
    (state, outcomeId) => outcomeId
  ],
  (state, forecasts, questions, outcomeId) => {
    forecasts.filter((forecast) => {
      let question = questions.find(
        (question) => question.id === forecast.question_id
      );
      if (question) {
        return outcomeId === question.outcome_id;
      } else {
        return false;
      }
    });
  }
);

export const selectResolutionImpactForecastsOfOutcome = (state, outcomeId) => {
  const forecasts = selectAllForecasts(state);
  const questions = selectResolutionImpactQuestionsOfOutcome(state, outcomeId);
  return forecasts
    .filter((forecast) => {
      return questions.map((q) => q.id).includes(forecast.question_id);
    })
    .sort((forecastA, forecastB) =>
      forecastA.forecast_week > forecastB.forecast_week
        ? 1
        : forecastB.forecast_week > forecastA.forecast_week
        ? -1
        : 0
    );
};

// export const selectResolutionImpactForecastsOfOutcome = (state, outcomeId) => {
//   const forecasts = selectAllForecasts(state);
//   const questions = selectAllQuestions(state);

//   return forecasts.filter((forecast) => {
//     let question = questions.find(
//       (question) => question.id === forecast.question_id
//     );
//     if (question) {
//       return (
//         outcomeId === question.outcome_id &&
//         question.outcome_owner_resolution_impact !== undefined
//       );
//     } else {
//       return false;
//     }
//   });
// };

export const selectForecastsByQuestion = createSelector(
  [selectAllForecasts, (state, questionId) => questionId],
  (forecasts, questionId) =>
    forecasts
      .filter((forecast) => forecast.question_id === questionId)
      .sort((forecastA, forecastB) =>
        forecastA.forecast_week > forecastB.forecast_week
          ? 1
          : forecastB.forecast_week > forecastA.forecast_week
          ? -1
          : 0
      )
);

export const selectOutcomesSort = createSelector(
  (state) => state.outcomes,
  (state) => state.outcomes.sort
);

export const selectOutcomesFilters = createSelector(
  (state) => state.outcomes,
  (state) => state.outcomes.filters
);

export const selectOrderedFilteredOutcomes = (state, userId) => {
  const outcomes = selectFilteredOutcomes(state, userId);
  state = state.outcomes;
  if (state.outcomes.sort.type === 'created') {
    if (state.outcomes.sort.order === 'asc') {
      outcomes.sort((outcomeA, outcomeB) =>
        outcomeB.created_at.localeCompare(outcomeA.created_at)
      );
    } else if (state.outcomes.sort.order === 'desc') {
      outcomes.sort((outcomeA, outcomeB) =>
        outcomeA.created_at.localeCompare(outcomeB.created_at)
      );
    }
  } else if (state.outcomes.sort.type === 'endAt') {
    if (state.outcomes.sort.order === 'asc') {
      outcomes.sort((outcomeA, outcomeB) => {
        if (outcomeA.end_at && outcomeB.end_at) {
          return outcomeA.end_at.localeCompare(outcomeB.end_at);
        } else if (
          (outcomeA.end_at === null || outcomeA.end_at === undefined) &&
          (outcomeB.end_at === null || outcomeB.end_at === undefined)
        ) {
          return 0;
        } else {
          return outcomeA.end_at ? 1 : -1;
        }
      });
    } else if (state.outcomes.sort.order === 'desc') {
      outcomes.sort((outcomeA, outcomeB) => {
        if (outcomeA.end_at && outcomeB.end_at) {
          return outcomeB.end_at.localeCompare(outcomeA.end_at);
        } else if (
          (outcomeA.end_at === null || outcomeA.end_at === undefined) &&
          (outcomeB.end_at === null || outcomeB.end_at === undefined)
        ) {
          return 0;
        } else {
          return outcomeA.end_at ? -1 : 1;
        }
      });
    }
  } else if (state.outcomes.sort.type === 'activity') {
    if (state.outcomes.sort.order === 'asc') {
      outcomes.sort((outcomeA, outcomeB) =>
        activitySort(outcomeA.activity, outcomeB.activity)
      );
    } else if (state.outcomes.sort.order === 'desc') {
      outcomes.sort((outcomeA, outcomeB) =>
        activitySort(outcomeB.activity, outcomeA.activity)
      );
    }
  } else if (state.outcomes.sort.type === 'recentActivity') {
    if (state.outcomes.sort.order === 'asc') {
      outcomes.sort((outcomeA, outcomeB) =>
        activitySort(outcomeA.recent_activity, outcomeB.recent_activity)
      );
    } else if (state.outcomes.sort.order === 'desc') {
      outcomes.sort((outcomeA, outcomeB) =>
        activitySort(outcomeB.recent_activity, outcomeA.recent_activity)
      );
    }
  } else if (state.outcomes.sort.type === 'popular') {
    if (state.outcomes.sort.order === 'asc') {
      outcomes.sort((outcomeA, outcomeB) =>
        activitySort(outcomeA.popularity, outcomeB.popularity)
      );
    } else if (state.outcomes.sort.order === 'desc') {
      outcomes.sort((outcomeA, outcomeB) =>
        activitySort(outcomeB.popularity, outcomeA.popularity)
      );
    }
  }
  return outcomes;
};

const activitySort = (activityA, activityB) => {
  if (activityA && activityB) {
    if (parseFloat(activityB) > parseFloat(activityA)) {
      return 1;
    }
    if (parseFloat(activityB) < parseFloat(activityA)) {
      return -1;
    }
    return 0;
  } else if (
    (activityA === null || activityA === undefined) &&
    (activityB === null || activityB === undefined)
  ) {
    return 0;
  } else {
    return activityA ? -1 : 1;
  }
};

export const selectFilteredOutcomes = createSelector(
  [
    (state) => state.outcomes,
    selectAllOutcomes,
    selectAllQuestions,
    selectAllEvaluations,
    (state, userId) => userId
  ],
  (state, outcomes, questions, evaluations, userId) => {
    if (state.outcomes.filters.own) {
      let filteredOutcomeIds = [];
      outcomes.forEach((outcome) => {
        if (outcome.created_by.id === userId) {
          filteredOutcomeIds.push(outcome.id);
        }
      });

      let ownQuestions = questions.filter(
        (question) => question.created_by.id === userId
      );
      ownQuestions.forEach((question) => {
        if (!filteredOutcomeIds.includes(question.outcome_id)) {
          filteredOutcomeIds.push(question.outcome_id);
        }
      });

      let ownEvaluations = evaluations.filter(
        (evaluation) => evaluation.user_id === userId
      );
      ownEvaluations.forEach((evaluation) => {
        questions.forEach((question) => {
          if (question.id === evaluation.question_id) {
            if (!filteredOutcomeIds.includes(question.outcome_id)) {
              filteredOutcomeIds.push(question.outcome_id);
            }
          }
        });
      });

      outcomes = outcomes.filter(
        (outcome) => !filteredOutcomeIds.includes(outcome.id)
      );
    }

    if (
      state.outcomes.filters.user.enabled &&
      state.outcomes.filters.user.username !== ''
    ) {
      outcomes = outcomes.filter(
        (outcome) =>
          outcome.created_by.username === state.outcomes.filters.user.username
      );
    }

    if (!state.outcomes.filters.generation) {
      outcomes = outcomes.filter((outcome) => outcome.status !== 'Generation');
    }
    if (!state.outcomes.filters.moderation) {
      outcomes = outcomes.filter((outcome) => outcome.status !== 'Moderation');
    }
    if (!state.outcomes.filters.evaluation) {
      outcomes = outcomes.filter((outcome) => outcome.status !== 'Evaluation');
    }
    if (!state.outcomes.filters.forecasting) {
      outcomes = outcomes.filter((outcome) => outcome.status !== 'Forecasting');
    }
    if (!state.outcomes.filters.closed) {
      outcomes = outcomes.filter((outcome) => outcome.status !== 'Closed');
    }

    return outcomes;
  }
);

export const selectFilteredQuestionsByOutcomeGlobal = createSelector(
  [
    (state) => state.outcomes,
    selectAllQuestions,
    (state, outcomeId) => outcomeId
  ],
  (state, questions, outcomeId) => {
    return selectFilteredQuestionsByOutcome(
      questions,
      outcomeId,
      state.outcomes.entities[outcomeId].questionFilter
    );
  }
);

export const selectFilteredQuestionsByOutcomeWithoutSelf = (
  state,
  outcomeId,
  questionFilter,
  userId
) => {
  const filteredQuestions = selectCustomFilteredQuestionsbyOutcome(
    state,
    outcomeId,
    questionFilter
  );
  return filteredQuestions.filter(
    (question) => question.created_by.id !== userId
  );
};

export const selectCustomFilteredQuestionsbyOutcome = createSelector(
  [
    (state) => state.outcomes,
    selectAllQuestions,
    (state, outcomeId) => outcomeId,
    (state, outcomeId, questionFilter) => questionFilter
  ],
  (state, questions, outcomeId, questionFilter) =>
    selectFilteredQuestionsByOutcome(questions, outcomeId, questionFilter)
);

export const selectFilteredQuestionsByOutcome = (
  questions,
  outcomeId,
  questionFilter
) => {
  let allQuestions = questions.filter(
    (question) => question.outcome_id === outcomeId
  );
  let outputQuestions = [];
  if (questionFilter.accepted) {
    outputQuestions = outputQuestions.concat(
      allQuestions.filter((question) => question.status === 'Accepted')
    );
  }
  if (questionFilter.submitted) {
    outputQuestions = outputQuestions.concat(
      allQuestions.filter(
        (question) =>
          question.status === 'Submitted' || question.status === 'Completed'
      )
    );
  }
  if (questionFilter.closed) {
    outputQuestions = outputQuestions.concat(
      allQuestions.filter(
        (question) =>
          question.status === 'Closed' || question.status === 'Completed'
      )
    );
  }
  if (questionFilter.pending) {
    outputQuestions = outputQuestions.concat(
      allQuestions.filter((question) => question.status === 'Pending')
    );
  }
  if (questionFilter.rejected) {
    outputQuestions = outputQuestions.concat(
      allQuestions.filter((question) => question.status === 'Rejected')
    );
  }
  if (questionFilter.notSubmitted) {
    outputQuestions = outputQuestions.concat(
      allQuestions.filter((question) => question.status === 'Not Submitted')
    );
  }
  if (questionFilter.duplicate) {
    outputQuestions = outputQuestions.concat(
      allQuestions.filter((question) => question.status === 'Duplicate')
    );
  }
  return outputQuestions;
};

export const selectQuestionFilter = createSelector(
  [(state) => state.outcomes, (state, outcomeId) => outcomeId],
  (state, outcomeId) => state.outcomes.entities[outcomeId].questionFilter
);

export const selectEvaluationByQuestionUser = (state, questionId, userId) => {
  const evaluations = selectAllEvaluations(state);
  const evaluation = evaluations.find(
    (evaluation) =>
      evaluation.user_id === userId && evaluation.question_id === questionId
  );
  return evaluation ? evaluation : null;
};

export const selectResolutionEvaluationByQuestionUser = (
  state,
  questionId,
  userId
) => {
  const evaluations = selectAllResolutionEvaluations(state);
  const evaluation = evaluations.find(
    (evaluation) =>
      evaluation.user_id === userId && evaluation.question_id === questionId
  );
  return evaluation ? evaluation : null;
};

export const selectEvaluationsByUserId = createSelector(
  [selectAllEvaluations, (state, userId) => userId],
  (evaluations, userId) =>
    evaluations.filter((evaluation) => evaluation.user_id === userId)
);

export const selectHasUserCommented = (state, questionId, username) => {
  const commentIds = selectStandardCommentIdsByQuestion(state, questionId);
  const comments = selectAllComments(state);
  const filteredComments = comments.filter((comment) =>
    commentIds.includes(comment.id)
  );
  const userComment = filteredComments.find(
    (comment) => comment.created_by.username === username
  );

  if (userComment !== undefined) {
    return true;
  } else {
    return false;
  }
};

function relationiseCommentReplies(comment) {
  if (comment.replies.length <= 0) {
    return comment;
  } else {
    comment.replies.forEach((reply) => {
      reply.parentId = comment.id;
      relationiseCommentReplies(reply);
    });
    return comment;
  }
}

function addQuestionReferenceToReplies(replies, questionId) {
  replies.forEach((reply) => {
    reply.questionId = questionId;
    if (reply.replies.length > 0) {
      addQuestionReferenceToReplies(reply.replies, questionId);
    }
  });
  return replies;
}

function flattenComment(comment) {
  let flatComments = [comment];
  comment.replies.forEach((reply) => {
    flatComments.push(...flattenReply(reply));
  });
  flatComments.forEach((comment) => {
    delete comment.replies;
  });
  return flatComments;
}

function flattenReply(reply) {
  const flat = [reply];

  reply.replies.forEach((innerReply) => {
    if (Array.isArray(innerReply.replies)) {
      flat.push(...flattenReply(innerReply));
    } else {
      flat.push(innerReply);
    }
  });
  return flat;
}

function sort_by_created_modified(a, b) {
  let a_val = a.modified_at ? a.modified_at : a.created_at;
  let b_val = b.modified_at ? b.modified_at : b.created_at;
  return b_val.localeCompare(a_val);
}

const getPlainText = (text) => {
  return text.replace(/[^a-zA-Z0-9 ]/g, '');
};
