import {
  AccountCircle,
  DarkMode,
  Login,
  Logout,
  Settings
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { Divider, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AuthContext, ColorModeContext, checkTokenStatus } from '../../App';
import logoBlack from '../../assets/images/Fujitsu-Symbol-Black.png';
import logoRed from '../../assets/images/Fujitsu-Symbol-Red.png';
import logoWhite from '../../assets/images/Fujitsu-Symbol-White-.png';

import { logout, updateUserPreferences } from '../../store/slices/userSlice';
import { DarkSwitch } from './DarkSwitch';

const questionerPages = [
  { name: 'Outcomes', link: '/outcomes' },
  { name: 'Users', link: '/users' },
  { name: 'Leaderboard', link: '/leaderboard' }
];
const moderatorPages = [
  { name: 'Outcomes', link: '/outcomes' },
  { name: 'Users', link: '/users' },
  { name: 'Leaderboard', link: '/leaderboard' },
  { name: 'Forecasting', link: '/forecasting' }
];
const adminPages = [
  { name: 'Outcomes', link: '/outcomes' },
  { name: 'Users', link: '/users' },
  { name: 'Leaderboard', link: '/leaderboard' },
  { name: 'Forecasting', link: '/forecasting' },
  { name: 'Admin', link: '/admin-settings' }
];

export default function Navbar() {
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [pages, setPages] = useState([]);
  const navigate = useNavigate();
  const colorMode = useContext(ColorModeContext);
  const { isLoggedIn, setIsLoggedIn, userData } = useContext(AuthContext);
  const theme = useTheme();

  useEffect(() => {
    if (userData.role === 'Admin') {
      setPages(adminPages);
    } else if (userData.role === 'Moderator') {
      setPages(moderatorPages);
    } else {
      setPages(questionerPages);
    }
  }, [pages, userData]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const openPage = (event, page) => {
    navigate(page.link);
    handleCloseNavMenu();
    handleCloseUserMenu();
  };

  async function handleLogout() {
    if (checkTokenStatus() === true) {
      try {
        const token = localStorage.getItem('auth_token');
        await dispatch(logout(token))
          .unwrap()
          .then(() => {
            localStorage.removeItem('auth_token');
            setIsLoggedIn(false);
          });
      } catch (err) {
        console.log(`Failed logout: ${err.message}`);
      }
    } else {
      setIsLoggedIn(false);
      return navigate('/login');
    }
  }

  const changeDark = () => {
    colorMode.toggleColorMode();
    if (checkTokenStatus() === true) {
      let newTheme = theme.palette.mode === 'light' ? 'dark' : 'light';
      localStorage.setItem('mode', newTheme);
    }
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'navbar' }}>
      <Container maxWidth="false">
        <Toolbar disableGutters>
          <Box
            component="a"
            href="https://www.fujitsu.com/global/"
            sx={{ mr: 1, mb: 0.9 }}>
            <Box
              component="img"
              sx={{
                display: { xs: 'none', md: 'flex' },
                height: '2rem',
                justifySelf: 'flex-start'
              }}
              src={theme.palette.mode === 'dark' ? logoWhite : logoBlack}
              onMouseOver={(e) => {
                e.currentTarget.src = logoRed;
              }}
              onMouseOut={(e) => {
                e.currentTarget.src =
                  theme.palette.mode === 'dark' ? logoWhite : logoBlack;
              }}
              alt="logo"
            />
          </Box>
          <Divider
            sx={{
              display: { xs: 'none', md: 'flex' },
              mx: 2,
              borderRightWidth: 1.1
            }}
            orientation="vertical"
            variant="middle"
            flexItem
          />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              ml: 1,
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontWeight: 500,
              color: 'text.primary',
              textDecoration: 'none',
              flexGrow: 0,
              textAlign: 'center',
              '&:hover': {
                color: '#EA0000'
              }
            }}>
            Hivemind
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}>
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={(event) => openPage(event, page)}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 500,
              color: 'text.primary',
              textDecoration: 'none'
            }}>
            Hivemind
          </Typography>

          <Box
            sx={{
              flexGrow: 0,
              marginLeft: 'auto',
              display: { xs: 'none', md: 'flex' }
            }}>
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={(event) => openPage(event, page)}
                sx={{
                  my: 2,
                  color: 'text.primary',
                  borderRadius: 3,
                  display: 'block',
                  '&:hover': {
                    bgcolor: 'hover'
                  }
                }}>
                {page.name}
              </Button>
            ))}
          </Box>

          <Divider
            sx={{
              display: { xs: 'none', md: 'flex' },
              mx: 2,
              borderRightWidth: 1.1
            }}
            orientation="vertical"
            variant="middle"
            flexItem
          />

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountCircle />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {isLoggedIn && (
                <MenuItem
                  sx={{ my: '0.1rem' }}
                  key="profile"
                  onClick={(event) =>
                    openPage(event, {
                      name: 'Profile',
                      link: `/profile/${userData.username}`
                    })
                  }>
                  <AccountCircle sx={{ mr: 1 }} />
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
              )}
              {isLoggedIn && (
                <MenuItem
                  sx={{ my: '0.1rem' }}
                  key="account"
                  onClick={(event) =>
                    openPage(event, {
                      name: 'Account',
                      link: '/account-settings'
                    })
                  }
                  divider>
                  <Settings sx={{ mr: 1 }} />
                  <Typography textAlign="center">Account Settings</Typography>
                </MenuItem>
              )}

              <MenuItem sx={{ my: '0.1rem' }} key="darkMode" divider>
                <DarkMode sx={{ mr: 1 }} />
                <Typography textAlign="center">Dark Mode</Typography>
                <DarkSwitch onChange={changeDark} />
              </MenuItem>
              {isLoggedIn ? (
                <MenuItem
                  key="logout"
                  sx={{ my: '0.1rem' }}
                  onClick={handleLogout}>
                  <Logout sx={{ mr: 1 }} />
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              ) : (
                <MenuItem
                  key="login"
                  sx={{ my: '0.1rem' }}
                  onClick={(event) =>
                    openPage(event, {
                      name: 'Login',
                      link: '/login'
                    })
                  }>
                  <Login sx={{ mr: 1 }} />
                  <Typography textAlign="center">Login</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
