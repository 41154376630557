
class HivemindAPI {

    fetch = async (uri, parameters = null, auth_token = null) => {
        try {
            let uriVal = parameters !== null ? uri + '?' + new URLSearchParams(parameters) : uri
            if (auth_token) {
                const response = await fetch(uriVal, {
                    method: "GET", headers: {
                        "Authorization": `Bearer ${auth_token}`
                    }
                })

                if (response.ok) {
                    return response;
                } else {
                    const data = await response.json()
                    throw { "message": data.message ? data.message : "Failed to retrieve data." }
                }
            }
            else {
                let meta = { method: "GET" }
                const response = await fetch(uriVal, meta)

                if (response.ok) {
                    return response;
                } else {
                    const data = await response.json()
                    throw { "message": data.message ? data.message : "Failed to perform post" }
                }
            }
        } catch (err) {
            throw err
        }
    }

    post = async (uri, parameters = null, auth_token = null) => {
        try {
            let uriVal = uri
            if (auth_token) {
                const response = await fetch(uriVal, {
                    method: "POST", headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth_token}`
                    },
                    body: JSON.stringify(parameters)
                })

                if (response.ok) {
                    return response;
                } else {
                    const data = await response.json()
                    throw { "message": data.message ? data.message : "Failed to perform post" }
                }
            } else {
                const response = await fetch(uriVal, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(parameters)
                })
                if (response.ok) {
                    return response;
                } else {
                    const data = await response.json()
                    throw { "message": data.message ? data.message : "Failed to perform post" }
                }
            }
        } catch (err) {
            throw err
        }
    }
    put = async (uri, parameters = null, auth_token = null) => {
        try {
            let uriVal = uri
            if (auth_token) {
                const response = await fetch(uriVal, {
                    method: "PUT", headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth_token}`
                    },
                    body: JSON.stringify(parameters)
                })

                if (response.ok) {
                    return response;
                } else {
                    const data = await response.json()
                    throw { "message": data.message ? data.message : "Failed to perform post" }
                }
            } else {
                const response = await fetch(uriVal, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(parameters)
                })
                if (response.ok) {
                    return response;
                } else {
                    const data = await response.json()
                    throw { "message": data.message ? data.message : "Failed to perform post" }
                }
            }
        } catch (err) {
            throw err
        }
    }

    delete = async (uri, parameters = null, auth_token = null) => {
        try {
            let uriVal = uri
            if (auth_token) {
                const response = await fetch(uriVal, {
                    method: "DELETE", headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${auth_token}`
                    },
                    body: JSON.stringify(parameters)
                })
                if (response.ok) {
                    return response;
                } else {
                    const data = await response.json()
                    throw { "message": data.message ? data.message : "Failed to perform post" }
                }
            } else {
                const response = await fetch(uriVal, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(parameters)
                })
                if (response.ok) {
                    return response;
                } else {
                    const data = await response.json()
                    throw { "message": data.message ? data.message : "Failed to perform post" }
                }
            }
        } catch (err) {
            throw err
        }
    }
}

export const hivemindAPI = new HivemindAPI()