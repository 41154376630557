import {
  Button,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { FiTriangle } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import InfoTooltip from '../../components/other/InfoTooltip';
import {
  selectLeaderboardSort,
  selectLeaderboardUsers,
  setLeaderboardSort
} from '../../store/slices/userSlice';

export default function LeaderboardView() {
  const userStatus = useSelector((state) => state.users.status);
  const users = useSelector((state) => selectLeaderboardUsers(state));
  const leaderboardSort = useSelector((state) => selectLeaderboardSort(state));
  const { userData } = useContext(AuthContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleOrderChange = (order) => {
    dispatch(setLeaderboardSort({ order: order }));
  };

  const chevronColour = (direction) => {
    if (direction === 'up') {
      return leaderboardSort.order === 'asc'
        ? theme.palette.primary.main
        : theme.palette.background.default;
    } else if (direction === 'down') {
      return leaderboardSort.order === 'desc'
        ? theme.palette.primary.main
        : theme.palette.background.default;
    }
  };

  const upChevronClicked = (event) => {
    if (event.stopPropagation) event.stopPropagation();
    handleOrderChange('asc');
  };

  const downChevronClicked = (event) => {
    if (event.stopPropagation) event.stopPropagation();
    handleOrderChange('desc');
  };

  const changeScoreType = (event, newScoreType) => {
    dispatch(setLeaderboardSort({ scoreType: newScoreType }));
  };

  const getRank = (user) => {
    if (leaderboardSort.type === 'generatorScore') {
      if (leaderboardSort.scoreType === 'confirmed') {
        return user.confirmed_generator_rank;
      } else if (leaderboardSort.scoreType === 'current') {
        return user.current_generator_rank;
      } else if (leaderboardSort.scoreType === 'total') {
        return user.total_generator_rank;
      }
    } else if (leaderboardSort.type === 'evaluatorScore') {
      if (leaderboardSort.scoreType === 'confirmed') {
        return user.confirmed_evaluator_rank;
      } else if (leaderboardSort.scoreType === 'current') {
        return user.current_evaluator_rank;
      }
    }
  };

  const getLabel = () => {
    if (leaderboardSort.scoreType === 'confirmed') {
      return 'Confirmed ' + leaderboardSort.key;
    } else if (leaderboardSort.scoreType === 'current') {
      return 'Current ' + leaderboardSort.key;
    } else if (leaderboardSort.scoreType === 'total') {
      return 'Total ' + leaderboardSort.key;
    }
  };

  const getValue = (user) => {
    if (leaderboardSort.type === 'generatorScore') {
      if (leaderboardSort.scoreType === 'confirmed') {
        return (user.confirmed_generator_score * 100).toFixed(2);
      } else if (leaderboardSort.scoreType === 'current') {
        return (user.current_generator_score * 100).toFixed(2);
      } else if (leaderboardSort.scoreType === 'total') {
        return (user.total_generator_score * 100).toFixed(2);
      }
    } else if (leaderboardSort.type === 'evaluatorScore') {
      if (leaderboardSort.scoreType === 'confirmed') {
        return user.confirmed_evaluator_score.toFixed(2);
      } else if (leaderboardSort.scoreType === 'current') {
        return user.current_evaluator_score.toFixed(2);
      }
    }
  };

  const changeType = (event, newType) => {
    if (newType === 'generatorScore') {
      dispatch(setLeaderboardSort({ key: 'Generator Score', type: newType }));
    } else if (newType === 'evaluatorScore') {
      if (leaderboardSort.scoreType === 'total') {
        dispatch(setLeaderboardSort({ scoreType: 'confirmed' }));
      }
      dispatch(setLeaderboardSort({ key: 'Evaluator Score', type: newType }));
    }
  };

  const getTooltipText = () => {
    if (leaderboardSort.type === 'generatorScore') {
      if (leaderboardSort.scoreType === 'current') {
        return 'The average score achieved by Questions submitted by the user. Including incomplete questions. Higher is better.';
      } else if (leaderboardSort.scoreType === 'confirmed') {
        return 'The average score achieved by Questions submitted by the user. Only completed questions. Higher is better.';
      } else if (leaderboardSort.scoreType === 'total') {
        return 'The total cumulative score achieved by Questions submitted by the user. Only completed questions. Higher is better.';
      }
    } else if (leaderboardSort.type === 'evaluatorScore') {
      if (leaderboardSort.scoreType === 'confirmed') {
        return 'The average accuracy of the user’s evaluations of Question scores. Only evaluations of completed questions. Higher is better. 4 is the maximum.';
      } else if (leaderboardSort.scoreType === 'current') {
        return 'The average accuracy of the user’s evaluations of Question scores. Including evaluations of incomplete questions. Higher is better. 4 is the maximum.';
      }
    }
  };

  let tableContent;

  if (userStatus === 'loading') {
    tableContent = (
      <TableRow className="bg-white border-b">
        <TableCell
          scope="row"
          className="w-1/12 py-4 px-6 font-medium text-gray-900 whitespace-nowrap"></TableCell>
        <TableCell
          scope="row"
          className="w-2/12 py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
          Loading...
        </TableCell>
      </TableRow>
    );
  } else if (userStatus === 'succeeded') {
    tableContent = users.map((user, index) => {
      if (getRank(user) !== undefined) {
        return (
          <TableRow
            key={index}
            className={`  ${
              user.id === userData.id
                ? 'border-cyan-500 border-2'
                : 'border-b border-gray-200'
            }`}>
            <TableCell
              scope="row"
              className="w-1/12 py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
              {getRank(user)}
            </TableCell>
            <TableCell
              scope="row"
              className="w-1/12 py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
              <Link underline="hover" href={`/profile/${user.username}`}>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  {' '}
                  {user.username}
                </span>
              </Link>
            </TableCell>
            <TableCell
              scope="row"
              className="w-1/12 py-4 px-6 font-medium whitespace-nowrap text-gray-900">
              {getValue(user)}
            </TableCell>
          </TableRow>
        );
      }
    });
  } else if (userStatus === 'failed') {
    tableContent = (
      <TableRow className="border-b">
        <TableCell
          scope="row"
          className="w-1/12 py-4 px-6 font-medium whitespace-nowrap"></TableCell>
        <TableCell
          scope="row"
          className="w-2/12 py-4 px-6 font-medium whitespace-nowrap">
          Failed to get data
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div className="m-5">
      <div className="flex justify-between ml-5 mt-7 items-center">
        <HierarchicalBreadcrumbs outcomes={true} leaderboard={true} />
      </div>
      <Card
        style={{ maxHeight: '42rem' }}
        className="m-2 my-4 overflow-y-auto overflow-x-hidden relative shadow-md sm:rounded-lg">
        <TableContainer className="w-full text-sm text-left">
          <div className="p-5 text-lg font-semibold text-left ">
            <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
              User Rankings
            </Typography>
            <Typography color="text.secondary" sx={{ fontSize: '0.875rem' }}>
              View the highest ranked Hivemind users for evaluator and generator
              scores.
            </Typography>
            <div className="flex w-full justify-evenly items-center font-normal text-base mt-2 flex-wrap">
              <div className="w-96 my-1 flex justify-center">
                <ToggleButtonGroup
                  color="primary"
                  value={leaderboardSort.type}
                  exclusive
                  onChange={changeType}>
                  <ToggleButton value="generatorScore">
                    Generator Score
                  </ToggleButton>
                  <ToggleButton value="evaluatorScore">
                    Evaluator Score
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>

              <div className="w-96 my-1 flex justify-center">
                <ToggleButtonGroup
                  color="primary"
                  value={leaderboardSort.scoreType}
                  exclusive
                  onChange={changeScoreType}>
                  <ToggleButton value="confirmed">Confirmed</ToggleButton>
                  <ToggleButton value="current">Current</ToggleButton>
                  {leaderboardSort.type === 'generatorScore' && (
                    <ToggleButton value="total">Total</ToggleButton>
                  )}
                </ToggleButtonGroup>
              </div>
            </div>
          </div>
          <Table>
            <TableHead className="text-xs">
              <TableRow>
                <TableCell scope="col" className="w-1/12 py-3 px-6">
                  #
                </TableCell>
                <TableCell scope="col" className="w-2/12 py-3 px-6">
                  User
                </TableCell>
                <TableCell scope="col" className="w-3/12 py-3 px-6">
                  <div className="flex items-center">
                    <span className="">{getLabel()}</span>
                    <div className="ml-1 self-start">
                      <InfoTooltip text={getTooltipText()} />
                    </div>
                    <div className="ml-1 flex flex-col">
                      <Button
                        sx={{ p: 0.1, minWidth: 0 }}
                        onClick={(e) => upChevronClicked(e)}>
                        <FiTriangle
                          fill={chevronColour('up')}
                          stroke={chevronColour('up')}
                          size={'12'}
                        />
                      </Button>
                      <Button
                        sx={{ p: 0.1, minWidth: 0 }}
                        onClick={(e) => downChevronClicked(e)}>
                        <FiTriangle
                          fill={chevronColour('down')}
                          stroke={chevronColour('down')}
                          style={{ transform: 'rotate(180deg)' }}
                          size={'12'}
                        />
                      </Button>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableContent}</TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
}
