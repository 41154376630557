import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import RoleBadgeIcon from '../icons/RoleBadgeIcon';

import { useDispatch, useSelector } from 'react-redux';
import {
  deleteComment,
  selectCommentById,
  selectReplyIdsByComment,
  updateComment
} from '../../store/slices/outcomeSlice';

import { selectSettingByName } from '../../store/slices/settingsSlice';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Button,
  Card,
  Collapse,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import moment from 'moment';
import { FiDelete, FiEdit, FiLink } from 'react-icons/fi';
import { GoComment } from 'react-icons/go';
import { AuthContext, checkTokenStatus } from '../../App';
import CreateCommentModal from '../../components/modals/CreateCommentModal';
import ConfirmationModal from '../modals/ConfirmationModal';

export default function CommentCard({
  questionId,
  commentId,
  maxIndentLevels,
  indentLevel
}) {
  const dispatch = useDispatch();

  const { setIsLoggedIn, userData } = useContext(AuthContext);

  const [commentExists, setCommentExists] = useState(false);
  const [showAddReply, setShowAddReply] = useState(false);
  const navigate = useNavigate();
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const comment = useSelector((state) => selectCommentById(state, commentId));
  const replyIds = useSelector((state) =>
    selectReplyIdsByComment(state, commentId)
  );
  const usersData = useSelector((state) =>
    state.users.entities
  );
  const [editCard, setEditCard] = useState(false);
  const [deleteCommentRequestStatus, setDeleteCommentRequestStatus] =
    useState('idle');
  const [updateCommentRequestStatus, setUpdateCommentRequestStatus] =
    useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [repliesCollapsed, setRepliesCollapsed] = useState(false);
  const [indentCollapsed, setIndentCollapsed] = useState(
    indentLevel > maxIndentLevels
  );
  const [extraReplies, setExtraReplies] = useState(false);

  const [commentText, setCommentText] = useState(comment.text);

  const timeSincePosted = () => {
    return moment(comment.created_at, 'YYYY-MM-DDThh:mm:ss').fromNow();
  };

  const openCommentView = () => {
    navigate(`/questions/${questionId}/comment/${commentId}`);
  };

  const changeCommentText = (event) => {
    setCommentText(event.target.value);
  };
  const changeRepliesCollapsed = (event) => {
    setRepliesCollapsed(!repliesCollapsed);
  };
  const showExtraReplies = (event) => {
    setExtraReplies(true);
  };

  useEffect(() => {
    if (comment !== undefined) {
      if (checkTokenStatus() === true) {
        setIsLoggedIn(true);
        setCommentExists(true);
      }
    } else {
      setCommentExists(false);
    }
  }, [comment, setIsLoggedIn, userData]);
  let replyContent;
  replyContent = replyIds.map((replyId, index) => (
    <div key={index}>
      {index < 3 && (
        <>
          <CommentCard
            questionId={questionId}
            commentId={replyId}
            maxIndentLevels={maxIndentLevels}
            indentLevel={indentLevel + 1}
          />
        </>
      )}
    </div>
  ));

  let extraReplyContent;
  extraReplyContent = replyIds.map((replyId, index) => (
    <div key={index}>
      {index >= 3 && (
        <CommentCard
          questionId={questionId}
          commentId={replyId}
          maxIndentLevels={maxIndentLevels}
          indentLevel={indentLevel + 1}
        />
      )}
    </div>
  ));

  const canUpdateComment =
    [comment.id, commentText, checkTokenStatus()].every(Boolean) &&
    updateCommentRequestStatus === 'idle';

  const updateCommentData = async () => {
    if (canUpdateComment) {
      let isMounted = true;
      try {
        setUpdateCommentRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: comment.id,
          text: commentText,
          auth_token: token
        };
        await dispatch(updateComment(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setEditCard(!editCard);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to update comment: ${err.message}`);
      } finally {
        if (isMounted) {
          setUpdateCommentRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Comment could not be updated.');
    }
  };

  const canDeleteComment =
    [comment.id, checkTokenStatus()].every(Boolean) &&
    deleteCommentRequestStatus === 'idle';

  const deleteCommentData = async () => {
    if (canDeleteComment) {
      let isMounted = true;
      try {
        setDeleteCommentRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          commentId: comment.id,
          auth_token: token
        };
        await dispatch(deleteComment(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setEditCard(!editCard);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to delete comment: ${err.message}`);
      } finally {
        if (isMounted) {
          setDeleteCommentRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      setErrorMessage('Comment could not be deleted.');
    }
  };
  const isEdited = () => {
    return comment.modified_at ? true : false;
  };

  if (usernameSettings === undefined) {
    return null;
  } else {
    return (
      commentExists === true &&
      (userData !== undefined || userData !== null) && (
        <div id={'commentCard' + commentId}>
          <Card
            className={`CommentCard ml-20 mr-10 p-2 border-l-4 border-slate-700 break-words mt-2 ${
              replyIds.length > 0 ? 'mb-2' : 'mb-5'
            }`}>
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}
            {editCard && userData.username === comment.created_by.username ? (
              <>
                <div className="flex text-sm font-extralight justify-start">
                  <Typography
                    sx={{
                      fontSize: '0.875rem'
                    }}
                    color="text.secondary">
                    Commented {timeSincePosted()}
                  </Typography>
                  {(userData.role === 'Admin' ||
                    userData.role === 'Moderator' ||
                    usernameSettings.active) && (
                    <>
                      <Typography
                        color="text.secondary"
                        sx={{
                          fontSize: '0.875rem',
                          ml: 0.6
                        }}>
                        by
                      </Typography>
                      <Typography
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/profile/${comment.created_by.username}`);
                        }}
                        sx={{ ml: 0.6, fontSize: '0.875rem' }}
                        className="hover:underline hover:cursor-pointer"
                        color="primary.main">
                        {comment.created_by.username}
                      </Typography>
                    </>
                  )}
                </div>
                <TextField
                  variant="outlined"
                  type="text"
                  size="small"
                  sx={{ m: 1 }}
                  value={commentText}
                  onChange={(event) => changeCommentText(event)}
                  className="w-full my-1 p-1 rounded border-solid border-2"
                />
              </>
            ) : (
              <>
                <div className="flex justify-between items-start max-h-16 overflow-y-auto">
                  <Typography sx={{ fontSize: '1rem' }}>
                    <span className="whitespace-pre-line">{comment.text}</span>
                  </Typography>
                  <Tooltip title="Permalink" placement="bottom">
                    <button
                      className="text-grey-darkest text-xs rounded inline-flex items-center justify-center"
                      onClick={openCommentView}>
                      <FiLink className="mr-1" />
                    </button>
                  </Tooltip>
                </div>
                <div className="flex text-sm font-extralight justify-start">
                  <Typography
                    sx={{
                      fontSize: '0.875rem',
                      ml: 0.6
                    }}
                    color="text.secondary">
                    Commented {timeSincePosted()}
                  </Typography>
                  {(userData.role === 'Admin' ||
                    userData.role === 'Moderator' ||
                    usernameSettings.active) && (
                    <>
                      <Typography
                        color="text.secondary"
                        sx={{
                          fontSize: '0.875rem',
                          ml: 0.6
                        }}>
                        by
                      </Typography>
                      <Typography
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/profile/${comment.created_by.username}`);
                        }}
                        sx={{ ml: 0.6, fontSize: '0.875rem' }}
                        className="hover:underline hover:cursor-pointer"
                        color="primary.main">
                        {comment.created_by.username}
                      </Typography>
                      <div className="pb-1">
                  <RoleBadgeIcon role={usersData[comment.created_by.id].role} />
                </div>
                    </>
                  )}
                  {isEdited() && <p className="mr-1">*</p>}
                </div>
              </>
            )}
            <div className="flex items-start justify-between">
              <div className="flex">
                <>
                  <div className="flex items-center mr-2">
                    <Button size="small" onClick={() => setShowAddReply(true)}>
                      <GoComment className="mr-1" />
                      <span>Reply</span>
                    </Button>
                  </div>
                  {comment.created_by.username === userData.username && (
                    <div className="flex items-center mr-2">
                      <Button
                        size="small"
                        onClick={() => {
                          setEditCard(!editCard);
                        }}>
                        <FiEdit className="mr-1" />
                        {editCard ? 'Cancel' : 'Edit'}
                      </Button>
                    </div>
                  )}
                  {comment.created_by.username === userData.username && (
                    <div className="flex items-center mr-2">
                      <Button
                        size="small"
                        onClick={() => {
                          setShowDeleteConfirm(true);
                        }}>
                        <FiDelete className="mr-1" />
                        Delete
                      </Button>
                    </div>
                  )}
                </>
              </div>
              {editCard && (
                <div className="flex justify-end p-1">
                  <Button
                    variant="contained"
                    onClick={updateCommentData}
                    size="small">
                    Save
                  </Button>
                </div>
              )}
            </div>
          </Card>
          <Collapse
            className="w-full"
            in={indentCollapsed && replyIds.length > 0}>
            <Button
              variant="text"
              size="small"
              sx={{ ml: 12, pl: 1.2, my: 0.6, mb: 1.2 }}
              onClick={openCommentView}>
              Continue comment thread..
            </Button>
          </Collapse>
          <Collapse className="w-full" in={!indentCollapsed}>
            {replyIds.length > 0 && (
              <div className="ml-20">
                <IconButton
                  onClick={changeRepliesCollapsed}
                  aria-label="delete"
                  size="small">
                  {repliesCollapsed ? (
                    <AddIcon fontSize="small" />
                  ) : (
                    <RemoveIcon fontSize="small" />
                  )}
                </IconButton>
              </div>
            )}
            <Collapse className="w-full" in={!repliesCollapsed}>
              <section className="reply-list ml-10">
                {replyContent}
                <Collapse className="w-full" in={extraReplies}>
                  {extraReplyContent}
                </Collapse>
                <Collapse
                  className="w-full"
                  in={replyIds.length > 2 && !extraReplies}>
                  <Button
                    variant="text"
                    size="small"
                    sx={{ ml: 12, pl: 1.2, mb: 1.2 }}
                    onClick={showExtraReplies}>
                    View {replyIds.length - 2} more repl
                    {replyIds.length > 3 ? 'ies' : 'y'}
                  </Button>
                </Collapse>
              </section>
            </Collapse>
          </Collapse>

          {showAddReply && (
            <CreateCommentModal
              shown={showAddReply}
              close={() => {
                setShowAddReply(false);
              }}
              afterSubmit={() => {
                setShowAddReply(false);
              }}
              parentCommentId={commentId}
            />
          )}

          {showDeleteConfirm && (
            <ConfirmationModal
              shown={showDeleteConfirm}
              close={() => {
                setShowDeleteConfirm(false);
              }}
              confirm={() => {
                setShowDeleteConfirm(false);
                deleteCommentData();
              }}
              confirmationMessage="Do you really want to delete this comment? This process cannot be undone"
            />
          )}
        </div>
      )
    );
  }
}
