import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../App';
import OutcomeContainer from '../../components/lists/ForecastingList/OutcomeContainer';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import {
  fetchAllForecasts,
  fetchOutcomes,
  fetchQuestions,
  selectOutcomesByStatus
} from '../../store/slices/outcomeSlice';

export default function QuestionForecasting() {
  const dispatch = useDispatch();
  const { userData } = useContext(AuthContext);

  const outcomeStatus = useSelector((state) => state.outcomes.outcomes.status);
  const questionStatus = useSelector(
    (state) => state.outcomes.questions.status
  );
  const forecastsStatus = useSelector(
    (state) => state.outcomes.forecasts.status
  );

  const forecastingOutcomes = useSelector((state) =>
    selectOutcomesByStatus(state, 'Forecasting')
  );
  const closedOutcomes = useSelector((state) =>
    selectOutcomesByStatus(state, 'Closed')
  );

  useEffect(() => {
    if (outcomeStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchOutcomes({ auth_token: token }));
    }
  }, [outcomeStatus, dispatch]);

  useEffect(() => {
    if (questionStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchQuestions({ auth_token: token }));
    }
  }, [questionStatus, dispatch]);

  useEffect(() => {
    if (forecastsStatus === 'idle') {
      dispatch(fetchAllForecasts());
    }
  }, [forecastsStatus, dispatch]);

  let outcomeList;

  if (forecastingOutcomes || closedOutcomes) {
    let allOutcomes = forecastingOutcomes.concat(closedOutcomes);
    outcomeList = allOutcomes.map((outcome) => (
      <OutcomeContainer key={outcome.id} outcome={outcome} />
    ));
  }

  return (
    <div className="mt-5 mb-14 mx-10">
      <div className="flex ml-10 mt-7 items-center"></div>
      <HierarchicalBreadcrumbs outcomes={true} forecasting={true} />
      {outcomeList}
    </div>
  );
}
