import React from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

import { selectSettingByName } from "../../store/slices/settingsSlice";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { selectUserByUsername } from "../../store/slices/userSlice";
import ProfileCard from "../../components/cards/ProfileCard";
import HierarchicalBreadcrumbs from "../../components/other/HierarchicalBreadcrumbs";

export default function Profile() {
    const usernameSettings = useSelector(state => selectSettingByName(state, "View Usernames"))
    const { username } = useParams();
    const user = useSelector(state => selectUserByUsername(state, username))
    const userStatus = useSelector(state => state.users.status);

    const navigate = useNavigate();

    return usernameSettings !== undefined ? (
        <div className="Profile m-5">
            <div className="flex justify-between ml-5 mt-7 items-center">
                <HierarchicalBreadcrumbs
                    outcomes={true}
                    profile={true}
                />
            </div>
            {userStatus === 'succeeded'
                && <ProfileCard userId={user.id} />
            }
        </div>
    ) : null;
}