import { ViewAgenda, ViewList } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserPreferences,
  setQuestionLayout,
  updateUserPreferences
} from '../../store/slices/userSlice';

export default function QuestionLayoutToggle() {
  const userPreferences = useSelector((state) => selectUserPreferences(state));
  const dispatch = useDispatch();

  const handleLayoutChange = (event, newLayout) => {
    localStorage.setItem('questionLayout', newLayout);
    dispatch(setQuestionLayout(newLayout));
  };

  return (
    <div>
      <ToggleButtonGroup
        value={userPreferences.question_layout}
        exclusive
        onChange={handleLayoutChange}
        aria-label="Layout"
        sx={{}}>
        <ToggleButton color="primary" sx={{}} variant="outlined" value="card">
          <ViewAgenda />
          Card
        </ToggleButton>
        <ToggleButton color="primary" sx={{}} variant="outlined" value="detail">
          <ViewList />
          Detail
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
