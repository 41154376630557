import { AccessTime } from '@mui/icons-material';
import { Button, Card, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export default function TimeOutModal() {
  const token = localStorage.getItem('auth_token');
  const [shown, setShown] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    let timeout;

    if (token) {
      const tokenBody = JSON.parse(atob(token.split('.')[1]));
      const tokenExp = tokenBody.exp;
      const now = new Date().getTime() / 1000;
      const timeLeft = tokenExp - now - 300;
      if (timeLeft > 0) {
        timeout = setTimeout(() => {
          setMessage('Your session will timeout in 5 minutes');
          setShown(true);
        }, timeLeft * 1000);
      } else if (timeLeft < -300 && timeLeft > -3600) {
        setMessage('Your session has timed out');
        setShown(true);
        // } else {
        //   const minutes = Math.floor((timeLeft + 300) / 60);
        //   const seconds = Math.floor((timeLeft + 300) % 60);
        //   if (minutes === 1) {
        //     setMessage(
        //       `Your session will timeout in ${minutes} minute and ${seconds} seconds`
        //     );
        //   } else {
        //     setMessage(
        //       `Your session will timeout in ${minutes} minutes and ${seconds} seconds`
        //     );
        //   }
        //   setShown(true);
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [token]);

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Card
        className="modal-content flex w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div className="m-auto w-full">
          <div className="flex items-center justify-center">
            <AccessTime fontSize="large" />
          </div>
          <div className="flex items-center justify-center py-1">
            <Typography
              sx={{ fontWeight: 700 }}
              className="text-lg font-extrabold text-center"
              color="#FFA500">
              {message}
            </Typography>
          </div>
          <div className="flex items-center">
            <div className="w-full m-2">
              <Button
                variant="contained"
                onClick={() => setShown(false)}
                className="w-full">
                Close
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  ) : null;
}
