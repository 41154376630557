import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectSettingById } from "../../store/slices/settingsSlice";

import SettingCheckbox from "../../components/other/SettingsCheckbox";
import SettingCheckboxes from "./SettingCheckboxes";
import SettingValue from "./SettingsValue";

export default function Setting({ id }) {
    const setting = useSelector(state => selectSettingById(state, id));

    let content;
    if (setting.active !== undefined) {
        content = <SettingCheckbox id={id}/>
    } else if (setting.value !== undefined) {
        content = <SettingValue id={id}/>
    } else if (setting.options !== undefined) {
        content = <SettingCheckboxes id={id}/>
    } else {
        content = null
    }

    return (
        <div className="Setting">
            {content}
        </div>
    )
}
