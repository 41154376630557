import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Card,
  Collapse,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectQuestionsByOutcome } from '../../../store/slices/outcomeSlice';
import QuestionContainer from './QuestionContainer';

export default function OutcomeContainer({ outcome }) {
  const questions = useSelector((state) =>
    selectQuestionsByOutcome(state, outcome.id)
  );
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  let questionList;

  if (questions) {
    let filtereduestions = questions.filter(
      (question) =>
        question.status === 'Submitted' || question.status === 'Closed'
    );
    questionList = filtereduestions.map((question) => (
      <QuestionContainer
        key={question.id}
        question={question}
        forecastingStartDate={outcome.question_deadline}
        maxForecastingWeeks={outcome.forecasting_weeks}
      />
    ));
  }

  const getStatusColour = () => {
    switch (outcome.status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };

  const showOutcome = questionList.length > 0;

  return (
    showOutcome && (
      <Card sx={{ py: 1, px: 2, m: 2, borderRadius: '0.5rem' }}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center', fontSize: '0.875rem' }}>
          <HiOutlineDocumentText className="mr-1" /> Outcome
        </Typography>
        <div className="flex justify-between">
          <div className="flex items-center">
            <Typography sx={{ fontWeight: 600, fontSize: '1rem' }}>
              {outcome.title}
            </Typography>
            <Typography
              sx={{ fontSize: '0.875rem', ml: 1.2, mr: 0.6 }}
              color="text.secondary">
              Created by{' '}
            </Typography>
            <Typography
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/profile/${outcome.created_by.username}`);
              }}
              sx={{ fontSize: '0.875rem', mr: 1.2 }}
              className="hover:underline hover:cursor-pointer"
              color="primary.main">
              {outcome.created_by.username}
            </Typography>
            <Typography
              sx={{
                color: getStatusColour(),
                fontWeight: 'bold',
                fontSize: '0.9rem'
              }}
              className={`rounded font-semibold text-xs`}>
              {outcome.status}
            </Typography>
          </div>
          <IconButton
            className="mr-2"
            onClick={() => setCollapsed(!collapsed)}
            size="small">
            {collapsed ? <AddIcon /> : <RemoveIcon />}
          </IconButton>
        </div>
        <Collapse in={!collapsed}>{questionList}</Collapse>
      </Card>
    )
  );
}
