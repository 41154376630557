import { useTheme } from '@mui/material';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchForecastsByQuestion,
  selectForecastsByQuestion
} from '../../../store/slices/outcomeSlice';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

export default function ForecastTimeline({
  questionId,
  axisLabels,
  width,
  title,
  forecastingStartDate,
  falseData
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [forecastsStatus, setForecastsStatus] = useState('idle');

  const forecastData = useSelector((state) =>
    selectForecastsByQuestion(state, questionId)
  );

  useEffect(() => {
    if (forecastsStatus === 'idle') {
      dispatch(fetchForecastsByQuestion(questionId)).then(
        setForecastsStatus('success')
      );
    }
  }, [forecastsStatus, dispatch, questionId]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: title !== undefined ? true : false,
        text: title !== undefined ? title : '',
        color: theme.palette.text.secondary,
        font: {
          size: 20
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = `True: ${context.parsed.y}%`;
            return label;
          },
          title: function (context) {
            let title = moment(context[0].parsed.x).format(
              'dddd, MMMM Do YYYY, h:mm:ss a'
            );
            return title;
          }
        }
      }
    },
    scales: {
      y: {
        min: 0,
        max: 100,
        title: {
          display: axisLabels,
          text: 'Probability',
          color: theme.palette.text.secondary,
          font: {
            size: 20
          }
        },
        ticks: {
          callback: function (value, index, ticks) {
            return value + '%';
          },
          color: theme.palette.text.primary
        },
        grid: {
          display: true,
          color: theme.palette.background.card
        }
      },
      x: {
        type: 'time',
        time: {
          unit:"day"
        },
        title: {
          display: false
        },
        grid: {
          display: true,
          color: theme.palette.background.card
        },
        ticks: {
          color: theme.palette.text.primary,
          autoSkip: true,
          source: 'auto',
          maxTicksLimit: width === 'large' ? 10 : 5
        }
      }
    }
  };
  var data={}
  if (!falseData){
    data = {
      labels: forecastData.map((f) =>
        moment(forecastingStartDate).add(f.forecast_week - 1, 'weeks')
      ),
      datasets: [
        {
          label: 'Dataset 1',
          data: forecastData.map((f) => f.probability * 100),
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main
        }
      ]
    };
  } else {
    data = falseData
  }

  var dimensions={}
  switch (width) {
    case 'large':
      dimensions={width:'38rem', height:'16rem'}
      break;
    case 'small':
      dimensions={width:'16rem', height:'5rem'}
      break;
    case 'card':
      dimensions={width:'100%', height:'10vw'}
      break;
    default:
      dimensions={width:'100%', height:'10vw'}
  }
  return (
    <div style={dimensions}>
      <Line options={options} data={data} />
    </div>
  );
}
