import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { hivemindAPI } from '../HivemindAPI';

const usersAdapter = createEntityAdapter({});

const initialState = usersAdapter.getInitialState({
  status: 'idle',
  error: null,
  leaderboardSort: {
    key: 'Generator Score',
    order: 'desc',
    type: 'generatorScore',
    scoreType: 'confirmed'
  },
  totalUsers: 0,
  userPreferences: {
    outcome_layout: localStorage.getItem('outcomeLayout')
      ? localStorage.getItem('outcomeLayout')
      : 'card',
    question_layout: localStorage.getItem('questionLayout')
      ? localStorage.getItem('questionLayout')
      : 'card'
  }
});

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async (auth_token) => {
    const response = await hivemindAPI.fetch('/api/users', null, auth_token);
    return response.json();
  }
);

export const fetchUserPreferences = createAsyncThunk(
  'users/fetchUserPreferences',
  async (auth_token) => {
    const response = await hivemindAPI.fetch(
      '/api/auth/user/preferences',
      null,
      auth_token
    );
    return response.json();
  }
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (payload) => {
    const response = await hivemindAPI.delete(
      '/api/users',
      {
        username: payload.username
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/users',
      {
        id: payload.id,
        username: payload.usernmae,
        role: payload.role,
        email: payload.email
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateUserPreferences = createAsyncThunk(
  'users/updateUserPreferences',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/auth/user/preferences',
      {
        email_notifications: payload.emailNotifications
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const registerUser = createAsyncThunk(
  'users/registerUser',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/auth/register',
      {
        username: payload.username,
        password: payload.password,
        role: payload.role,
        email: payload.email
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const changeUserPassword = createAsyncThunk(
  'users/changeUserPassword',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/auth/password',
      {
        username: payload.username,
        password: payload.password,
        new_password: payload.newPassword
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const login = createAsyncThunk('users/login', async (payload) => {
  const response = await hivemindAPI.post('/api/auth/login', {
    username: payload.username,
    password: payload.password
  });
  return response.json();
});

export const logout = createAsyncThunk('users/logout', async (auth_token) => {
  const response = await hivemindAPI.post('/api/auth/logout', null, auth_token);
  return response.json();
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setLeaderboardSort: {
      reducer(state, action) {
        state.leaderboardSort = {
          key: action.payload.key
            ? action.payload.key
            : state.leaderboardSort.key,
          order: action.payload.order
            ? action.payload.order
            : state.leaderboardSort.order,
          type: action.payload.type
            ? action.payload.type
            : state.leaderboardSort.type,
          scoreType: action.payload.scoreType
            ? action.payload.scoreType
            : state.leaderboardSort.scoreType
        };
      }
    },
    setOutcomeLayout: {
      reducer(state, action) {
        state.userPreferences.outcome_layout = action.payload;
      }
    },
    setQuestionLayout: {
      reducer(state, action) {
        state.userPreferences.question_layout = action.payload;
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.totalUsers = action.payload.data.length;
        usersAdapter.upsertMany(state, action.payload.data);
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        const updatedUser = action.payload.data;
        usersAdapter.updateOne(state, {
          id: updatedUser.id,
          changes: updatedUser
        });
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        usersAdapter.removeOne(state, action.meta.arg.id);
      })
      .addCase(fetchUserPreferences.fulfilled, (state, action) => {
        state.userPreferences.emailNotifications =
          action.payload.data.email_notifications;
      })
      .addCase(updateUserPreferences.fulfilled, (state, action) => {
        state.userPreferences.emailNotifications =
          action.payload.data.email_notifications;
      });
  }
});

export const { setLeaderboardSort, setOutcomeLayout, setQuestionLayout } =
  usersSlice.actions;

export default usersSlice.reducer;

export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUsersIds
} = usersAdapter.getSelectors((state) => state.users);

// export const selectUsernames = (state) => {
//     return state.users.entities.map(user => user.username)
// }

export const selectUsernames = createSelector(
  [selectAllUsers, (state) => state],
  (users) => {
    return users.map((user) => user.username);
  }
);

export const selectUserByUsername = createSelector(
  [selectAllUsers, (state, username) => username],
  (users, username) => users.find((user) => user.username === username)
);

export const selectLeaderboardSort = createSelector(
  (state) => state.users,
  (state) => state.leaderboardSort
);

export const selectLeaderboardUsers = createSelector(
  [(state) => state.users, selectAllUsers],
  (state, users) => {
    if (state.leaderboardSort.type === 'generatorScore') {
      if (state.leaderboardSort.order === 'asc') {
        if (state.leaderboardSort.scoreType === 'confirmed') {
          users = users.filter((user) => user.confirmed_generator_score !== 0);
          users.sort(
            (userA, userB) =>
              userA.confirmed_generator_score - userB.confirmed_generator_score
          );
        } else if (state.leaderboardSort.scoreType === 'current') {
          users = users.filter((user) => user.current_generator_score !== 0);
          users.sort(
            (userA, userB) =>
              userA.current_generator_score - userB.current_generator_score
          );
        } else if (state.leaderboardSort.scoreType === 'total') {
          users = users.filter((user) => user.total_generator_score !== 0);
          users.sort(
            (userA, userB) =>
              userA.total_generator_score - userB.total_generator_score
          );
        }
      } else if (state.leaderboardSort.order === 'desc') {
        if (state.leaderboardSort.scoreType === 'confirmed') {
          users = users.filter((user) => user.confirmed_generator_score !== 0);
          users.sort(
            (userA, userB) =>
              userB.confirmed_generator_score - userA.confirmed_generator_score
          );
        } else if (state.leaderboardSort.scoreType === 'current') {
          users = users.filter((user) => user.current_generator_score !== 0);
          users.sort(
            (userA, userB) =>
              userB.current_generator_score - userA.current_generator_score
          );
        } else if (state.leaderboardSort.scoreType === 'total') {
          users = users.filter((user) => user.total_generator_score !== 0);
          users.sort(
            (userA, userB) =>
              userB.total_generator_score - userA.total_generator_score
          );
        }
      }
    } else if (state.leaderboardSort.type === 'evaluatorScore') {
      if (state.leaderboardSort.order === 'asc') {
        if (state.leaderboardSort.scoreType === 'confirmed') {
          users = users.filter((user) => user.confirmed_evaluator_score !== 0);
          users.sort(
            (userA, userB) =>
              userA.confirmed_evaluator_score - userB.confirmed_evaluator_score
          );
        } else if (state.leaderboardSort.scoreType === 'current') {
          users = users.filter((user) => user.current_evaluator_score !== 0);
          users.sort(
            (userA, userB) =>
              userA.current_evaluator_score - userB.current_evaluator_score
          );
        }
      } else if (state.leaderboardSort.order === 'desc') {
        if (state.leaderboardSort.scoreType === 'confirmed') {
          users = users.filter((user) => user.confirmed_evaluator_score !== 0);
          users.sort(
            (userA, userB) =>
              userB.confirmed_evaluator_score - userA.confirmed_evaluator_score
          );
        } else if (state.leaderboardSort.scoreType === 'current') {
          users = users.filter((user) => user.current_evaluator_score !== 0);
          users.sort(
            (userA, userB) =>
              userB.current_evaluator_score - userA.current_evaluator_score
          );
        }
      }
    }
    return users;
  }
);

export const selectUserPreferences = createSelector(
  (state) => state.users,
  (state) => state.userPreferences
);
