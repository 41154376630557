import { CheckBox } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Link,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import ChangeEmailModal from '../../components/modals/ChangeEmailModal';
import PasswordVerificationModal from '../../components/modals/PasswordVerificationModal';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import {
  changeUserPassword,
  selectUserById
} from '../../store/slices/userSlice';

export default function AccountSettings() {
  const { userData } = useContext(AuthContext);

  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const [tab, setTab] = useState('1');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordVerification, setNewPasswordVerification] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [changeRequestStatus, setChangeRequestStatus] = useState('idle');
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [showPasswordVerificationModal, setShowPasswordVerificationModal] =
    useState(false);
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const user = useSelector((state) => selectUserById(state, userData.id));
  const navigate = useNavigate();
  const changeCurrentPasswordText = (event) =>
    setCurrentPassword(event.target.value);
  const changeNewPasswordText = (event) => setNewPassword(event.target.value);
  const changeNewPasswordVerificationText = (event) =>
    setNewPasswordVerification(event.target.value);

  const checkPasswordsMatch = () => {
    return newPassword === newPasswordVerification;
  };

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  const canChange =
    [userData.username, currentPassword, newPassword].every(
      (val) => val !== ''
    ) &&
    checkTokenStatus() &&
    checkPasswordsMatch() &&
    changeRequestStatus === 'idle';

  const changePassword = async () => {
    if (canChange) {
      let isMounted = true;
      try {
        setIsLoggedIn(true);
        const token = localStorage.getItem('auth_token');
        let payload = {
          username: userData.username,
          password: currentPassword,
          newPassword: newPassword,
          auth_token: token
        };
        await dispatch(changeUserPassword(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setCurrentPassword('');
              setNewPassword('');
              setNewPasswordVerification('');
              setPasswordMatchError(false);
            }
            if (response.status === 'success') {
              setResponseMessage(`Password Successfully Updated`);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to change password: ${err.message}`);
      } finally {
        if (isMounted) {
          setChangeRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (!userData.username) {
      setErrorMessage(
        'User details could not be gathered. Please try logging in again.'
      );
    } else if (!currentPassword) {
      setErrorMessage(
        'Ensure you enter your Current Password before submitting.'
      );
    } else if (!newPassword) {
      setErrorMessage('Ensure a New Password exists before submitting.');
    } else if (!newPasswordVerification) {
      setPasswordMatchError(true);
    } else if (!checkPasswordsMatch()) {
      setPasswordMatchError(true);
    } else if (!checkTokenStatus()) {
      setErrorMessage('Must be logged in to change password.');
      localStorage.removeItem('auth_token');
      setIsLoggedIn(false);
      return <Navigate to="/login" />;
    } else {
      setErrorMessage('Could not change password.');
    }
  };

  const verifyPassword = () => {
    setShowPasswordVerificationModal(true);
  };

  const updateEmail = () => {
    setShowChangeEmailModal(true);
  };

  const openDemographicsQuestionnaire = (id) => {
    navigate(`/questionnaire/demographics`);
  };

  const openPersonalityQuestionnaire = (id) => {
    navigate(`/questionnaire/personality`);
  };

  const openOpennessQuestionnaire = (id) => {
    navigate(`/questionnaire/open-minded`);
  };

  const userDemographicsCompleted =
    user && user.questionnaires_responsed
      ? user.questionnaires_responsed.some((q) => q.id === 1)
      : false;

  const personalityComplete =
    user && user.questionnaires_responsed
      ? user.questionnaires_responsed.some((q) => q.id === 2)
      : false;

  const opennessComplete =
    user && user.questionnaires_responsed
      ? user.questionnaires_responsed.some((q) => q.id === 3)
      : false;

  return (
    <div className="AccountSettings">
      <div className="m-5">
        <div className="flex justify-between ml-5 mt-7 items-center">
          <HierarchicalBreadcrumbs outcomes={true} settings={true} />
        </div>

        <div className="w-full flex justify-center">
          <Card className="flex sm:w-11/12 md:w-9/12 lg:w-1/2 xl:w-5/12 p-1 items-center justify-end flex-col">
            <div className="w-full p-3 mt-2">
              <TabContext value={tab} sx={{ width: '100%' }}>
                <Box sx={{ mb: 2 }}>
                  <TabList onChange={handleChange}>
                    <Tab label="Account" value="1" />
                    <Tab label="Questionnaires" value="2" />
                    <Tab label="Notifications" value="3" />
                  </TabList>
                </Box>
                <TabPanel value={'1'} sx={{ p: 1 }}>
                  <Divider sx={{ mt: 2 }} />
                  <Typography
                    sx={{ mt: 0.5, ml: 2 }}
                    color="text.secondary"
                    display="block"
                    variant="caption">
                    Account Details
                  </Typography>
                  <div className="m-1 text-sm">
                    <div className="flex items-center">
                      <Typography sx={{ pr: 0.6, fontWeight: 'bold' }}>
                        Username:
                      </Typography>
                      <Typography>{userData.username}</Typography>
                    </div>
                    <div className="flex items-center">
                      <Typography sx={{ pr: 0.6, fontWeight: 'bold' }}>
                        Email:
                      </Typography>
                      {userData.email ? userData.email : 'Not set'}
                      <Link
                        component="button"
                        sx={{ ml: 2.4 }}
                        onClick={verifyPassword}>
                        Update
                      </Link>
                    </div>
                    <div className="flex items-center">
                      <Typography sx={{ pr: 0.6, fontWeight: 'bold' }}>
                        Role:
                      </Typography>
                      <Typography>{userData.role}</Typography>
                    </div>
                    <div className="flex items-center">
                      <Typography sx={{ pr: 0.6, fontWeight: 'bold' }}>
                        Registered on:
                      </Typography>
                      <Typography>
                        {moment(userData.registered_on).format(
                          'dddd MMMM Do YYYY'
                        )}
                      </Typography>
                    </div>
                  </div>

                  <Divider sx={{ mt: 2 }} />
                  <Typography
                    sx={{ mt: 0.5, ml: 2 }}
                    color="text.secondary"
                    display="block"
                    variant="caption">
                    Change Password
                  </Typography>
                  <form className="m-1 flex flex-col justify-evenly items-start">
                    <TextField
                      id="current-password"
                      label="Current Password"
                      type="password"
                      autoComplete="current-password"
                      value={currentPassword}
                      onChange={(event) => changeCurrentPasswordText(event)}
                      size="small"
                      sx={{ my: 1.5 }}
                    />
                    <TextField
                      id="new-password"
                      label="New Password"
                      type="password"
                      value={newPassword}
                      onChange={(event) => changeNewPasswordText(event)}
                      size="small"
                      sx={{ my: 1 }}
                    />

                    <TextField
                      id="verify-new-password"
                      label="Verify New Password"
                      type="password"
                      value={newPasswordVerification}
                      onChange={(event) =>
                        changeNewPasswordVerificationText(event)
                      }
                      error={passwordMatchError}
                      helperText={
                        passwordMatchError ? 'Passwords must match' : ''
                      }
                      size="small"
                      sx={{ my: 1 }}
                    />

                    {errorMessage && (
                    <Typography color="error">{errorMessage}</Typography>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={changePassword}
                      sx={{
                        my: 2,
                        fontSize: '0.85rem',
                        textTransform: 'none'
                      }}>
                      Update Password
                    </Button>
                    {responseMessage && (
                      <p className="text text-sm text-center text-green-600 my-1">
                        {responseMessage}
                      </p>
                    )}
                  </form>
                </TabPanel>
                <TabPanel value={'2'} sx={{ p: 1 }}>
                  <Divider sx={{ mt: 2 }} />
                  <Typography
                    sx={{ mt: 0.5, ml: 2 }}
                    color="text.secondary"
                    display="block"
                    variant="caption">
                    Questionaires
                  </Typography>
                  <div className="my-1">
                    <div className="flex items-center justify-between">
                      <Typography sx={{ pr: 0.6, fontWeight: 'bold' }}>
                        User Demographics Questionnaire:
                      </Typography>
                      <div className="flex">
                        <Button
                          size="small"
                          onClick={openDemographicsQuestionnaire}>
                          {userDemographicsCompleted ? 'Redo' : 'Complete'}{' '}
                        </Button>
                        <Checkbox
                          checked={userDemographicsCompleted}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <Typography sx={{ pr: 0.6, fontWeight: 'bold' }}>
                        Personality Profile Questionnaire:
                      </Typography>
                      <div className="flex">
                        <Button
                          size="small"
                          onClick={openPersonalityQuestionnaire}>
                          {personalityComplete ? 'Redo' : 'Complete'}{' '}
                        </Button>
                        <Checkbox checked={personalityComplete} disabled />
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <Typography sx={{ pr: 0.6, fontWeight: 'bold' }}>
                        Open Minded Questionnaire:
                      </Typography>
                      <div className="flex">
                        <Button
                          size="small"
                          onClick={openOpennessQuestionnaire}>
                          {opennessComplete ? 'Redo' : 'Complete'}{' '}
                        </Button>
                        <Checkbox checked={opennessComplete} disabled />
                      </div>
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={'3'} sx={{ p: 1 }}>
                  <Divider sx={{ mt: 2 }} />
                  <Typography
                    sx={{ mt: 0.5, ml: 2 }}
                    color="text.secondary"
                    display="block"
                    variant="caption">
                    Notifications
                  </Typography>
                </TabPanel>
              </TabContext>
            </div>
          </Card>
        </div>
      </div>
      {showPasswordVerificationModal && (
        <PasswordVerificationModal
          shown={showPasswordVerificationModal}
          close={() => {
            setShowPasswordVerificationModal(false);
          }}
          afterVerify={updateEmail}
        />
      )}
      {showChangeEmailModal && (
        <ChangeEmailModal
          shown={showChangeEmailModal}
          close={() => {
            setShowChangeEmailModal(false);
          }}
        />
      )}
    </div>
  );
}
