import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { login, registerUser } from '../../store/slices/userSlice';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material';
import { AuthContext, checkTokenStatus } from '../../App';

export default function PasswordVerificationModal({
  shown,
  close,
  afterVerify
}) {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const { userData } = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [verifyRequestStatus, setVerifyRequestStatus] = useState('idle');
  const [showPassword, setShowPassword] = useState(false);

  const changePassword = (event) => setPassword(event.target.value);

  const canVerifyPassword =
    [password].every((val) => val !== '') &&
    checkTokenStatus() &&
    verifyRequestStatus === 'idle';

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlePasswordVerification = async () => {
    if (canVerifyPassword) {
      let isMounted = true;
      try {
        setVerifyRequestStatus('pending');
        setIsLoggedIn(true);
        const token = localStorage.getItem('auth_token');
        let payload = {
          username: userData.username,
          password: password
        };
        await dispatch(login(payload))
          .unwrap()
          .then((response) => {
            if (response.data.auth_token) {
              setPassword('');
              setIsLoggedIn(true);
              localStorage.setItem('auth_token', response.data.auth_token);
              afterVerify();
              setResponseMessage(`Password Correct`);
            }
          });
      } catch (err) {
        setErrorMessage(`Failed to verify password: ${err.message}`);
      } finally {
        if (isMounted) {
          setVerifyRequestStatus('idle');
          isMounted = false;
          closeModal();
        }
      }
    } else if (checkTokenStatus() === false) {
      localStorage.removeItem('auth_token');
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else if (password === '') {
      setErrorMessage('Please set the password before submitting');
    } else {
      setErrorMessage('Password could not be verified.');
    }
  };

  const closeModal = () => {
    close();
  };

  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Card
        className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3 z-10"
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <div>
          <Typography
            sx={{ fontSize: '1.1rem', fontWeight: 600 }}
            className="text-center">
            Verify your password
          </Typography>
          {!responseMessage && (
            <div>
              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}
              <div className="my-2">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    fullWidth
                    sx={{
                      '& fieldset': {
                        borderRadius: '9999px'
                      }
                    }}
                    value={password}
                    onChange={(event) => changePassword(event)}
                  />
                </FormControl>
              </div>

              <div className="flex mt-4">
                <div className="w-full m-2">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: 'gray',
                      ':hover': { backgroundColor: '#757575' }
                    }}
                    onClick={closeModal}
                    className="w-full">
                    Cancel
                  </Button>
                </div>

                <div className="w-full m-2">
                  <Button
                    variant="contained"
                    onClick={handlePasswordVerification}
                    className="w-full">
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  ) : null;
}
