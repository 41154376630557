import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import { AuthContext } from '../../App';
import {
  fetchOutcomes,
  selectOutcomeById,
  setQuestionFilter,
  updateSelectedOutcome
} from '../../store/slices/outcomeSlice';

import OutcomeCardQuestionView from '../../components/cards/OutcomeCardQuestionView';
import QuestionList from '../../components/lists/QuestionList';
import CreateQuestion from '../../components/other/CreateQuestion';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import QuestionStatusFilter from '../../components/other/QuestionStatusFilter';
import { selectUserPreferences } from '../../store/slices/userSlice';

export default function OutcomeView() {
  const dispatch = useDispatch();
  const { isLoggedIn, userData } = useContext(AuthContext);
  let { outcomeId } = useParams();
  const location = useLocation();
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  const [isVotingDisabled, setIsVotingDisabled] = useState(false);

  const outcome = useSelector((_state) => selectOutcomeById(_state, outcomeId));

  const outcomeStatus = useSelector(
    (_state) => _state.outcomes.outcomes.status
  );
  const [listLoaded, setListLoaded] = useState(false);

  useEffect(() => {
    let isMounted = true;
    function fetchOutcomeData() {
      if (isMounted) {
        if (outcomeStatus === 'idle') {
          const token = localStorage.getItem('auth_token');
          dispatch(fetchOutcomes({ auth_token: token }));
        }
      }
    }
    fetchOutcomeData();
    return () => {
      isMounted = false;
    };
  }, [outcomeStatus, dispatch, outcome]);

  useEffect(() => {
    if (outcome) {
      dispatch(updateSelectedOutcome(outcome.id));
    }
  }, [outcome, dispatch]);

  useEffect(() => {
    if (outcome) {
      let payload;
      switch (outcome.status) {
        case 'Generation':
          payload = {
            outcomeId: outcome.id,
            questionFilter: {
              pending: true,
              accepted: true,
              rejected: false,
              submitted: false,
              notSubmitted: false,
              closed: false,
              duplicate: false
            }
          };
          dispatch(setQuestionFilter(payload));
          break;
        case 'Evaluation':
          payload = {
            outcomeId: outcome.id,
            questionFilter: {
              pending: true,
              accepted: true,
              rejected: false,
              submitted: false,
              notSubmitted: false,
              closed: false,
              duplicate: false
            }
          };
          dispatch(setQuestionFilter(payload));
          break;
        case 'Forecasting':
          payload = {
            outcomeId: outcome.id,
            questionFilter: {
              pending: false,
              accepted: false,
              rejected: false,
              submitted: true,
              notSubmitted: false,
              closed: false,
              duplicate: false
            }
          };
          dispatch(setQuestionFilter(payload));
          break;
        case 'Closed':
          payload = {
            outcomeId: outcome.id,
            questionFilter: {
              pending: false,
              accepted: false,
              rejected: false,
              submitted: false,
              notSubmitted: false,
              closed: true,
              duplicate: false
            }
          };
          dispatch(setQuestionFilter(payload));
          break;
        default:
          break;
      }
    }
  }, [outcomeStatus, dispatch]);

  useEffect(() => {
    if (listLoaded) {
      if (location.hash) {
        let str;
        if (location.hash.includes('comment')) {
          let splitArr = location.hash.split('#', location.hash.length);
          splitArr = splitArr[1].split('comment', splitArr[1].length);
          str = 'commentCard' + splitArr[1];
        } else {
          let splitArr = location.hash.split('#', location.hash.length);
          str = 'questionCard' + splitArr[1];
        }
        let elem = document.getElementById(str);
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      }
    }
  }, [location, listLoaded]);

  const getDisplayableFilters = () => {
    switch (outcome.status) {
      case 'Generation':
        return [
          { key: 'pending', value: 'Pending' },
          { key: 'accepted', value: 'Accepted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' }
        ];
      case 'Evaluation':
        return [
          { key: 'pending', value: 'Pending' },
          { key: 'accepted', value: 'Accepted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' }
        ];
      case 'Forecasting':
        return [
          { key: 'submitted', value: 'Submitted' },
          { key: 'notSubmitted', value: 'Not Submitted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' }
        ];
      case 'Closed':
        return [
          { key: 'closed', value: 'Closed' },
          { key: 'notSubmitted', value: 'Not Submitted' },
          { key: 'rejected', value: 'Rejected' },
          { key: 'duplicate', value: 'Duplicate' }
        ];
      default:
        return [];
    }
  };

  let content;
  if (outcomeStatus === 'loading') {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (outcomeStatus === 'succeeded') {
    if (outcome === undefined) {
      content = (
        <p className="text-sm text-center">No questions could be found.</p>
      );
    } else {
      content = (
        <div className="flex justify-center">
          <div
            className={`w-full ${
              userPreferences.question_layout === 'card' ||
              userPreferences.question_layout === undefined
                ? 'md:w-2/3'
                : 'md:w-full'
            }`}>
            <OutcomeCardQuestionView outcome={outcome} />
            {outcome.status === 'Generation' &&
              outcome.created_by.id !== userData.id && (
                <div className="mx-10">
                  <CreateQuestion outcome={outcome} />
                </div>
              )}
            <QuestionList
              key={outcome.id}
              outcomeId={outcome.id}
              isLoggedIn={isLoggedIn}
              isVotingDisabled={isVotingDisabled}
              setIsVotingDisabled={(val) => setIsVotingDisabled(val)}
              setListLoaded={setListLoaded}
            />
          </div>
        </div>
      );
    }
  } else {
    content = (
      <p className="text-sm text-center">No questions could be found.</p>
    );
  }

  return (
    <div className="QuestionsView mt-5">
      <div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
        <div className="ml-10 items-center">
          <HierarchicalBreadcrumbs outcomes={true} outcome={true} />

          {/* <Breadcrumbs aria-label="breadcrumb">
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href="/outcomes"
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Outcomes
                        </Link>
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
                            color="text.primary"
                        >
                            <Description sx={{ mr: 0.5 }} fontSize="inherit" />
                            Outcome
                        </Typography>
                    </Breadcrumbs> */}
          {/* <h1 className="text-xl ml-2 font-extrabold">Outcome</h1> */}
        </div>
        {outcome && userPreferences.question_layout === 'detail' && (
          <QuestionStatusFilter
            filters={getDisplayableFilters()}
            outcomeId={outcome.id}
            className="mx-5"
          />
        )}
      </div>
      {outcome &&
        (userPreferences.question_layout === 'card' ||
          userPreferences.question_layout === undefined) && (
          <div className="flex justify-center">
            <div className="w-full md:w-2/3">
              <div className="w-full flex justify-end">
                <QuestionStatusFilter
                  filters={getDisplayableFilters()}
                  outcomeId={outcome.id}
                  className="mx-5"
                />
              </div>
            </div>
          </div>
        )}

      <section className="question-view">{content}</section>
    </div>
  );
}
