import { Checkbox, FormLabel } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectSettingById,
  updateSetting
} from '../../store/slices/settingsSlice';
import ConfirmationModal from '../modals/ConfirmationModal';
import InfoTooltip from '../other/InfoTooltip';

export default function SettingCheckbox({ id }) {
  const [showChangeConfirm, setShowChangeConfirm] = useState(false);
  const setting = useSelector((state) => selectSettingById(state, id));
  const dispatch = useDispatch();

  return (
    <div>
      {showChangeConfirm && (
        <ConfirmationModal
          shown={showChangeConfirm}
          close={() => {
            setShowChangeConfirm(false);
          }}
          confirm={() => {
            setShowChangeConfirm(false);
            dispatch(
              updateSetting({
                id: setting.id,
                active: !setting.active,
                auth_token: localStorage.getItem('auth_token')
              })
            );
          }}
          confirmationMessage="Are you sure you want to enable/disable this admin setting? This will modify admin functionality for all non-administrator users."
        />
      )}
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="flex items-center justify-end mr-1">
          <FormLabel
            id="settings-checkbox-label"
            sx={{ fontSize: '0.8rem', color: 'text.primary' }}>
            {setting.name}
          </FormLabel>
          <div className="text-sm ml-1 pb-1 align-text-top">
            <InfoTooltip text={setting.description} />
          </div>
        </div>
        <div className="flex items-center justify-start">
          <Checkbox
            checked={setting.active}
            onChange={() => setShowChangeConfirm(true)}
            name={setting.name}
          />
        </div>
      </div>
    </div>
  );
}
