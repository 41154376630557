import React, { useContext, useEffect, useState } from 'react';

import {
  Card,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import Questionnaire from '../../components/other/questionnaire/Questionnaire';
import { fetchQuestionnaires } from '../../store/slices/questionnaireSlice';

export default function DemographicsQuestionnaireView() {
  const dispatch = useDispatch();
  const questionnaireStatus = useSelector(
    (state) => state.questionnaires.status
  );

  useEffect(() => {
    if (questionnaireStatus === 'idle') {
      dispatch(fetchQuestionnaires());
    }
  }, [questionnaireStatus, dispatch]);

  return (
    <div className="PersonalityQuestionnaire mt-7">
      <div className="flex justify-between ml-10 items-center">
        <HierarchicalBreadcrumbs
          outcomes={true}
          questionnaire={true}
          personality={true}
        />
      </div>
      <div className="flex justify-center">
        <Card className="m-2 my-4 p-2 w-10/12 overflow-y-auto overflow-x-hidden relative shadow-md sm:rounded-lg">
          <Typography
            sx={{ fontWeight: 700, fontSize: '1.2rem' }}
            className="text-center">
            User Demographics Questionnaire
          </Typography>
          <div className="flex flex-col items-center justify-center">
            <div className="w-full sm:11/12 md:5/6 lg:w-4/5 xl:w-2/3">
              <Typography
                sx={{ fontWeight: 700, fontSize: '1rem' }}
                className="">
                Instructions
              </Typography>
              <div className="break-words text-xs font-medium m-1 my-2 max-h-48 overflow-y-auto">
                <List
                  sx={{
                    listStyleType: 'disc',
                    '& .MuiListItem-root': {
                      display: 'list-item'
                    }
                  }}>
                  <ListItem>
                    <Typography sx={{ fontSize: '0.9rem' }}>
                      Answer each of the below questions and then hit submit.
                    </Typography>
                  </ListItem>
                </List>
              </div>
            </div>
            <div className="w-full sm:11/12 md:5/6 lg:w-4/5 xl:w-2/3">
              <Questionnaire questionnaireId="1" />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}
