import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import React from 'react';

export default function BlankForecastCard({
  weekNumber,
  forecastingStartDate
}) {
  const getStartDate = () => {
    return moment(forecastingStartDate).add(weekNumber - 1, 'weeks');
  };

  return (
    <TableRow>
      <TableCell>{weekNumber}</TableCell>
      <TableCell>
        {moment(getStartDate()).format('dddd MMMM Do YYYY')}
      </TableCell>
      <TableCell>Missing</TableCell>
      <TableCell>Missing</TableCell>
    </TableRow>
  );
}
