import { Start } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  SubTitle,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import 'chartjs-adapter-moment';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchForecastsByOutcome,
  selectResolutionImpactForecastsOfOutcome,
  selectResolutionImpactQuestionsOfOutcome
} from '../../../store/slices/outcomeSlice';
import { graphColors } from '../GraphColors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
  TimeScale,
  Filler
);

export default function ForecastDashboard({
  outcomeId,
  title,
  forecastingStartDate,
  falseData,
  format
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [forecastsStatus, setForecastsStatus] = useState('idle');
  const [stacked, setStacked] = useState(true);
  const [filterOpen, setFilterOpen] = useState(false);
  const [aggregated, setAggregated] = useState(true);
  const [relative, setRelative] = useState(true);
  const [resolutionImpact, setResolutionImpact] = useState('positive');
  const [summaryCaption, setSummaryCaption] = useState('');
  const [optionsOpen, setOptionsOpen] = useState(true);

  const questions = useSelector((state) =>
    selectResolutionImpactQuestionsOfOutcome(state, outcomeId)
  );
  const [isQuestionChecked, setIsQuestionChecked] = useState([]);

  const forecastData = useSelector((state) => {
    let temp = selectResolutionImpactForecastsOfOutcome(state, outcomeId);
    return temp;
  });

  const changeStacked = () => {
    setStacked(!stacked);
  };

  const changeFilterOpen = () => {
    setFilterOpen(!filterOpen);
  };

  const changeOptionsOpen = () => {
    setOptionsOpen(!optionsOpen);
  };

  const changeAggregated = () => {
    setStacked(true);
    setAggregated(!aggregated);
  };

  const changeRelative = () => {
    setRelative(!relative);
  };

  const changeResolutionImpact = () => {
    resolutionImpact === 'positive'
      ? setResolutionImpact('negative')
      : setResolutionImpact('positive');
  };

  const isCheckboxChecked = (index, checked) => {
    setIsQuestionChecked((isQuestionChecked) => {
      return isQuestionChecked.map((c, i) => {
        if (i === index) return checked;
        return c;
      });
    });
  };

  useEffect(() => {
    if (forecastsStatus === 'idle') {
      dispatch(fetchForecastsByOutcome(outcomeId)).then(
        setForecastsStatus('success')
      );
    }
  }, [forecastsStatus, dispatch, outcomeId]);

  useEffect(() => {
    if (questions) {
      setIsQuestionChecked(questions.map((q) => true));
    }
  }, [questions]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: aggregated ? false : true,
        labels: {
          color: theme.palette.text.primary
        }
      },
      title: {
        display: title !== undefined ? true : false,
        text: title !== undefined ? title : '',
        color: theme.palette.text.secondary,
        font: {
          size: 20
        }
      },
      subtitle: {
        display: false,
        text: summaryCaption,
        color: theme.palette.text.primary,
        font: {
          size: 14
        },
        padding: {
          bottom: 5
        }
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = relative
              ? `True: ${context.parsed.y.toFixed(2)}%`
              : `True: ${context.parsed.y.toFixed(0)}%`;
            return label;
          },
          title: function (context) {
            let title = moment(context[0].parsed.x).format(
              'dddd, MMMM Do YYYY, h:mm:ss a'
            );
            return title;
          }
        },
        filter: function (tooltipItem) {
          return tooltipItem.datasetIndex !== 15000;
        }
      }
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false
    },
    scales: {
      y: {
        stacked: stacked,
        min: 0,
        max: relative ? 100 : stacked ? null : 100,
        title: {
          display: format === 'landingpage' ? false : true,
          text: 'Probability',
          color: theme.palette.text.secondary,
          font: {
            size: 20
          }
        },
        ticks: {
          callback: function (value, index, ticks) {
            return relative ? value + '%' : !stacked ? value + '%' : value;
          },
          color: theme.palette.text.primary
        },
        grid: {
          display: true,
          color: theme.palette.background.card
        }
      },
      x: {
        type: 'time',
        time: {
          unit: 'day'
        },
        title: {
          display: false
        },
        grid: {
          display: true,
          color: theme.palette.background.card
        },
        ticks: {
          color: theme.palette.text.primary,
          autoSkip: true,
          source: 'auto',
          maxTicksLimit: 10
        }
      }
    }
  };

  const mapForecast = (forecast, question) => {
    let probability = forecast.probability * 100;
    if (question.outcome_owner_resolution_impact) {
      probability =
        resolutionImpact === 'positive' ? probability : 100 - probability;
    } else if (question.group_resolution_impact) {
      probability =
        resolutionImpact === 'positive' ? probability : 100 - probability;
    } else {
      probability =
        resolutionImpact === 'positive' ? 100 - probability : probability;
    }
    return relative
      ? (probability / (questions.length * 100)) * 100
      : probability;
  };

  const createDatasets = useMemo(() => {
    return questions.map((question, index) => {
      return {
        label:
          question.question_text.length > 30
            ? question.question_text.substring(0, 30) + '...'
            : question.question_text,
        data: forecastData
          .filter((f) => f.question_id === question.id)
          .map((f) => mapForecast(f, question)),
        pointStyle: false,
        borderColor: graphColors[index].darker,
        backgroundColor: graphColors[index].mid,
        borderWidth: aggregated ? (index === questions.length - 1 ? 3 : 0) : 3,
        fill: {
          target: aggregated
            ? index === questions.length - 1
              ? 'origin'
              : false
            : stacked
            ? index === 0
              ? 'origin'
              : '-1'
            : false,
          above: graphColors[index].lighter
        },
        hidden: !isQuestionChecked[index]
      };
    });
  }, [
    forecastData,
    isQuestionChecked,
    questions,
    resolutionImpact,
    stacked,
    aggregated
  ]);

  const getLabels = useMemo(() => {
    let labels = [];
    for (let question of questions) {
      labels = labels.concat(
        forecastData
          .filter((f) => f.question_id === question.id)
          .map((f) =>
            moment(forecastingStartDate).add(f.forecast_week - 1, 'weeks')
          )
      );
    }
    return labels;
  }, [forecastData, questions, forecastingStartDate]);

  const data = useMemo(
    () =>
      forecastData.length > 0
        ? {
            labels: getLabels,
            datasets: createDatasets
          }
        : falseData
        ? falseData
        : null, // if no data is loaded, use false data. if no false data is provided, return null
    [forecastData, createDatasets, getLabels]
  );

  const calculateStackedProbability = useMemo(() => {
    if (data) {
      let activeDatasets = data.datasets.filter((d) => !d.hidden);
      let maxProbability = activeDatasets.length * 100;
      let latestProbabilities = activeDatasets.map(
        (d) => d.data[d.data.length - 1]
      );
      return relative
        ? latestProbabilities.reduce((sum, p) => sum + p, 0)
        : (latestProbabilities.reduce((sum, p) => sum + p, 0) /
            maxProbability) *
            100;
    } else {
      return null;
    }
  }, [data, relative]);

  useEffect(() => {
    if (data) {
      let stackedProbability = calculateStackedProbability;
      setSummaryCaption(
        format === 'landingpage'
          ? ''
          : resolutionImpact === 'positive'
          ? `Question probabilities are currently ${
              stackedProbability >= 50 ? 'in favour of' : 'against'
            } your Outcome with ${stackedProbability.toFixed(
              2
            )}% cumulative positive impact.`
          : `Question probabilities are currently ${
              stackedProbability >= 50 ? 'against' : 'in favour of'
            } your Outcome with ${stackedProbability.toFixed(
              2
            )}% cumulative negative impact.`
      );
    }
  }, [data, calculateStackedProbability, resolutionImpact]);

  return data ? (
    <div className="flex flex-wrap w-full justify-start">
      {/* <div
        className={
          'w-[18rem] sm:w-[24rem] md:w-[32rem] lg:w-[34rem] xl:w-[38rem] 2xl:w-[42rem] h-[14rem] sm:h-[18rem] md:h-[21rem] lg:h-[22rem] xl:h-[22rem]'
        }> */}
      <>
        <div className="w-full sm:w-11/12 md:w-11/12 lg:w-9/12 xl:w-8/12 2xl:w-7/12 m-0 text-center">
          <Typography sx={{ ml: 2.4, fontSize: '0.875rem', my: 1.2 }}>
            {summaryCaption}
          </Typography>
        </div>
        <div
          style={format === 'landingpage' ? { width: '100%' } : {}}
          className="w-full sm:w-11/12 md:w-11/12 lg:w-9/12 xl:w-8/12 2xl:w-7/12 h-[22rem]">
          <Line
            style={format === 'landingpage' ? { width: '100%' } : {}}
            options={options}
            data={data}
          />
        </div>
      </>
      {/* do not display collapsible options toggle on landing page */}
      {format !== 'landingpage' && (
        <>
          <Collapse in={!optionsOpen} orientation="horizontal">
            <Button
              sx={{ p: 0, mt: 4, minWidth: 0 }}
              onClick={changeOptionsOpen}>
              <Start sx={{ p: 0, minWidth: 0 }} />
            </Button>
          </Collapse>
          <Collapse in={optionsOpen} orientation="horizontal">
            <Paper
              sx={{ p: 2 }}
              variant="outlined"
              className="flex flex-col gap-2 mx-2 my-2 h-fit">
              <div className="flex justify-between">
                <Typography sx={{ fontWeight: 'bold' }}>Options</Typography>
                <Button sx={{ p: 0, minWidth: 0 }} onClick={changeOptionsOpen}>
                  <Start
                    sx={{ p: 0, minWidth: 0, transform: 'rotate(180deg)' }}
                  />
                </Button>
              </div>
              <FormControlLabel
                control={
                  <ToggleButtonGroup
                    color="primary"
                    value={resolutionImpact}
                    onChange={changeResolutionImpact}>
                    <ToggleButton size="small" value="positive">
                      Positive
                    </ToggleButton>
                    <ToggleButton size="small" value="negative">
                      Negative
                    </ToggleButton>
                  </ToggleButtonGroup>
                }
                label={
                  <Typography sx={{ fontSize: '0.875rem' }}>
                    {'Resolution Impact'}
                  </Typography>
                }
                labelPlacement="top"
                sx={{
                  p: 0,
                  m: 0
                }}
              />
              <Divider sx={{ my: 0.6 }} />
              <Button
                size="small"
                variant="outlined"
                onClick={changeAggregated}>
                {aggregated ? 'Divide' : 'Aggregate'}
              </Button>
              <Divider sx={{ my: 0.6 }} />
              <Button size="small" variant="outlined" onClick={changeRelative}>
                {relative ? 'Absolute' : 'Relative'}
              </Button>
              <Divider sx={{ my: 0.6 }} />
              {!aggregated && (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={changeStacked}>
                    {stacked ? 'Unstack' : 'Stack'}
                  </Button>
                  <Divider sx={{ my: 0.6 }} />
                </>
              )}
              <Button
                size="small"
                variant="outlined"
                onClick={changeFilterOpen}>
                {filterOpen ? 'Hide' : 'Filters'}
              </Button>
            </Paper>
          </Collapse>
          <Collapse in={filterOpen} orientation="horizontal">
            <Paper
              sx={{ p: 2, width: '25rem' }}
              variant="outlined"
              className="mx-2 my-2 max-h-72 overflow-y-auto">
              <Typography sx={{ fontWeight: 'bold' }}>Questions</Typography>
              {questions.map((question, index) => {
                return (
                  <div key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            isQuestionChecked[index] !== undefined
                              ? isQuestionChecked[index]
                              : true
                          }
                          onChange={(e) =>
                            isCheckboxChecked(index, e.target.checked)
                          }
                        />
                      }
                      label={
                        <Typography sx={{ fontSize: '0.875rem' }}>
                          {question.question_text}
                        </Typography>
                      }
                    />
                    {index !== questions.length - 1 && <Divider />}
                  </div>
                );
              })}
            </Paper>
          </Collapse>
        </>
      )}
    </div>
  ) : null;
}
