import { Button, FormLabel, Input, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSettingById,
  updateSetting
} from '../../store/slices/settingsSlice';
import ConfirmationModal from '../modals/ConfirmationModal';
import InfoTooltip from './InfoTooltip';

export default function SettingValue({ id }) {
  const [showChangeConfirm, setShowChangeConfirm] = useState(false);
  const setting = useSelector((state) => selectSettingById(state, id));
  const dispatch = useDispatch();
  const [value, setValue] = useState(setting.value);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleValueChange = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < setting.minimum) {
      setValue(setting.minimum);
    } else if (value > setting.maximum) {
      setValue(setting.maximum);
    }
  };

  return (
    <div>
      {showChangeConfirm && (
        <ConfirmationModal
          shown={showChangeConfirm}
          close={() => {
            setShowChangeConfirm(false);
          }}
          confirm={() => {
            setShowChangeConfirm(false);
            dispatch(
              updateSetting({
                id: setting.id,
                value: value,
                auth_token: localStorage.getItem('auth_token')
              })
            );
          }}
          confirmationMessage="Are you sure you want to change this admin setting? This will modify admin functionality for all non-administrator users."
        />
      )}
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="flex items-center justify-end mr-1">
          <FormLabel
            id="settings-checkbox-label"
            sx={{ fontSize: '0.8rem', color: 'text.primary' }}>
            {setting.name}
          </FormLabel>
          <div className="ml-1 pb-1 text-sm align-text-top">
            <InfoTooltip text={setting.description} />
          </div>
        </div>
        <div className="flex items-center justify-start">
          <div className="w-1/2 mx-2 items-center pt-1">
            <Slider
              value={typeof value === 'number' ? value : 0}
              step={setting.step}
              min={setting.minimum}
              max={setting.maximum}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
            />
          </div>
          <div className="mx-2">
            <Input
              value={value}
              size="small"
              onChange={handleValueChange}
              onBlur={handleBlur}
              inputProps={{
                step: setting.step,
                min: setting.minimum,
                max: setting.maximum,
                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </div>
          <div className="w-1/2 ml-2">
            <Button
              variant="contained"
              onClick={() => setShowChangeConfirm(true)}>
              Change
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
