import React, { useContext, useEffect, useState } from 'react';

import OutcomeCard from '../../components/cards/OutcomeCard';
import CreateOutcomeModal from '../../components/modals/CreateOutcomeModal';
import OutcomeFilterSection from '../../components/other/OutcomeFilterSection';
import OutcomeStatusFilter from '../../components/other/OutcomeStatusFilter';

import { AuthContext } from '../../App';

import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import CreateOutcomeSmall from '../../components/other/CreateOutcomeSmall';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import {
  fetchOutcomes,
  selectOrderedFilteredOutcomes,
  selectOutcomesFilters,
  selectOutcomesSort
} from '../../store/slices/outcomeSlice';
import { selectUserPreferences } from '../../store/slices/userSlice';

export default function OutcomesView() {
  const dispatch = useDispatch();

  const { isLoggedIn, userData } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const orderedOutcomes = useSelector((state) =>
    selectOrderedFilteredOutcomes(state, userData.id)
  );
  const outcomeStatus = useSelector((state) => state.outcomes.outcomes.status);
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  useEffect(() => {
    if (outcomeStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchOutcomes({ auth_token: token }));
    }
  }, [outcomeStatus, dispatch]);

  let content;

  if (outcomeStatus === 'loading') {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (outcomeStatus === 'succeeded') {
    content = orderedOutcomes.map((outcome) => (
      <OutcomeCard key={outcome.id} outcomeId={outcome.id} />
    ));
  } else if (outcomeStatus === 'failed') {
    content = (
      <Typography sx={{ mx: 4 }}>No outcomes could be found.</Typography>
    );
  }

  const createOutcome = () => {
    setShowModal(true);
  };

  return (
    <div className="OutcomeView z-0">
      {showModal && (
        <CreateOutcomeModal
          shown={showModal}
          close={() => {
            setShowModal(false);
          }}
        />
      )}

      <div className="m-5">
        <div className="flex flex-col md:flex-row justify-center md:justify-start items-center">
          <div className="flex items-center ml-5">
            <HierarchicalBreadcrumbs outcomes={true} />
          </div>
          {userPreferences.outcome_layout === 'detail' && (
            <div className="w-full flex justify-end">
              <OutcomeStatusFilter className="mx-5 ml-auto" />
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <div
            className={`xs:w-full ${
              userPreferences.outcome_layout === 'card' ||
              userPreferences.outcome_layout === undefined
                ? 'md:w-1/2'
                : 'md:w-full'
            }`}>
            {(userPreferences.outcome_layout === 'card' ||
              userPreferences.outcome_layout === undefined) && (
              <div className="w-full flex justify-end">
                <OutcomeStatusFilter className="mx-5 ml-auto" />
              </div>
            )}
            {isLoggedIn &&
              (userData.role === 'Admin' ||
                userData.role === 'Moderator' ||
                userData.role === 'OutcomeOwner') && (
                <div>
                  <CreateOutcomeSmall />
                </div>
              )}
            <OutcomeFilterSection />
            <section className="outcome-list">{content}</section>
          </div>
        </div>
      </div>
    </div>
  );
}
