import React, { useState, useContext } from "react";

import HierarchicalBreadcrumbs from "../../components/other/HierarchicalBreadcrumbs";
import CreateOutcome from "../../components/other/CreateOutcome";

export default function SubmitView() {
    return (
        <div className="SubmitOutcomeView mt-7">
            <div className="flex justify-between ml-10 items-center">
                <HierarchicalBreadcrumbs
                    outcomes={true}
                    submit={true}
                />
            </div>
            <div className="flex justify-center w-full">
                <div className="w-1/2">
                    <CreateOutcome />
                </div>
            </div>
        </div>
    )
}
