import {
  CalendarMonthOutlined,
  EventOutlined,
  MailOutline,
  QuestionMarkOutlined
} from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Card,
  Tab,
  Typography,
  useTheme
} from '@mui/material';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { BiTrash } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AuthContext, checkTokenStatus } from '../../App';
import ModifyUserGroupModal from '../../components/modals/ModifyUserGroupModal';
import {
  fetchOutcomes,
  selectOutcomeById
} from '../../store/slices/outcomeSlice';
import {
  removeOutcomesFromUserGroup,
  removeUsersFromUserGroup,
  selectUserGroupById
} from '../../store/slices/userGroupSlice';
import { selectUserById } from '../../store/slices/userSlice';
import RoleBadgeIcon from '../icons/RoleBadgeIcon';
import ConfirmationModal from '../modals/ConfirmationModal';

export default function UserGroupCard({ groupId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { userData, isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [confirmType, setConfirmType] = useState('');
  const [confirmId, setConfirmId] = useState();
  const [removeUserRequestStatus, setRemoveUserRequestStatus] =
    useState('idle');
  const [removeOutcomeRequestStatus, setRemoveOutcomeRequestStatus] =
    useState('idle');
  const group = useSelector((_state) => selectUserGroupById(_state, groupId));
  const [showModifyModal, setShowModifyModal] = useState(false);
  const outcomeStatus = useSelector((state) => state.outcomes.outcomes.status);

  const [tab, setTab] = useState('1');
  const changeTab = (event, newValue) => setTab(newValue);

  useEffect(() => {
    if (outcomeStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchOutcomes({ auth_token: token }));
    }
  }, [outcomeStatus, dispatch]);

  const groupUsers = useSelector((state) => {
    return group.users
      ? group.users.map((user) => {
          return selectUserById(state, user.id);
        })
      : [];
  });

  const groupOutcomes = useSelector((state) => {
    return group.outcomes
      ? group.outcomes.map((outcome) => {
          return selectOutcomeById(state, outcome.id);
        })
      : [];
  });

  const getOutcomeStatusColour = (status) => {
    switch (status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };

  const outcomeTitleClick = (id) => {
    navigate(`/outcomes/${id}`);
  };

  const userTitleClick = () => {
    navigate(`/users`);
  };

  const removeUserFromGroup = async (user_id) => {
    if (checkTokenStatus() && removeUserRequestStatus === 'idle') {
      let isMounted = true;
      try {
        setRemoveUserRequestStatus('pending');
        setIsLoggedIn(true);
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: groupId,
          auth_token: token,
          user_ids: [user_id]
        };
        dispatch(removeUsersFromUserGroup(payload));
      } catch (err) {
        setErrorMessage(`Failed to remove user: ${err.message}`);
      } finally {
        if (isMounted) {
          setRemoveUserRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      localStorage.removeItem('auth_token');
      setIsLoggedIn(false);
      navigate('/login');
    } else {
      setErrorMessage('Group could not be created.');
    }
  };

  const removeOutcomeFromGroup = async (outcome_id) => {
    if (checkTokenStatus() === true && removeOutcomeRequestStatus === 'idle') {
      let isMounted = true;
      try {
        setRemoveOutcomeRequestStatus('pending');
        setIsLoggedIn(true);
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: groupId,
          auth_token: token,
          outcome_ids: [outcome_id]
        };
        dispatch(removeOutcomesFromUserGroup(payload));
      } catch (err) {
        setErrorMessage(`Failed to remove outcome: ${err.message}`);
      } finally {
        if (isMounted) {
          setRemoveOutcomeRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      localStorage.removeItem('auth_token');
      setIsLoggedIn(false);
      navigate('/login');
    } else {
      setErrorMessage('Group could not be created.');
    }
  };

  const outcomeStatusText = (outcome) => {
    if (outcome.status === 'Generation') {
      return 'Started';
    } else if (outcome.status === 'Evaluation') {
      return 'Evaluation Started';
    } else if (outcome.status === 'Forecasting') {
      return 'Forecasting Started';
    } else {
      return 'Closed';
    }
  };

  const timeBetweenStatusChange = (outcome) => {
    if (outcome.status === 'Closed') {
      return moment(outcome.end_at, 'YYYY-MM-DD').fromNow();
    } else if (outcome.status === 'Generation') {
      return moment(outcome.created_at, 'YYYY-MM-DDThh:mm:ss').fromNow();
    } else if (outcome.status === 'Evaluation') {
      return moment(outcome.generation_end_date, 'YYYY-MM-DD').fromNow();
    } else {
      return moment(outcome.question_deadline, 'YYYY-MM-DD').fromNow();
    }
  };

  const getEndingStatusText = (outcome) => {
    if (outcome.status === 'Generation') {
      return 'Generation Deadline';
    } else if (outcome.status === 'Evaluation') {
      return 'Evaluation Deadline';
    } else if (outcome.status === 'Forecasting') {
      return 'Ending';
    } else {
      return '';
    }
  };

  const timeTillFinish = (outcome) => {
    if (outcome.status === 'Generation') {
      return moment(outcome.generation_end_date, 'YYYY-MM-DD').fromNow();
    } else if (outcome.status === 'Evaluation') {
      return moment(outcome.question_deadline, 'YYYY-MM-DD').fromNow();
    } else if (outcome.status === 'Forecasting') {
      return moment(outcome.end_at, 'YYYY-MM-DD').fromNow();
    } else {
      return '';
    }
  };

  const canViewGroup = () => {
    if (userData.role === 'Admin') {
      return true;
    } else if (userInGroup(userData.id)) {
      return true;
    } else {
      return false;
    }
  };

  const userInGroup = (userId) => {
    return group.users.filter((u) => u.id === userId).length > 0;
  };

  return (
    <div className="ProfileCard w-full p-2">
      {showModifyModal && isLoggedIn && userData.role === 'Admin' && (
        <ModifyUserGroupModal
          shown={showModifyModal}
          close={() => {
            setShowModifyModal(false);
          }}
          groupId={groupId}
        />
      )}
      {showDeleteConfirm && (
        <ConfirmationModal
          shown={showDeleteConfirm}
          close={() => {
            setShowDeleteConfirm(false);
          }}
          confirm={() => {
            setShowDeleteConfirm(false);
            if (confirmType === 'outcome') {
              removeOutcomeFromGroup(confirmId);
            } else if (confirmType === 'user') {
              removeUserFromGroup(confirmId);
            }
            setConfirmType('');
          }}
          confirmationMessage="Do you really want to remove this from the group? This process cannot be undone"
        />
      )}
      {group && canViewGroup() ? (
        <div className="w-full">
          <div className="flex justify-center m-3">
            <div className="flex-initial w-full lg:w-4/5">
              <Card sx={{ p: 3.6, m: 1.2, borderRadius: '0.5rem' }}>
                <div className="flex w-full">
                  <Typography
                    sx={{ fontWeight: 600, fontSize: '1.4rem', mb: 1 }}>
                    {group.name}
                  </Typography>
                  {userData.role === 'Admin' && (
                    <Button
                      variant="contained"
                      sx={{ ml: 'auto' }}
                      onClick={() => setShowModifyModal(true)}>
                      Modify User Group
                    </Button>
                  )}
                </div>
                <div className="flex text-sm items-center">
                  <CalendarMonthOutlined fontSize="inherit" sx={{ mr: 0.6 }} />
                  <Typography
                    color="text.secondary"
                    sx={{
                      fontSize: '0.95rem'
                    }}>
                    <span>Created </span>{' '}
                    {moment(group.created_at).format('MMMM Do YYYY')}
                  </Typography>
                </div>
                <TabContext value={tab} sx={{ width: '100%' }}>
                  <div className="flex justify-between">
                    <Box sx={{}}>
                      <TabList onChange={changeTab}>
                        <Tab label="Users" value="1" />
                        <Tab label="Outcomes" value="2" />
                      </TabList>
                    </Box>
                  </div>
                  <Card
                    sx={{
                      borderRadius: '0.5rem',
                      overflowY: 'auto',
                      overflowX: 'hidden',
                      height: '24rem',
                      backgroundColor: 'background.default'
                    }}>
                    {errorMessage && (
                      <Typography color="error">{errorMessage}</Typography>
                    )}
                    <TabPanel value={'1'} sx={{ p: 1 }}>
                      {groupUsers.length > 0 ? (
                        groupUsers.map(
                          (user) =>
                            user && (
                              <div
                                key={user.id}
                                className="border-b border-gray-300">
                                <div className="flex items-center mt-1">
                                  <Button
                                    onClick={() => userTitleClick()}
                                    sx={{
                                      fontWeight: 'bold',
                                      fontSize: '1.1rem',
                                      ml: 1.2,
                                      textAlign: 'left',
                                      textTransform: 'none'
                                    }}>
                                    {user.username}
                                  </Button>
                                  <div className="pb-1">
                                    <RoleBadgeIcon role={user.role} />
                                  </div>
                                </div>
                                <div className="text-xs font-light px-4 pb-2 ml-2 w-full flex items-center">
                                  <Typography
                                    sx={{
                                      mr: 1.2,
                                      fontSize: '0.85rem',
                                      p: 0,
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                    color="text.secondary">
                                    <CalendarMonthOutlined
                                      fontSize="inherit"
                                      sx={{ mr: 0.3, mb: 0.3 }}
                                    />
                                    Registered{' '}
                                    {moment(user.registered_on).format(
                                      'MMMM Do YYYY'
                                    )}
                                  </Typography>
                                  {user.email && (
                                    <Typography
                                      sx={{
                                        mr: 1.2,
                                        fontSize: '0.875rem',
                                        p: 0,
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                      color="text.secondary">
                                      <MailOutline
                                        fontSize="inherit"
                                        sx={{ mr: 0.3, mb: 0.3 }}
                                      />
                                      {user.email}
                                    </Typography>
                                  )}
                                  {userData.role === 'Admin' && (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        ml: 'auto',
                                        textTransform: 'none',
                                        p: 0.2,
                                        bgcolor: theme.palette.statuses.mid5,
                                        ':hover': {
                                          bgcolor: theme.palette.statuses.dark5
                                        }
                                      }}
                                      className="w-32"
                                      onClick={() => {
                                        setConfirmId(user.id);
                                        setConfirmType('user');
                                        setShowDeleteConfirm(true);
                                      }}>
                                      <div className="flex justify-center items-center">
                                        {<BiTrash className="mr-1" />}
                                        <span>Remove</span>
                                      </div>
                                    </Button>
                                  )}
                                </div>
                              </div>
                            )
                        )
                      ) : (
                        <div className="px-4 pt-2 text-lg">
                          <Typography>No users could be found.</Typography>
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel value={'2'} sx={{ p: 1 }}>
                      {groupOutcomes && groupOutcomes.length > 0 ? (
                        groupOutcomes.map(
                          (outcome) =>
                            outcome && (
                              <div
                                key={outcome.id}
                                className="border-b border-gray-300">
                                <div className="flex items-center mt-1">
                                  <Button
                                    onClick={() =>
                                      outcomeTitleClick(outcome.id)
                                    }
                                    sx={{
                                      fontWeight: 'bold',
                                      fontSize: '1.1rem',
                                      ml: 1.2,
                                      textAlign: 'left',
                                      textTransform: 'none'
                                    }}>
                                    {outcome.title}
                                  </Button>
                                  <Typography
                                    sx={{
                                      borderColor: getOutcomeStatusColour(
                                        outcome.status
                                      ),
                                      backgroundColor: getOutcomeStatusColour(
                                        outcome.status
                                      ),
                                      py: 0,
                                      px: 0.3,
                                      fontSize: '0.95rem',
                                      mx: 0.6
                                    }}
                                    className={`border-1 rounded text-white font-normal text-xs px-1 ml-1`}>
                                    {outcome.status}
                                  </Typography>
                                </div>
                                <div className="text-xs font-light px-4 pb-2 ml-2 flex items-center">
                                  <Typography
                                    sx={{
                                      mr: 1.2,
                                      fontSize: '0.85rem',
                                      p: 0,
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                    color="text.secondary">
                                    <CalendarMonthOutlined
                                      fontSize="inherit"
                                      sx={{ mr: 0.3, mb: 0.3 }}
                                    />
                                    {outcomeStatusText(outcome)}{' '}
                                    {timeBetweenStatusChange(outcome)}
                                  </Typography>
                                  {(outcome.status === 'Generation' ||
                                    outcome.status === 'Evaluation' ||
                                    outcome.status === 'Forecasting') && (
                                    <Typography
                                      sx={{
                                        mr: 1.2,
                                        fontSize: '0.875rem',
                                        p: 0,
                                        display: 'flex',
                                        alignItems: 'center'
                                      }}
                                      color="text.secondary">
                                      <EventOutlined
                                        fontSize="inherit"
                                        sx={{ mr: 0.3, mb: 0.3 }}
                                      />
                                      {getEndingStatusText(outcome)}{' '}
                                      {timeTillFinish(outcome)}
                                    </Typography>
                                  )}
                                  <Typography
                                    sx={{
                                      mr: 1.2,
                                      fontSize: '0.875rem',
                                      p: 0,
                                      display: 'flex',
                                      alignItems: 'center'
                                    }}
                                    color="text.secondary">
                                    <QuestionMarkOutlined
                                      fontSize="inherit"
                                      sx={{ mr: 0.3, mb: 0.3 }}
                                    />
                                    {outcome.questions.length} Questions posted
                                  </Typography>
                                  {userData.role === 'Admin' && (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        ml: 'auto',
                                        textTransform: 'none',
                                        p: 0.2,
                                        bgcolor: theme.palette.statuses.mid5,
                                        ':hover': {
                                          bgcolor: theme.palette.statuses.dark5
                                        }
                                      }}
                                      className="w-32"
                                      onClick={() => {
                                        setConfirmId(outcome.id);
                                        setConfirmType('outcome');
                                        setShowDeleteConfirm(true);
                                      }}>
                                      <div className="flex justify-center items-center">
                                        {<BiTrash className="mr-1" />}
                                        <span>Remove</span>
                                      </div>
                                    </Button>
                                  )}
                                </div>
                              </div>
                            )
                        )
                      ) : (
                        <div className="px-4 pt-2 text-lg">
                          <Typography>No outcomes could be found.</Typography>
                        </div>
                      )}
                    </TabPanel>
                  </Card>
                </TabContext>
              </Card>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Typography className="text-sm text-center">
            A group with this name could not be found.
          </Typography>
        </div>
      )}
    </div>
  );
}
