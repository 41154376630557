import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import React from 'react';

export default function ForecastCard({ forecast, forecastingStartDate }) {
  const calculateForecastDate = () => {
    let forecastDayInt = getIntDay(forecast.forecast_day);
    let forecastWeek = moment(forecastingStartDate).add(
      forecast.forecast_week - 1,
      'weeks'
    );
    let forecastWeekDay = moment(forecastWeek).isoWeekday();
    if (forecastWeekDay <= forecastDayInt) {
      return moment(forecastWeek).isoWeekday(forecastDayInt);
    } else {
      return moment(forecastWeek).add(1, 'weeks').isoWeekday(forecastDayInt);
    }
  };

  const getStartDate = () => {
    return moment(forecastingStartDate).add(
      forecast.forecast_week - 1,
      'weeks'
    );
  };

  const getIntDay = (dayString) => {
    switch (dayString) {
      case 'Monday':
        return 1;
      case 'Tuesday':
        return 2;
      case 'Wednesday':
        return 3;
      case 'Thursday':
        return 4;
      case 'Friday':
        return 5;
      case 'Saturday':
        return 6;
      case 'Sunday':
        return 7;
      default:
        return 1;
    }
  };

  return (
    <TableRow>
      <TableCell>{forecast.forecast_week}</TableCell>
      <TableCell>
        {moment(getStartDate()).format('dddd MMMM Do YYYY')}
      </TableCell>
      <TableCell>
        {moment(calculateForecastDate()).format('dddd MMMM Do YYYY')}
      </TableCell>
      <TableCell>{(forecast.probability * 100).toFixed(2)} %</TableCell>
    </TableRow>
    // <div className="p-1 bg-white border-gray-300 rounded-sm shadow-sm border w-full flex justify-center items-center text-sm">
    //     <div>
    //         <p className="font-semibold">Week {forecast.forecast_week}</p>
    //         <span className="font-normal text-xs">Starting {moment(getStartDate()).format('dddd MMMM Do YYYY')}</span>
    //     </div>
    //     <div className="flex basis-4/12 items-center justify-end">
    //         <p className="mr-2 font-semibold">Forecast Date</p>
    //         <span className="font-normal">{moment(calculateForecastDate()).format('dddd MMMM Do YYYY')}</span>
    //     </div>
    //     <div className="flex basis-4/12 items-center justify-center">
    //         <p className="mr-2 font-semibold">Probability</p>
    //         <span className="font-normal">{forecast.probability * 100} %</span>
    //     </div>
    //     {/* <div className="basis-1/12"></div> */}
    //     {/* <div className="flex basis-4/12 items-center justify-end">
    //         <div className="flex w-4/5 justify-start">
    //         <p className="mr-2 font-semibold">Forecast Date</p>
    //         <span className="font-normal">{moment(forecast.forecast_date).format("dddd, MMMM Do YYYY, h:mm:ss a")}</span>
    //         </div>
    //     </div> */}
    //     {/* <div className="basis-1/12"></div> */}
    // </div>
  );
}
