import { Input, Slider } from '@mui/material';
import { useEffect } from 'react';
import { MdSpeed } from 'react-icons/md';

export default function RangedNumericMeasure({ measure, value, setValue }) {
  useEffect(() => {
    setValue(measure.range.lower_bound);
  }, []);

  const changevalue = (event) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBlur = () => {
    if (value < measure.range.lower_bound) {
      setValue(measure.range.lower_bound);
    } else if (value > measure.range.lower_bound) {
      setValue(measure.range.lower_bound);
    }
  };

  return (
    <div className="flex items-center justify-center w-full">
      <div className="w-full mx-2 pl-8 items-center">
        <Slider
          value={typeof value === 'number' ? value : measure.range.lower_bound}
          onChange={handleSliderChange}
          aria-labelledby="input-slider"
        />
      </div>
      <div className="mx-2">
        <Input
          value={value}
          size="small"
          onChange={changevalue}
          onBlur={handleBlur}
          inputProps={{
            step: 1,
            min: 0,
            max: 100,
            type: 'number',
            'aria-labelledby': 'input-slider'
          }}
        />
      </div>
    </div>
  );
}
