import React, { useState, useContext, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { AuthContext, checkTokenStatus } from "../../App";

import { useDispatch } from "react-redux"
import { addNewOutcome } from "../../store/slices/outcomeSlice";

import TextField from "@mui/material/TextField";
import ukLocale from "date-fns/locale/en-GB";
import moment from "moment";
import InfoTooltip from "../other/InfoTooltip";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function CreateOutcomeModal({ shown, close }) {
    const dispatch = useDispatch()
    const { setIsLoggedIn } = useContext(AuthContext);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [questionSubmissionLimit, setQuestionSubmissionLimit] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const [addRequestStatus, setAddRequestStatus] = useState("idle")
    const navigate = useNavigate();

    useEffect(() => {
        moment.relativeTimeThreshold('w', 8);
        moment.relativeTimeThreshold('d', 14);
    }, []);


    const initialiseDeadline = () => {
        var current = new Date();
        current.setDate(current.getDate() + 2);
        return current
    }
    const initialiseEndDate = () => {
        var current = new Date();
        current.setDate(current.getDate() + 3);
        return current
    }
    const [endDate, setEndDate] = useState(initialiseEndDate());
    const [questionDeadline, setQuestionDeadline] = useState(initialiseDeadline());

    const openOutcomeView = (problemId) => {
        navigate(`/outcomes/${problemId}`)
    }

    const closeModal = () => {
        close()
    }

    const changeTitle = (event) => setTitle(event.target.value);
    const changeDescription = (event) => setDescription(event.target.value);
    const changeQuestionSubmissionLimit = (event) => {
        const submissionLimit = (event.target.validity.valid) ? event.target.value : questionSubmissionLimit;
        setQuestionSubmissionLimit(submissionLimit)
    }

    const canCreate =
        [title, description, checkTokenStatus(), endDate, questionDeadline, questionSubmissionLimit].every(Boolean) && addRequestStatus === "idle"

    const submitOutcome = async () => {
        if (canCreate) {
            let isMounted = true;
            try {
                setAddRequestStatus("pending")
                const token = localStorage.getItem("auth_token")
                let payload = {
                    title: title,
                    description: description,
                    end_date: moment(endDate).format("YYYY-MM-DD"),
                    question_deadline: moment(questionDeadline).format("YYYY-MM-DD"),
                    question_submission_limit: questionSubmissionLimit,
                    auth_token: token
                }
                await dispatch(addNewOutcome(payload))
                    .unwrap()
                    .then((response) => {                        
                        if (isMounted) {
                            setTitle("")
                            setDescription("")                            
                        }
                        if (response.status === "success") {
                            let problemId = response.data.id
                            closeModal()
                            openOutcomeView(problemId)                            
                        }
                    })
            } catch (err) {
                setErrorMessage(`Failed to create outcome: ${err.message}`);
            } finally {
                if (isMounted) {
                    setAddRequestStatus("idle");
                    isMounted = false
                }
            }
        } else if (checkTokenStatus() === false) {
            setIsLoggedIn(false);
            return <Navigate to={"/login"} />
        } else {
            if (title && description && endDate && questionSubmissionLimit && questionDeadline) setErrorMessage("Outcome could not be created.")
            if (!title) setErrorMessage("Please set a title before submitting.")
            else if (!description) setErrorMessage("Please set a Description before submitting.")
            else if (!endDate) setErrorMessage("Please set an Outcome end date before submitting")
            else if (!questionDeadline) setErrorMessage("Please set a Question Submission Deadline before submitting")
            else if (!questionSubmissionLimit) setErrorMessage("Please set a Question Submission Limit before submitting.")
        }
    };

    const calculateTimeBetweenDeadlines = () => {
        return moment(questionDeadline).from(endDate, true)
    }

    return shown ? (
        <div
            className="modal-backdrop"
            onClick={() => {
                // close modal when outside of modal is clicked

            }}
        >
            <div
                className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
                onClick={e => {
                    // do not close modal if anything inside modal content is clicked
                    e.stopPropagation();
                }}>
                <div>
                    <h3 className="text-lg font-extrabold text-center">Create a new outcome</h3>
                    {errorMessage && <p className="text-xs text-red-600 py-2">{errorMessage}</p>}
                    <div>
                        <div className="flex">
                            <span className="">Outcome Title</span>
                            <div className="ml-1">
                                <InfoTooltip text="A summary of the outcome." />
                            </div>
                        </div>
                        <input
                            type="text"
                            value={title}
                            id="outcomeTitle"
                            onChange={(event) => changeTitle(event)}
                            className="w-full my-2 p-2 rounded border-solid border-2 border-gray-200 bg-gray-50 text-sm"
                        />
                    </div>

                    <div>
                        <div className="flex">
                            <p className="">Outcome Description</p>
                            <div className="ml-1">
                                <InfoTooltip text="A text description that describes the decision faced by the Outcome Owner, their objectives, and any other key concerns." />
                            </div>
                        </div>
                        <textarea
                            id="outcomeDescription"
                            value={description}
                            onChange={(event) => changeDescription(event)}
                            rows="4"
                            className="resize-none w-full my-2 p-2 rounded border-solid border-2 border-gray-200 bg-gray-50 text-sm"
                        ></textarea>
                    </div>

                    <div className="mr-3">
                        <div className="flex">
                            <p className="">Question Submission Limit</p>
                            <div className="ml-1">
                                <InfoTooltip text="The maximum number of Questions that will be submitted to the forecasting platform relating to this Outcome." />
                            </div>
                        </div>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            onChange={(event) => changeQuestionSubmissionLimit(event)}
                            value={questionSubmissionLimit}
                            className="resize-none w-1/5 my-1 p-2 rounded border-solid border-2 border-gray-200 bg-gray-50 text-sm"
                        >
                        </input>
                    </div>
                    <div className="flex justify-evenly items-center mt-5 mb-1">
                        <div className="mr-2 flex text-center">
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
                                    <DatePicker
                                        clearable={false}
                                        label="Question Submission Deadline"
                                        value={questionDeadline}
                                        onChange={(newValue) => {
                                            setQuestionDeadline(newValue);
                                        }}
                                        maxDate={endDate}
                                        renderInput={(params) => <TextField {...params}/>}                            
                                    />
                                </LocalizationProvider>
                                <div className="ml-1">
                                    <InfoTooltip text="The date on which Question generation activity will stop and Questions will be submitted to the forecasting platform." />
                                </div>
                        </div>
                        <div className="ml-2 flex">
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
                                <DatePicker
                                    clearable={false}
                                    label="Outcome End Date"
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                    minDate={Date.now()}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <div className="ml-1">
                                <InfoTooltip text="The date after which the Outcome is no longer valid, and when all the questions and evaluations will be scored." />
                            </div>
                        </div>
                    </div>
                    <div className="px-2 my-3">
                        <p className="text-xs font-medium">{`There is ${calculateTimeBetweenDeadlines()} between the above Question Submission Deadline and the Outcome End Date. This gives ${calculateTimeBetweenDeadlines()} for Question forecasting.`}</p>
                    </div>
                </div>
                <div className="flex">
                    <div className="w-full mx-2">
                        <button
                            onClick={closeModal}
                            className="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded w-full"
                        >Cancel</button>
                    </div>

                    <div className="w-full mx-2">
                        <button
                            onClick={submitOutcome}
                            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded w-full"
                        >Add</button>
                    </div>
                </div>
            </div>
        </div >
    ) : null;
}
