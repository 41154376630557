import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { AuthContext, checkTokenStatus } from '../../App';
import { addNewOutcome } from '../../store/slices/outcomeSlice';

import TextField from '@mui/material/TextField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import MultiSelectDropdown from "../other/MultiSelectDropdown"

import { Button, Card, Divider, Typography } from '@mui/material';
import ukLocale from 'date-fns/locale/en-GB';
import InfoTooltip from './InfoTooltip';
import { fetchUserGroups, addOutcomesToUserGroup, selectAllUserGroups, selectGroupNames } from '../../store/slices/userGroupSlice';

export default function CreateOutcome() {
  const dispatch = useDispatch();
  const { setIsLoggedIn } = useContext(AuthContext);
  const userGroupStatus = useSelector(
    (state) => state.userGroups.status
  );

  useEffect(() => {
    if (userGroupStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchUserGroups(token));
    }
  }, [userGroupStatus, dispatch]);

  const groups = useSelector((state) => selectAllUserGroups(state));
  const groupNames = useSelector((state) => selectGroupNames(state));
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [questionSubmissionLimit, setQuestionSubmissionLimit] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [addRequestStatus, setAddRequestStatus] = useState('idle');
  const navigate = useNavigate();

  useEffect(() => {
    moment.relativeTimeThreshold('w', 8);
    moment.relativeTimeThreshold('d', 14);
  }, []);

  const initialiseDeadline = () => {
    var current = new Date();
    current.setDate(current.getDate() + 2);
    return current;
  };
  const initialiseEndDate = () => {
    var current = new Date();
    current.setDate(current.getDate() + 3);
    return current;
  };
  const [endDate, setEndDate] = useState(initialiseEndDate());
  const [questionDeadline, setQuestionDeadline] = useState(
    initialiseDeadline()
  );

  const openOutcomeView = (problemId) => {
    navigate(`/outcomes/${problemId}`);
  };

  const changeTitle = (event) => setTitle(event.target.value);
  const changeDescription = (event) => setDescription(event.target.value);
  const changeQuestionSubmissionLimit = (event) => {
    const submissionLimit = event.target.validity.valid
      ? event.target.value
      : questionSubmissionLimit;
    setQuestionSubmissionLimit(submissionLimit);
  };

  const canCreate =
    [
      title,
      description,
      checkTokenStatus(),
      endDate,
      questionDeadline,
      questionSubmissionLimit
    ].every(Boolean) && addRequestStatus === 'idle';

  const submitOutcome = async () => {
    if (canCreate) {
      let isMounted = true;
      try {
        setAddRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let problemId;
        let payload = {
          title: title,
          description: description,
          end_date: moment(endDate).format('YYYY-MM-DD'),
          question_deadline: moment(questionDeadline).format('YYYY-MM-DD'),
          question_submission_limit: questionSubmissionLimit,
          auth_token: token
        };
        await dispatch(addNewOutcome(payload))
          .unwrap()
          .then((response) => {
            if (isMounted) {
              setTitle('');
              setDescription('');
            }
            if (response.status === 'success') {
              problemId = response.data.id;
              openOutcomeView(problemId);
            }
          });
          if (problemId) {
            groups.forEach(group => {
              if (selectedGroups.includes(group.name)) {
                const groupPayload = {
                  id: group.id,
                  auth_token: token,
                  outcome_ids: [problemId]
                }
                dispatch(addOutcomesToUserGroup(groupPayload));
              }
            })
            setSelectedGroups([]);
          }
      } catch (err) {
        setErrorMessage(`Failed to create outcome: ${err.message}`);
      } finally {
        if (isMounted) {
          setAddRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (
        title &&
        description &&
        endDate &&
        questionSubmissionLimit &&
        questionDeadline
      )
        setErrorMessage('Outcome could not be created.');
      if (!title) setErrorMessage('Please set a title before submitting.');
      else if (!description)
        setErrorMessage('Please set a Description before submitting.');
      else if (!endDate)
        setErrorMessage('Please set an Outcome end date before submitting');
      else if (!questionDeadline)
        setErrorMessage(
          'Please set a Question Submission Deadline before submitting'
        );
      else if (!questionSubmissionLimit)
        setErrorMessage(
          'Please set a Question Submission Limit before submitting.'
        );
    }
  };

  const calculateTimeBetweenDeadlines = () => {
    return moment(questionDeadline).from(endDate, true);
  };

  return (
    <Card sx={{ my: 2 }}>
      <form className="xs:p2 md:px-3 md:py-4 m-5">
        <div>
          <Typography sx={{ mb: 0.6, fontWeight: 'bold' }}>
            Submit an Outcome
          </Typography>
          <Divider />
          <Typography color="error">{errorMessage}</Typography>

          <div>
            <div className="flex mt-3">
              <Typography className="">Title</Typography>
            </div>
            <TextField
            placeholder = 'A summary of the outcome.'
              type="text"
              variant="outlined"
              value={title}
              id="outcomeTitle"
              onChange={(event) => changeTitle(event)}
              sx={{ my: 1.2 }}
              className="w-full"
            />
          </div>

          <div>
            <div className="flex mt-3">
              <Typography className="">Summary</Typography>
            </div>
            <TextField
            placeholder = 'A text description that describes your decision, objectives, and any other key concerns.'
              id="outcomeDescription"
              variant="outlined"
              multiline
              value={description}
              onChange={(event) => changeDescription(event)}
              rows="4"
              sx={{ my: 1.2 }}
              className="w-full"></TextField>
          </div>

          <div>
            <div className="flex mt-3">
              <Typography className="">Outcome Group(s)</Typography>
              <div className="ml-1">
                <InfoTooltip text="User group(s) that outcome should be a part of, this will allow users in the group to view this." />
              </div>
            </div>  
                <MultiSelectDropdown
                  label={"Select group(s)"}
                  options={groupNames}
                  selectedValues={selectedGroups}
                  onSelectedValuesChange={setSelectedGroups}
                />
            </div>

          <div className="mr-3 mt-3">
            <div className="flex">
              <Typography className="">Question Submission Limit</Typography>
              <div className="ml-1">
                <InfoTooltip text="The maximum number of Questions that will be submitted to the forecasting platform relating to this Outcome." />
              </div>
            </div>
            <TextField
              type="text"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              size="small"
              onChange={(event) => changeQuestionSubmissionLimit(event)}
              value={questionSubmissionLimit}
              sx={{ my: 0.6 }}
              className="w-1/6"></TextField>
          </div>
          <div className="flex justify-evenly items-center mt-5 mb-1">
            <div className="mr-2 flex">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ukLocale}>
                <DatePicker
                  clearable={false}
                  label="Outcome End Date"
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  minDate={Date.now()}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <div className="ml-1">
                <InfoTooltip text="The date after which the Outcome is no longer valid, and when all the questions and evaluations will be scored." />
              </div>
            </div>
            <div className="ml-2 flex text-center">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ukLocale}>
                <DatePicker
                  clearable={false}
                  label="Question Submission Deadline"
                  value={questionDeadline}
                  onChange={(newValue) => {
                    setQuestionDeadline(newValue);
                  }}
                  maxDate={endDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <div className="ml-1">
                <InfoTooltip text="The date on which Question generation activity will stop and Questions will be submitted to the forecasting platform." />
              </div>
            </div>
          </div>
          <div className="px-2 my-3 text-center">
            <Typography
              sx={{ fontSize: '0.795rem' }}
              className="text-xs font-medium">{`There is ${calculateTimeBetweenDeadlines()} between the above Question Submission Deadline and the Outcome End Date. This gives ${calculateTimeBetweenDeadlines()} for Question forecasting.`}</Typography>
          </div>
        </div>
        <div className="flex w-full justify-end">
          <div className="mr-5">
            <Button
              variant="contained"
              onClick={(event) => submitOutcome(event)}>
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
}
