import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { hivemindAPI } from "../HivemindAPI";

const userGroupAdapter = createEntityAdapter({});

const initialState = userGroupAdapter.getInitialState({
  status: "idle",
  error: null,
  totalGroups: 0,
});

export const fetchUserGroups = createAsyncThunk(
  "user_groups/fetchUserGroups",
  async (auth_token) => {
    const response = await hivemindAPI.fetch(
      "/api/user_groups",
      null,
      auth_token
    );
    return response.json();
  }
);

export const deleteUserGroup = createAsyncThunk(
  "user_groups/deleteUserGroup",
  async (payload) => {
    const response = await hivemindAPI.delete(
      "/api/user_groups",
      {
        id: payload.id,
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateUserGroup = createAsyncThunk(
  "user_groups/updateUserGroup",
  async (payload) => {
    const response = await hivemindAPI.put(
      "/api/user_groups",
      {
        id: payload.id,
        name: payload.name,
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addUsersToUserGroup = createAsyncThunk(
  "user_groups/addUserToUserGroup",
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/user_groups/${payload.id}/add_user`,
      {
        user_ids: payload.user_ids,
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const removeUsersFromUserGroup = createAsyncThunk(
  "user_groups/removeUserFromUserGroup",
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/user_groups/${payload.id}/remove_user`,
      {
        user_ids: payload.user_ids,
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addOutcomesToUserGroup = createAsyncThunk(
  "user_groups/addOutcomesToUserGroup",
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/user_groups/${payload.id}/add_outcome`,
      {
        outcome_ids: payload.outcome_ids,
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const removeOutcomesFromUserGroup = createAsyncThunk(
  "user_groups/removeOutcomesFromUserGroup",
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/user_groups/${payload.id}/remove_outcome`,
      {
        outcome_ids: payload.outcome_ids,
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewUserGroup = createAsyncThunk(
  "user_groups/addNewUserGroup",
  async (payload) => {
    const response = await hivemindAPI.post(
      "/api/user_groups",
      {
        name: payload.name,
      },
      payload.auth_token
    );
    return response.json();
  }
);


export const userGroupsSlice = createSlice({
  name: "userGroups",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchUserGroups.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUserGroups.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.totalGroups = action.payload.data.length;
        userGroupAdapter.upsertMany(state, action.payload.data);
      })
      .addCase(fetchUserGroups.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateUserGroup.fulfilled, (state, action) => {
        const updatedUserGroup = action.payload.data;
        userGroupAdapter.updateOne(state, {
          id: updatedUserGroup.id,
          changes: updatedUserGroup,
        });
      })
      .addCase(deleteUserGroup.fulfilled, (state, action) => {
        userGroupAdapter.removeOne(state, action.meta.arg.id);
      })
      .addCase(addNewUserGroup.fulfilled, (state, action) => {
        userGroupAdapter.addOne(state, action.payload.data);
      })
      .addCase(addUsersToUserGroup.fulfilled, (state, action) => {
        const updatedUserGroup = action.payload.data;
        userGroupAdapter.updateOne(state, {
          id: updatedUserGroup.id,
          changes: updatedUserGroup,
        });
      })
      .addCase(addOutcomesToUserGroup.fulfilled, (state, action) => {
        const updatedUserGroup = action.payload.data;
        userGroupAdapter.updateOne(state, {
          id: updatedUserGroup.id,
          changes: updatedUserGroup,
        });
      })
      .addCase(removeUsersFromUserGroup.fulfilled, (state, action) => {
        const updatedUserGroup = action.payload.data;
        userGroupAdapter.updateOne(state, {
          id: updatedUserGroup.id,
          changes: updatedUserGroup,
        });
      }).addCase(removeOutcomesFromUserGroup.fulfilled, (state, action) => {
        const updatedUserGroup = action.payload.data;
        userGroupAdapter.updateOne(state, {
          id: updatedUserGroup.id,
          changes: updatedUserGroup,
        });
      });
  },
});

export const userGroupsActions = userGroupsSlice.actions;
export default userGroupsSlice.reducer;

export const {
  selectAll: selectAllUserGroups,
  selectById: selectUserGroupById,
  selectIds: selectUserGroupIds,
} = userGroupAdapter.getSelectors((state) => state.userGroups);

export const selectGroupNames = createSelector(
  [selectAllUserGroups, (state) => state],
  (groups) => {
    return groups.map((group) => group.name);
  }
);

export const selectGroupByName = createSelector(
  [selectAllUserGroups, (state, name) => name],
  (groups, name) => groups.find((group) => group.name === name)
);
