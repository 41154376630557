import { createSlice, createAsyncThunk, createEntityAdapter, createSelector } from "@reduxjs/toolkit"
import { hivemindAPI } from "../HivemindAPI";

const settingsAdapter = createEntityAdapter({})
const settingGroupsAdapter = createEntityAdapter({})
const settingGroupsInitialState = settingGroupsAdapter.getInitialState({ status: "idle", error: null })

const initialState = settingsAdapter.getInitialState({
    status: "idle",
    error: null,
    settingGroups: settingGroupsInitialState
})

export const fetchSettings = createAsyncThunk("settings/fetchSettings", async (settingsId) => {
    const response = await hivemindAPI.fetch(`/api/settings`);
    return response.json()
})

export const fetchSettingGroups = createAsyncThunk("settings/fetchSettingGroups", async () => {
    const response = await hivemindAPI.fetch(`/api/settings/groups`);
    return response.json()
})

export const updateSetting = createAsyncThunk(
    "settings/updateSettings",
    async (payload) => {
        const response = await hivemindAPI.put("/api/settings", {
            id: payload.id,
            active: payload.active,
            value: payload.value,
            toggle_options: payload.toggle_options
        }, payload.auth_token)
        return response.json()
    }
)

export const settingsSlice = createSlice({
    name: "settings",
    initialState,
    extraReducers(builder) {
        builder
            .addCase(fetchSettings.pending, (state, action) => {
                state.status = "loading"
            })
            .addCase(fetchSettings.fulfilled, (state, action) => {
                state.status = "succeeded"
                action.payload.data.map((setting) => {
                    if (setting.active !== undefined) {
                        setting.active = setting.active === 'True' ? true : false
                    }
                    if (setting.options !== undefined) {
                        for (const option of setting.options) {
                            option.selected = option.selected === 'True' ? true : false
                        }
                    }
                    return setting
                })
                settingsAdapter.addMany(state, action.payload.data)
            })
            .addCase(fetchSettings.rejected, (state, action) => {
                state.status = "failed"
                state.error = action.error.message
            })
            .addCase(fetchSettingGroups.fulfilled, (state, action) => {
                state.settingGroups.status = "succeeded"
                settingGroupsAdapter.addMany(state.settingGroups, action.payload.data)
            })
            .addCase(fetchSettingGroups.rejected, (state, action) => {
                state.settingGroups.status = "failed"
                state.settingGroups.error = action.error.message
            })
            .addCase(updateSetting.fulfilled, (state, action) => {
                const updateSetting = action.payload.data
                if (updateSetting.active !== undefined) {
                    updateSetting.active = updateSetting.active === 'True' ? true : false
                }
                if (updateSetting.options !== undefined) {
                    for (const option of updateSetting.options) {
                        option.selected = option.selected === 'True' ? true : false
                    }
                }
                settingsAdapter.updateOne(state, { id: updateSetting.id, changes: updateSetting })
            })
    }
});

export default settingsSlice.reducer;


export const {
    selectAll: selectAllSettings,
    selectById: selectSettingById,
    selectIds: selectSettingsIds,
} = settingsAdapter.getSelectors(state => state.settings)

export const {
    selectAll: selectAllSettingGroups,
    selectById: selectSettingGroupsById,
    selectIds: selectSettingGroupIds,
} = settingGroupsAdapter.getSelectors(state => state.settings.settingGroups)

export const selectSettingByName = createSelector(
    [selectAllSettings, (state, name) => name],
    (settings, name) => settings.find(setting => setting.name === name)
)