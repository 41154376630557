import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { AuthContext } from '../../App';
import QuestionCard from '../../components/cards/QuestionCard';
import CommentList from '../../components/lists/CommentList';
import HierarchicalBreadcrumbs from '../../components/other/HierarchicalBreadcrumbs';
import {
  fetchQuestionById,
  selectQuestionById
} from '../../store/slices/outcomeSlice';

export default function QuestionView() {
  const dispatch = useDispatch();
  const { isLoggedIn } = useContext(AuthContext);
  const [isVotingDisabled, setIsVotingDisabled] = useState(false);
  let { questionId } = useParams();
  const [fetchQuestionStatus, setFetchQuestionStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const question = useSelector((state) =>
    selectQuestionById(state, questionId)
  );

  useEffect(() => {
    let isMounted = true;
    function fetchQuestionData() {
      if (isMounted) {
        if (fetchQuestionStatus === 'idle') {
          try {
            setFetchQuestionStatus('pending');
            const token = localStorage.getItem('auth_token');
            dispatch(
              fetchQuestionById({ questionId: questionId, auth_token: token })
            )
              .unwrap()
              .then((response) => {
                if (response.data.id === parseInt(questionId)) {
                  setFetchQuestionStatus('succeeded');
                } else {
                  setFetchQuestionStatus('failed');
                  setErrorMessage(`Failed to fetch question data`);
                }
              });
          } catch (err) {
            setErrorMessage(`Failed to fetch question data: ${err.message}`);
          }
        }
      }
    }
    fetchQuestionData();
    return () => {
      isMounted = false;
    };
  }, [fetchQuestionStatus, dispatch, questionId]);

  let content;
  if (fetchQuestionStatus === 'pending') {
    content = (
      <div className="text-center">
        <ClipLoader color="#f87171" loading={true} size={100} />
      </div>
    );
  } else if (fetchQuestionStatus === 'succeeded') {
    content = (
      <div>
        <QuestionCard
          questionId={parseInt(questionId)}
          isLoggedIn={isLoggedIn}
          isVotingDisabled={isVotingDisabled}
          setIsVotingDisabled={(val) => setIsVotingDisabled(val)}
          detail={true}
        />
        <CommentList questionId={parseInt(questionId)} maxIndentLevels={4} />
      </div>
    );
  } else {
    content = <p className="text-sm text-center">Question could be found.</p>;
  }

  return (
    <div className="QuestionView mt-5">
      <div className="flex ml-10 mt-7 items-center">
        <HierarchicalBreadcrumbs
          outcomes={true}
          outcome={true}
          outcomeId={question ? question.outcome_id : '?'}
          question={true}
        />
      </div>

      {errorMessage && (
        <p className="text-xs m-2 text-red-600 py-2">{errorMessage}</p>
      )}
      <section className="question-view">{content}</section>
    </div>
  );
}
