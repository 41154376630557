import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import './App.css';

import {
  fetchSettingGroups,
  fetchSettings
} from './store/slices/settingsSlice';

import { CssBaseline, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import TimeOutModal from './components/modals/TimeOutModal';
import Footer from './components/other/Footer';
import Navbar from './components/other/Navbar';
import { getColourPalette } from './components/other/Theme';
import AboutView from './pages/AboutView';
import LandingPage from './pages/LandingPage.jsx';
import LoginView from './pages/LoginView';
import PageNotFound from './pages/PageNotFound';
import AccountSettings from './pages/protected/AccountSettings';
import AdminSettingsView from './pages/protected/AdminSettingsView';
import CommentView from './pages/protected/CommentView';
import DemographicsQuestionnaireView from './pages/protected/DemographicsQuestionnaireView';
import LeaderboardView from './pages/protected/LeaderboardView';
import OpenMindedQuestionnaireView from './pages/protected/OpenMindedQuestionnaireView';
import OutcomesView from './pages/protected/OutcomesView';
import OutcomeView from './pages/protected/OutcomeView';
import PersonalityQuestionnaireView from './pages/protected/PersonalityQuestionnaireView';
import ProfileView from './pages/protected/ProfileView';
import QuestionForecasting from './pages/protected/QuestionForecasting';
import QuestionView from './pages/protected/QuestionView';
import SubmitView from './pages/protected/SubmitView';
import UserGroupsView from './pages/protected/UserGroupsView';
import UsersView from './pages/protected/UsersView';
import { fetchUsers } from './store/slices/userSlice';

export const AuthContext = createContext();

const userRouteAccess = {
  admin: [
    'outcomes',
    'outcome',
    'comment',
    'question',
    'submit',
    'forecasting',
    'account-settings',
    'profile',
    'users',
    'leaderboard',
    'admin-settings',
    'personalityQuestionnaire',
    'demographicsQuestionnaire',
    'openMindedQuestionnaire',
    'user-groups'
  ],
  moderator: [
    'outcomes',
    'outcome',
    'comment',
    'question',
    'submit',
    'forecasting',
    'account-settings',
    'profile',
    'users',
    'leaderboard',
    'personalityQuestionnaire',
    'demographicsQuestionnaire',
    'openMindedQuestionnaire',
    'user-groups'
  ],
  outcomeOwner: [
    'outcomes',
    'outcome',
    'comment',
    'question',
    'submit',
    'account-settings',
    'profile',
    'users',
    'leaderboard',
    'personalityQuestionnaire',
    'demographicsQuestionnaire',
    'openMindedQuestionnaire',
    'user-groups'
  ],
  questioner: [
    'outcomes',
    'outcome',
    'comment',
    'question',
    'account-settings',
    'profile',
    'users',
    'leaderboard',
    'personalityQuestionnaire',
    'demographicsQuestionnaire',
    'openMindedQuestionnaire',
    'user-groups'
  ],
  observer: [
    'outcomes',
    'outcome',
    'comment',
    'question',
    'account-settings',
    'profile',
    'users',
    'leaderboard',
    'user-groups'
  ]
};

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {}
});

export const checkTokenStatus = () => {
  const token = localStorage.getItem('auth_token');
  if (token === null || token === undefined) {
    return false;
  }
  const tokenBody = JSON.parse(atob(token.split('.')[1]));
  const tokenExp = tokenBody.exp;
  const now = new Date().getTime() / 1000;
  if (now > tokenExp) {
    return false;
  }
  return true;
};

export function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});
  const [mode, setMode] = useState('light');
  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      }
    }),
    []
  );
  let theme = useMemo(() => createTheme(getColourPalette(mode)), [mode]);
  theme = responsiveFontSizes(theme);

  useEffect(() => {
    const token = localStorage.getItem('auth_token');
    async function getUserData() {
      if (checkTokenStatus() === false) {
        localStorage.removeItem('auth_token');
        setIsLoggedIn(false);
        setUserData({});
        return <Navigate to="/login" />;
      } else {
        setIsLoggedIn(true);
        await fetch('/api/auth/user', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.status === 'success') {
              setUserData(data.data);
            } else {
              localStorage.removeItem('auth_token');
              setIsLoggedIn(false);
              setUserData({});
              return <Navigate to="/login" />;
            }
          });
      }
    }

    getUserData();
  }, [setIsLoggedIn, isLoggedIn]);

  useEffect(() => {
    if (localStorage.getItem('mode')) {
      setMode(localStorage.getItem('mode'));
    }
  }, []);

  return (
    <div className="flex flex-col min-h-screen justify-between">
      <AuthContext.Provider
        value={{ isLoggedIn, setIsLoggedIn, userData, setUserData }}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div>
              <Navbar />
              <Routes>
                <Route path="/" element={<LandingPage />} />{' '}
                {/* New default landing page */}
                <Route path="/login" element={<LoginView />} />
                <Route
                  exact
                  path="/outcomes"
                  element={<ProtectedRoute routeName={'outcomes'} />}>
                  <Route path="/outcomes" element={<OutcomesView />} />
                </Route>
                <Route
                  exact
                  path="/outcomes/:outcomeId"
                  element={<ProtectedRoute routeName={'outcome'} />}>
                  <Route
                    path="/outcomes/:outcomeId"
                    element={<OutcomeView />}
                  />
                </Route>
                <Route
                  exact
                  path="/questions/:questionId"
                  element={<ProtectedRoute routeName={'question'} />}>
                  <Route
                    path="/questions/:questionId"
                    element={<QuestionView />}
                  />
                </Route>
                <Route
                  exact
                  path="/questions/:questionId/comment/:commentId"
                  element={<ProtectedRoute routeName={'comment'} />}>
                  <Route
                    path="/questions/:questionId/comment/:commentId"
                    element={<CommentView />}
                  />
                </Route>
                <Route
                  exact
                  path="/submit"
                  element={<ProtectedRoute routeName={'submit'} />}>
                  <Route path="/submit" element={<SubmitView />} />
                </Route>
                <Route
                  exact
                  path="/forecasting"
                  element={<ProtectedRoute routeName={'forecasting'} />}>
                  <Route
                    path="/forecasting"
                    element={<QuestionForecasting />}
                  />
                </Route>
                <Route
                  exact
                  path="/admin-settings"
                  element={<ProtectedRoute routeName={'admin-settings'} />}>
                  <Route
                    exact
                    path="/admin-settings"
                    element={<AdminSettingsView />}
                  />
                </Route>
                <Route
                  exact
                  path="/users"
                  element={<ProtectedRoute routeName={'users'} />}>
                  <Route exact path="/users" element={<UsersView />} />
                </Route>
                <Route
                  exact
                  path="/user-groups"
                  element={<ProtectedRoute routeName={'user-groups'} />}>
                  <Route
                    exact
                    path="/user-groups"
                    element={<UserGroupsView />}
                  />
                </Route>
                <Route
                  exact
                  path="/user-groups/:groupId"
                  element={<ProtectedRoute routeName={'user-groups'} />}>
                  <Route
                    path="/user-groups/:groupId"
                    element={<UserGroupsView />}
                  />
                </Route>
                <Route
                  exact
                  path="/leaderboard"
                  element={<ProtectedRoute routeName={'leaderboard'} />}>
                  <Route
                    exact
                    path="/leaderboard"
                    element={<LeaderboardView />}
                  />
                </Route>
                <Route
                  exact
                  path="/account-settings"
                  element={<ProtectedRoute routeName={'account-settings'} />}>
                  <Route
                    exact
                    path="/account-settings"
                    element={<AccountSettings />}
                  />
                </Route>
                <Route
                  exact
                  path="/profile/:username"
                  element={<ProtectedRoute routeName={'profile'} />}>
                  <Route
                    exact
                    path="/profile/:username"
                    element={<ProfileView />}
                  />
                </Route>
                <Route
                  exact
                  path="/questionnaire/personality"
                  element={
                    <ProtectedRoute routeName={'personalityQuestionnaire'} />
                  }>
                  <Route
                    exact
                    path="/questionnaire/personality"
                    element={<PersonalityQuestionnaireView />}
                  />
                </Route>
                <Route
                  exact
                  path="/questionnaire/demographics"
                  element={
                    <ProtectedRoute routeName={'demographicsQuestionnaire'} />
                  }>
                  <Route
                    exact
                    path="/questionnaire/demographics"
                    element={<DemographicsQuestionnaireView />}
                  />
                </Route>
                <Route
                  exact
                  path="/questionnaire/open-minded"
                  element={
                    <ProtectedRoute routeName={'openMindedQuestionnaire'} />
                  }>
                  <Route
                    exact
                    path="/questionnaire/open-minded"
                    element={<OpenMindedQuestionnaireView />}
                  />
                </Route>
                <Route path="/about" element={<AboutView />} />
                <Route path="/landing" element={<LandingPage />} />
                <Route path="/*" element={<PageNotFound />} />
              </Routes>
            </div>
            <div>
              <TimeOutModal />
            </div>
            <div>
              <Footer className="mt-auto" />
            </div>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </AuthContext.Provider>
    </div>
  );
}

const ProtectedRoute = ({ routeName }) => {
  const dispatch = useDispatch();
  const { userData } = useContext(AuthContext);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    dispatch(fetchSettings());
    dispatch(fetchSettingGroups());
  }, [routeName, dispatch]);

  useEffect(() => {
    setUserRole(userData.role);
    const token = localStorage.getItem('auth_token');
    if (token) {
      dispatch(fetchUsers(token));
    }
  }, [userData.role, dispatch]);

  if (checkTokenStatus()) {
    if (
      (userRole === 'Admin' && userRouteAccess.admin.includes(routeName)) ||
      (userRole === 'Moderator' &&
        userRouteAccess.moderator.includes(routeName)) ||
      (userRole === 'Questioner' &&
        userRouteAccess.questioner.includes(routeName)) ||
      (userRole === 'OutcomeOwner' &&
        userRouteAccess.outcomeOwner.includes(routeName)) ||
      (userRole === 'Observer' && userRouteAccess.observer.includes(routeName))
    ) {
      return <Outlet />;
    } else {
      return <PageNotFound />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};
