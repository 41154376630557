import {
  Button,
  Divider,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import Card from '@mui/material/Card';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import ukLocale from 'date-fns/locale/en-GB';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { FiDelete, FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Navigate } from 'react-router-dom';
import { AuthContext, checkTokenStatus } from '../../App';
import {
  deleteOutcome,
  selectOutcomeById,
  updateOutcome
} from '../../store/slices/outcomeSlice';
import { selectSettingByName } from '../../store/slices/settingsSlice';
import { selectUserPreferences } from '../../store/slices/userSlice';
import ConfirmationModal from '../modals/ConfirmationModal';
import InfoTooltip from '../other/InfoTooltip';

export default function OutcomeCard({ outcomeId, selectedFilter }) {
  const dispatch = useDispatch();
  const { setIsLoggedIn, userData } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const usernameSettings = useSelector((state) =>
    selectSettingByName(state, 'View Usernames')
  );

  const outcome = useSelector((_state) => selectOutcomeById(_state, outcomeId));
  const title = outcome.title;
  const [newTitle, setNewTitle] = useState(outcome.title);
  const [description, setDescription] = useState(outcome.description);
  const [questionSubmissionLimit, setQuestionSubmissionLimit] = useState(
    outcome.question_submission_limit
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [editCard, setEditCard] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [endDate, setEndDate] = useState(parseISO(outcome.end_at));
  const userPreferences = useSelector((state) => selectUserPreferences(state));
  const [questionDeadline, setQuestionDeadline] = useState(
    parseISO(outcome.question_deadline)
  );

  const changeTitle = (event) => {
    setNewTitle(event.target.value);
  };
  const changeDescription = (event) => {
    setDescription(event.target.value);
  };
  const changeQuestionSubmissionLimit = (event) => {
    const submissionLimit = event.target.validity.valid
      ? event.target.value
      : questionSubmissionLimit;
    setQuestionSubmissionLimit(submissionLimit);
  };

  const [updateRequestStatus, setUpdateRequestStatus] = useState('idle');
  const [deleteRequestStatus, setDeleteRequestStatus] = useState('idle');

  const openOutcomeView = () => {
    navigate(`/outcomes/${outcomeId}`);
  };

  const canUpdate =
    [
      outcome.id,
      newTitle ||
        description ||
        questionSubmissionLimit ||
        questionDeadline ||
        endDate,
      checkTokenStatus()
    ].every(Boolean) && updateRequestStatus === 'idle';

  const updateOutcomeData = async () => {
    if (canUpdate) {
      let isMounted = true;
      try {
        setUpdateRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          id: outcome.id,
          auth_token: token
        };
        if (newTitle) {
          payload.new_title = newTitle;
        }
        if (description) {
          payload.description = description;
        }
        if (questionSubmissionLimit) {
          payload.question_submission_limit = questionSubmissionLimit;
        }
        if (questionDeadline) {
          payload.question_deadline = questionDeadline;
        }
        if (endDate) {
          payload.end_date = endDate;
        }
        await dispatch(updateOutcome(payload)).unwrap();
      } catch (err) {
        setErrorMessage(`Failed to update outcome: ${err.message}`);
      } finally {
        if (isMounted) {
          setUpdateRequestStatus('idle');
          isMounted = false;
        }
        setEditCard(!editCard);
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (newTitle && description)
        setErrorMessage('Outcome could not be created.');
      if (!description) setErrorMessage('Description does not exist.');
      if (!newTitle) setErrorMessage('Title does not exist.');
      if (!questionSubmissionLimit)
        setErrorMessage('Question Submission Limit does not exist.');
      if (!questionDeadline)
        setErrorMessage('Question Deadline does not exist.');
      if (!endDate) setErrorMessage('Outcome end date does not exist.');
    }
  };

  const canDelete =
    [title, checkTokenStatus()].every(Boolean) &&
    deleteRequestStatus === 'idle';

  const deleteOutcomeData = async () => {
    if (canDelete) {
      let isMounted = true;
      try {
        setDeleteRequestStatus('pending');
        const token = localStorage.getItem('auth_token');
        let payload = {
          outcomeId: outcomeId,
          auth_token: token
        };
        await dispatch(deleteOutcome(payload)).unwrap();
      } catch (err) {
        setErrorMessage(`Failed to delete outcome: ${err.message}`);
      } finally {
        if (isMounted) {
          setDeleteRequestStatus('idle');
          isMounted = false;
        }
      }
    } else if (checkTokenStatus() === false) {
      setIsLoggedIn(false);
      return <Navigate to={'/login'} />;
    } else {
      if (title) setErrorMessage('Outcome could not be created.');
      if (!title) setErrorMessage('Title does not exist.');
    }
  };

  const outcomeCardClick = () => {
    openOutcomeView();
  };

  const getStatusText = () => {
    if (outcome.status === 'Generation') {
      return 'Started';
    } else if (outcome.status === 'Evaluation') {
      return 'Evaluation Started';
    } else if (outcome.status === 'Forecasting') {
      return 'Forecasting Started';
    } else {
      return 'Closed';
    }
  };

  const timeBetweenStatusChange = () => {
    if (outcome.status === 'Closed') {
      return moment(outcome.end_at, 'YYYY-MM-DD').fromNow();
    } else if (outcome.status === 'Generation') {
      return moment(outcome.created_at, 'YYYY-MM-DDThh:mm:ss').fromNow();
    } else if (outcome.status === 'Evaluation') {
      return moment(outcome.generation_end_date, 'YYYY-MM-DD').fromNow();
    } else {
      return moment(outcome.question_deadline, 'YYYY-MM-DD').fromNow();
    }
  };

  const getEndingStatusText = () => {
    if (outcome.status === 'Generation') {
      return 'Generation Deadline';
    } else if (outcome.status === 'Evaluation') {
      return 'Evaluation Deadline';
    } else if (outcome.status === 'Forecasting') {
      return 'Ending';
    } else {
      return '';
    }
  };

  const timeTillFinish = () => {
    if (outcome.status === 'Generation') {
      return moment(outcome.generation_end_date, 'YYYY-MM-DD').fromNow();
    } else if (outcome.status === 'Evaluation') {
      return moment(outcome.question_deadline, 'YYYY-MM-DD').fromNow();
    } else if (outcome.status === 'Forecasting') {
      return moment(outcome.end_at, 'YYYY-MM-DD').fromNow();
    } else {
      return '';
    }
  };

  const getBorderColour = () => {
    switch (outcome.status) {
      case 'Generation':
        return theme.palette.statuses.mid1;
      case 'Evaluation':
        return theme.palette.statuses.mid2;
      case 'Closed':
        return theme.palette.statuses.mid4;
      case 'Forecasting':
        return theme.palette.statuses.mid3;
      default:
        return theme.palette.statuses.mid5;
    }
  };

  if (usernameSettings === undefined) {
    return null;
  } else {
    return (
      <div>
        {outcome === undefined ? (
          <div></div>
        ) : (
          <Card
            sx={{
              transition: '0.3s',
              boxShadow: '0 7px 20px -9px rgba(0,0,0,0.3)',
              '&:hover': {
                boxShadow: '0 14px 37px -9.125px rgba(0,0,0,0.3)',
                bgcolor: 'hover'
              },
              borderLeftWidth: '5px',
              borderLeftColor: getBorderColour()
            }}
            className={`rounded shadow-lg m-5 px-4 ${
              editCard ? 'py-5' : 'cursor-pointer duration-300 py-7'
            }`}>
            {!editCard ? (
              <div onClick={outcomeCardClick}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    fontSize: '1.1rem'
                  }}>
                  <span className="whitespace-pre-line">{newTitle}</span>
                </Typography>
                <div className="OutcomeInfo flex flex-wrap items-center justify-start">
                  {(userData.role === 'Admin' ||
                    userData.role === 'Moderator' ||
                    usernameSettings.active) && (
                    <div className="flex">
                      <Typography
                        sx={{
                          ml: 0.6,
                          fontSize: '0.95rem',
                          alignItems: 'center'
                        }}
                        color="text.secondary">
                        Created by
                      </Typography>
                      <Typography
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/profile/${outcome.created_by.username}`);
                        }}
                        sx={{ mx: 0.6, fontSize: '0.95rem' }}
                        className="hover:underline hover:cursor-pointer"
                        color="primary.main">
                        {outcome.created_by.username}
                      </Typography>
                    </div>
                  )}
                  <Typography
                    sx={{ mx: 0.6, fontSize: '0.95rem' }}
                    color="text.secondary">
                    {getStatusText()} {timeBetweenStatusChange()}
                  </Typography>

                  {(outcome.status === 'Generation' ||
                    outcome.status === 'Evaluation' ||
                    outcome.status === 'Forecasting') && (
                    <Typography
                      sx={{ mx: 0.6, fontSize: '0.95rem' }}
                      color="text.secondary">
                      {getEndingStatusText()} {timeTillFinish()}
                    </Typography>
                  )}
                </div>
                {userPreferences.outcome_layout === 'detail' && (
                  <div className="">
                    <Divider />
                    <div className="flex my-1 max-h-36 overflow-y-auto">
                      <Typography
                        sx={{
                          ml: 0.6,
                          mr: 0.6,
                          fontSize: '0.95rem',
                          fontWeight: 'bold'
                        }}
                        color="text.secondary">
                        Description:
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '0.95rem'
                        }}>
                        <span className="whitespace-pre-line">
                          {outcome.description}
                        </span>
                      </Typography>
                    </div>
                    <div className="flex">
                      <Typography
                        sx={{
                          ml: 0.6,
                          mr: 0.6,
                          fontSize: '0.95rem',
                          fontWeight: 'bold'
                        }}
                        color="text.secondary">
                        Outcome Ends:
                      </Typography>
                      <Typography sx={{ fontSize: '0.95rem' }}>{`${moment(
                        outcome.end_at
                      ).format('dddd, MMMM Do YYYY')}`}</Typography>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <>
                <Typography className="px-1" color="text.secondary">
                  Outcome Title
                </Typography>

                {errorMessage && (
                  <p className="text-xs text-red-600 py-2">{errorMessage}</p>
                )}

                <TextField
                  type="text"
                  variant="outlined"
                  value={newTitle}
                  onChange={(event) => changeTitle(event)}
                  className="w-full"
                  inputProps={{ style: { fontSize: '0.875rem' } }}
                  sx={{ my: 0.6 }}
                />

                <Typography
                  className="px-1"
                  color="text.secondary"
                  sx={{ my: 0.6 }}>
                  Outcome Description
                </Typography>
                <TextField
                  type="text"
                  variant="outlined"
                  multiline
                  minRows={2}
                  value={description}
                  sx={{ my: 0.6 }}
                  inputProps={{ style: { fontSize: '0.875rem' } }}
                  onChange={(event) => changeDescription(event)}
                  className="w-full"
                />
                <div className="flex justify-start items-end mb-1">
                  <div>
                    <Typography
                      className="px-1"
                      color="text.secondary"
                      sx={{ my: 0.6, fontSize: '0.875rem' }}>
                      Question Submission Limit
                    </Typography>
                    <TextField
                      type="text"
                      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      value={questionSubmissionLimit}
                      size="small"
                      onChange={(event) => changeQuestionSubmissionLimit(event)}
                      className="md:w-1/2"
                      sx={{ my: 0.6 }}
                    />
                  </div>

                  <div className="ml-5 flex">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ukLocale}>
                      <DateTimePicker
                        clearable
                        label="Question Submission Deadline"
                        value={questionDeadline}
                        onChange={(newValue) => {
                          setQuestionDeadline(newValue);
                        }}
                        maxDateTime={endDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <div className="ml-1">
                      <InfoTooltip text="The date on which Question generation activity will stop and Questions will be submitted to the forecasting platform." />
                    </div>
                  </div>
                  <div className="ml-5 flex">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ukLocale}>
                      <DateTimePicker
                        clearable={false}
                        label="Outcome End Date"
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        minDateTime={questionDeadline}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <div className="ml-1">
                      <InfoTooltip text="The date to which the Outcome is no longer valid." />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="flex pt-1 items-center justify-between">
              <div className="flex">
                {outcome.status === 'Generation' &&
                  outcome.created_by.username === userData.username &&
                  outcome.questions.length === 0 && (
                    <>
                      <div className="flex items-center">
                        <Button
                          variant="text"
                          sx={{ color: 'text.primary' }}
                          onClick={() => {
                            setEditCard(!editCard);
                          }}>
                          <FiEdit className="mr-1" />
                          {editCard ? 'Cancel' : 'Edit'}
                        </Button>
                      </div>
                      <div className="flex items-center">
                        <Button
                          variant="text"
                          sx={{ color: 'text.primary' }}
                          onClick={() => {
                            setShowDeleteConfirm(true);
                          }}>
                          <FiDelete className="mr-1" />
                          Delete
                        </Button>
                      </div>
                    </>
                  )}
              </div>
              {editCard && (
                <div className="flex justify-end px-5">
                  <Button variant="contained" onClick={updateOutcomeData}>
                    Save
                  </Button>
                </div>
              )}
            </div>
          </Card>
        )}
        {showDeleteConfirm && (
          <ConfirmationModal
            shown={showDeleteConfirm}
            close={() => {
              setShowDeleteConfirm(false);
            }}
            confirm={() => {
              setShowDeleteConfirm(false);
              deleteOutcomeData();
            }}
            confirmationMessage="Do you really want to delete this outcome? This process cannot be undone"
          />
        )}
      </div>
    );
  }
}
