import React, { useEffect } from 'react';

import QuestionCard from '../cards/QuestionCard';
import CommentList from './CommentList';

import ClipLoader from 'react-spinners/ClipLoader';

import { Divider, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchQuestionsByOutcome,
  selectFilteredQuestionsByOutcomeGlobal
} from '../../store/slices/outcomeSlice';
import { selectUserPreferences } from '../../store/slices/userSlice';
import ClickableQuestionCard from '../cards/ClickableQuestionCard';
import QuestionLayoutToggle from '../other/QuestionLayoutToggle';

export default function QuestionList({
  outcomeId,
  isLoggedIn,
  isVotingDisabled,
  setIsVotingDisabled,
  setListLoaded
}) {
  const dispatch = useDispatch();
  const questionStatus = useSelector(
    (state) => state.outcomes.outcomes.entities[outcomeId].questionStatus
  );
  const userPreferences = useSelector((state) => selectUserPreferences(state));

  // const orderedQuestionIds = useSelector(state => selectQuestionIdsByOutcome(state, outcomeId))
  const filteredQuestions = useSelector((state) =>
    selectFilteredQuestionsByOutcomeGlobal(state, outcomeId)
  );
  useEffect(() => {
    let isMounted = true;
    function fetchQuestionsData() {
      if (isMounted) {
        if (questionStatus === 'idle') {
          const token = localStorage.getItem('auth_token');
          dispatch(
            fetchQuestionsByOutcome({
              outcomeId: outcomeId,
              auth_token: token
            })
          );
        }
      }
    }

    fetchQuestionsData();
    return () => {
      isMounted = false;
    };
  }, [questionStatus, dispatch, outcomeId]);

  useEffect(() => {
    if (questionStatus === 'succeeded') {
      setListLoaded(true);
    }
  }, [questionStatus, setListLoaded]);

  if (questionStatus === 'loading') {
    return (
      <section className="question-list">
        <div className="text-center">
          <ClipLoader color="#f87171" loading={true} size={100} />
        </div>
      </section>
    );
  } else if (questionStatus === 'succeeded') {
    return (
      <section className="question-list">
        <div className="flex relative mt-5 items-center mx-10">
          <Divider className="flex-grow"></Divider>
          <Typography sx={{ mx: 2.4 }} className="flex-shrink">
            Questions ({filteredQuestions.length})
          </Typography>
          <Divider className="flex-grow"></Divider>
        </div>
        <div className="flex justify-end mt-2 mx-10">
          <QuestionLayoutToggle />
        </div>
        {filteredQuestions.map((question, index) => (
          <div key={index}>
            {userPreferences.question_layout === 'detail' ? (
              <QuestionCard
                questionId={question.id}
                isLoggedIn={isLoggedIn}
                isVotingDisabled={isVotingDisabled}
                setIsVotingDisabled={(val) => setIsVotingDisabled(val)}
              />
            ) : (
              <ClickableQuestionCard
                questionId={question.id}
                isLoggedIn={isLoggedIn}
                isVotingDisabled={isVotingDisabled}
                setIsVotingDisabled={(val) => setIsVotingDisabled(val)}
              />
            )}
            <CommentList
              questionId={question.id}
              maxIndentLevels={
                userPreferences.question_layout === 'detail' ? 2 : 1
              }
            />
          </div>
        ))}
      </section>
    );
  } else {
    return (
      <section className="question-list">
        <div className="flex justify-end mx-10">
          <QuestionLayoutToggle />
        </div>
        <Typography sx={{ my: 3 }} className="text-sm text-center">
          No questions could be found.
        </Typography>
      </section>
    );
  }
}
