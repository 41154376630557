import React, { useState } from 'react';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Grow,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';

import GraphOutcomeCard from '../components/cards/GraphOutcomeCard.jsx';
import ForecastDashboard from '../components/other/charts/ForecastDashboard';

import FJLogo from '../assets/images/FJ_INFIN_RIGHT_ORANGE.png';
import Bees from '../assets/images/landingpage_bees.jpg';
import LandingPageEvaluation from '../assets/images/landingpage_evaluation.jsx';
import LandingPageForecasting from '../assets/images/landingpage_forecasting.jsx';
import LandingPageGeneration from '../assets/images/landingpage_generation.jsx';
import GradientCircle from '../assets/images/landingpage_gradientcircle.png';
import Hexagon from '../assets/images/landingpage_hexagon.png';
import useWindowDimensions from './useWindowDimensions.jsx';

export default function AboutView() {
  const theme = useTheme();
  const [generationHover, setGenerationHover] = useState(false);
  const [evaluationHover, setEvaluationHover] = useState(false);
  const [forecastingHover, setForecastingHover] = useState(false);
  function scrollLeft() {
    document.getElementById('bee-carousel').scrollLeft -= 500;
    // todo: dynamic button disabling
  }
  function scrollRight() {
    document.getElementById('bee-carousel').scrollLeft += 500;
    // todo: dynamic button disabling
  }
  var falseData1 = {
    labels: [
      '2023-10-09T23:00:00.000Z',
      '2023-10-10T23:00:00.000Z',
      '2023-10-11T23:00:00.000Z',
      '2023-10-12T23:00:00.000Z'
    ],
    datasets: [
      {
        label: 'Dataset 1',
        data: [40, 58, 59, 70],
        borderColor: '#378dcf',
        backgroundColor: '#378dcf'
      }
    ]
  };
  var falseData2 = {
    labels: [
      '2023-09-09T23:00:00.000Z',
      '2023-09-10T23:00:00.000Z',
      '2023-09-11T23:00:00.000Z',
      '2023-09-12T23:00:00.000Z'
    ],
    datasets: [
      {
        label: 'Dataset 1',
        data: [70, 60, 40, 30],
        borderColor: '#378dcf',
        backgroundColor: '#378dcf'
      }
    ]
  };
  var falseData3 = {
    labels: [
      '2023-06-09T23:00:00.000Z',
      '2023-06-10T23:00:00.000Z',
      '2023-06-11T23:00:00.000Z',
      '2023-06-12T23:00:00.000Z'
    ],
    datasets: [
      {
        label: 'Dataset 1',
        data: [75, 65, 70, 60],
        borderColor: '#378dcf',
        backgroundColor: '#378dcf'
      }
    ]
  };
  var falseDashboardData = {
    labels: [
      '2023-10-07T23:00:00.000Z',
      '2023-10-08T23:00:00.000Z',
      '2023-10-09T23:00:00.000Z',
      '2023-10-10T23:00:00.000Z',
      '2023-10-11T23:00:00.000Z'
    ],
    datasets: [
      {
        label: 'Dataset 1',
        data: [10, 20, 15, 25, 30],
        pointStyle: false,
        borderColor: '#7ea8c0',
        backgroundColor: '#9ad6f9',
        borderWidth: 3,
        fill: {
          target: 'origin',
          above: 'rgba(201,240,249,0.4)'
        },
        hidden: false
      },
      {
        label: 'Dataset 2',
        data: [5, 10, 5, 20, 30],
        pointStyle: false,
        borderColor: '#a690c3',
        backgroundColor: '#d3b1ff',
        borderWidth: 3,
        fill: {
          target: 'origin',
          above: 'rgba(201,240,249,0.4)'
        },
        hidden: false
      },
      {
        label: 'Dataset 2',
        data: [5, 15, 15, 35, 15],
        pointStyle: false,
        borderColor: '#bc754f',
        backgroundColor: '#ff9154',
        borderWidth: 3,
        fill: {
          target: 'origin',
          above: 'rgba(201,240,249,0.4)'
        },
        hidden: false
      }
    ]
  };
  const { width } = useWindowDimensions();
  return (
    <div>
      <div style={{ position: 'relative' }}>
        <div className="flex w-full">
          <div className="w-1/2">
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundImage:
                  'linear-gradient(to bottom right,#FFF300,#FFA300,#FF6700)'
              }}
            />
          </div>
          <div className="w-1/2">
            <img
              style={{ width: '100%', height: undefined, aspectRatio: 3 / 2 }}
              src={Bees}
              alt="Bees on honeycomb"
              loading="lazy"
            />
          </div>
          <div
            style={{
              textAlign: 'right',
              width: '33%',
              position: 'absolute',
              top: '32%',
              left: '1%'
            }}>
            <Typography
              variant="span"
              style={{ fontWeight: '900', fontSize: '4.2vw', color: 'white' }}>
              Hivemind
            </Typography>
            <Typography
              variant="h4"
              style={{
                marginTop: '12%',
                fontWeight: '600',
                fontSize: '2.7vw',
                color: 'white'
              }}>
              The Science of Foresight
            </Typography>
          </div>
        </div>
        <div className="flex w-full justify-center">
          <img
            style={{
              maxHeight: '80%',
              position: 'absolute',
              top: '10%'
            }}
            loading="lazy"
            src={FJLogo}
            alt="Fujitsu Logo"
          />
        </div>
      </div>
      <div style={{ backgroundColor: '#3c3c3c' }}>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'left',
            paddingTop: '5%',
            paddingBottom: '5%',
            width: '65%'
          }}>
          <Typography
            variant="h3"
            style={{
              textAlign: 'center',
              color: 'white',
              marginTop: '3%',
              fontSize: 'calc(10px + 2.2vw)',
              fontWeight: 'bold'
            }}>
            Helping leaders ask better questions
          </Typography>
          <Typography
            variant="h4"
            style={{
              color: 'white',
              marginTop: '5%',
              fontSize: width > 800 ? '1.4vw' : '2vw',
              fontWeight: 500
            }}>
            Hivemind is a platform for generating better questions about desired
            outcomes or problem statements. A better question in Hivemind is one
            which reduces uncertainty about an aspect of a problem.
          </Typography>
          <Button
            variant="contained"
            style={{ fontSize: '1vw', marginTop: '5%', borderRadius: '10px' }}
            component={Link}
            to={'/outcomes'}>
            View Outcomes
          </Button>
          {width > 800 ? (
            <>
              <div
                id="bee-carousel"
                style={{
                  marginTop: '5%',
                  scrollbarWidth: 'none',
                  display: 'flex',
                  scrollSnapType: 'x mandatory',
                  overflow: 'hidden',
                  scrollBehavior: 'smooth'
                }}>
                <GraphOutcomeCard
                  falseTitle={
                    'Will Russia lose control of more major (population over 100,000) Ukrainian cities?'
                  }
                  falseStatus={'Forecasting'}
                  falseData={falseData1}
                />
                <GraphOutcomeCard
                  falseTitle={
                    'Will the number of Russian military personnel or equipment deployed to the region increase?'
                  }
                  falseStatus={'Closed'}
                  falseData={falseData2}
                />
                <GraphOutcomeCard
                  falseTitle={
                    'Will there be an increase in Russian diplomatic efforts, such as negotiations or mediation to find a political solution to the conflict?'
                  }
                  falseStatus={'Forecasting'}
                  falseData={falseData3}
                />
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton
                  aria-label="scroll-left"
                  onClick={() => scrollLeft()}>
                  <ChevronLeft
                    style={{ backgroundColor: 'grey', borderRadius: '30px' }}
                  />
                </IconButton>
                <IconButton
                  aria-label="scroll-right"
                  onClick={() => scrollRight()}>
                  <ChevronRight
                    style={{ backgroundColor: 'grey', borderRadius: '30px' }}
                  />
                </IconButton>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'left',
            paddingTop: '5%',
            paddingBottom: '5%',
            width: '65%'
          }}>
          <Typography
            variant="h3"
            style={{
              textAlign: 'center',
              marginTop: '3%',
              fontSize: 'calc(10px + 2.2vw)',
              fontWeight: 'bold'
            }}>
            How Does Hivemind Work?
          </Typography>
          <Typography
            variant="h4"
            style={{
              marginTop: '5%',
              fontSize: width > 800 ? '1.6vw' : '2vw'
            }}>
            A diverse group of questioners provide questions and evaluations to
            decision-makers. The platform then uses a specialised scoring
            technique to identify SuperQuestioners.
          </Typography>
          <div
            style={{
              marginTop: '10%',
              marginBottom: '10%',
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <div
              style={{
                width: '25%',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <div
                style={{ position: 'relative', width: '100%', height: '16vw' }}>
                {!generationHover && <LandingPageGeneration />}
                <Grow in={generationHover}>
                  <div className="absolute w-full h-full flex justify-center items-center">
                    <div
                      style={{
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#f4efef' : '#5d5959'
                      }}
                      className={`absolute rounded-full text-center flex justify-center items-center w-[99%] h-[99%]`}>
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === 'dark'
                              ? 'rgba(0,0,0,0.87)'
                              : '#ffffff',
                          px: 'calc(10px + 2vw)',
                          fontSize: '0.9vw'
                        }}>
                        Diverse user groups submit resolvable questions that
                        maximally reduce ignorance about an outcome.
                      </Typography>
                    </div>
                  </div>
                </Grow>
                <img
                  style={{ position: 'absolute' }}
                  src={GradientCircle}
                  alt=""
                  onMouseEnter={() => setGenerationHover(true)}
                  onMouseLeave={() => setGenerationHover(false)}
                />
              </div>
              <Typography
                variant="h4"
                style={{
                  textAlign: 'center',
                  marginTop: '5%',
                  fontSize: '2vw',
                  fontWeight: 'bold'
                }}>
                Generation
              </Typography>
            </div>
            <div
              style={{
                width: '25%',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <div
                style={{ position: 'relative', width: '100%', height: '16vw' }}>
                {!evaluationHover && <LandingPageEvaluation />}
                <Grow in={evaluationHover}>
                  <div className="absolute w-full h-full flex justify-center items-center">
                    <div
                      style={{
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#f4efef' : '#5d5959'
                      }}
                      className={`absolute rounded-full text-center flex justify-center items-center w-[99%] h-[99%]`}>
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === 'dark'
                              ? 'rgba(0,0,0,0.87)'
                              : '#ffffff',
                          px: 'calc(10px + 2.4vw)',
                          fontSize: '0.9vw'
                        }}>
                        Evaluation and ranking of questions by relevance,
                        resolvability and information value.
                      </Typography>
                    </div>
                  </div>
                </Grow>
                <img
                  style={{ position: 'absolute' }}
                  src={GradientCircle}
                  alt=""
                  onMouseEnter={() => setEvaluationHover(true)}
                  onMouseLeave={() => setEvaluationHover(false)}
                />
              </div>
              <Typography
                variant="h4"
                style={{
                  textAlign: 'center',
                  marginTop: '5%',
                  fontSize: '2vw',
                  fontWeight: 'bold'
                }}>
                Evaluation
              </Typography>
            </div>
            <div
              style={{
                width: '25%',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <div
                style={{ position: 'relative', width: '100%', height: '16vw' }}>
                {!forecastingHover && <LandingPageForecasting />}
                <Grow in={forecastingHover}>
                  <div className="absolute w-full h-full flex justify-center items-center">
                    <div
                      style={{
                        backgroundColor:
                          theme.palette.mode === 'dark' ? '#f4efef' : '#5d5959'
                      }}
                      className={`absolute rounded-full text-center flex justify-center items-center w-[99%] h-[99%]`}>
                      <Typography
                        sx={{
                          color:
                            theme.palette.mode === 'dark'
                              ? 'rgba(0,0,0,0.87)'
                              : '#ffffff',
                          px: 'calc(10px + 2vw)',
                          fontSize: '0.9vw'
                        }}>
                        Superforecasters make time bounded, probabilistic
                        predictions against questions.
                      </Typography>
                    </div>
                  </div>
                </Grow>
                <img
                  style={{ position: 'absolute' }}
                  src={GradientCircle}
                  alt=""
                  onMouseEnter={() => setForecastingHover(true)}
                  onMouseLeave={() => setForecastingHover(false)}
                />
              </div>
              <Typography
                variant="h4"
                style={{
                  textAlign: 'center',
                  marginTop: '5%',
                  fontSize: '2vw',
                  fontWeight: 'bold'
                }}>
                Forecasting
              </Typography>
            </div>
          </div>
        </div>
      </div>
      {width > 600 ? (
        <div
          style={{
            backgroundColor: theme.palette.mode === 'dark' ? '#001e3c' : 'white'
          }}>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'left',
              paddingTop: '3%',
              paddingBottom: '3%',
              width: '65%'
            }}>
            <Typography
              variant="h3"
              style={{
                textAlign: 'center',
                marginTop: '3%',
                fontSize: 'calc(10px + 2.2vw)',
                fontWeight: 'bold'
              }}>
              Decision Analysis
            </Typography>
            <Typography
              variant="h4"
              style={{
                marginTop: '5%',
                fontSize: 'calc(8px + 1.3vw)'
              }}>
              Explore and measure the impacts of your decisions. Understand the
              reasons behind movements in forecasts.
            </Typography>
            <div style={{ marginTop: '5vw', marginBottom: '7vw' }}>
              <ForecastDashboard
                outcomeId={''}
                title={
                  'Prospects for Ukrainian victory in the Russia-Ukraine conflict'
                }
                forecastingStartDate={'2023-10-10'}
                falseData={falseDashboardData}
                format={'landingpage'}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <div>
        <div
          style={{
            marginTop: '15%',
            marginBottom: '10%',
            marginLeft: 'auto',
            marginRight: 'auto',
            // borderStyle:'solid',borderColor:'red', borderWidth:'2px',
            position: 'relative',
            display: 'flex',
            width: '77%',
            height: '33vw'
          }}>
          <img
            alt=""
            style={{ position: 'absolute', top: '30%', width: '15vw' }}
            src={Hexagon}
            loading="lazy"
          />
          <Card
            style={{
              display: 'flex',
              width: '90%',
              height: '100%',
              marginLeft: 'auto'
            }}>
            {/* <img alt="" style={{ width: '28%', height: '100%' }} src={Gradient} loading="lazy"/> */}
            <div
              style={{
                width: '50%',
                height: '40vw',
                backgroundImage:
                  'linear-gradient(to bottom right,#FFF300,#FFA300,#FF6700)'
              }}
            />
            <div
              style={{ marginLeft: '5%', marginRight: '5%', marginTop: '10%' }}>
              <Typography style={{ marginTop: '7%', fontSize: '1.5vw' }}>
                Get in touch to explore how you can host your own Hivemind
                instance or participate now on the public platform.
              </Typography>
              <Button
                variant="outlined"
                style={{
                  fontSize: '1vw',
                  marginTop: '5%',
                  marginRight: '4%',
                  width: '35%'
                }}
                href="mailto:John.Hetherington@fujitsu.com">
                More
              </Button>
              <Button
                variant="contained"
                style={{ fontSize: '1vw', marginTop: '5%', width: '35%' }}
                component={Link}
                to="/outcomes">
                Let's try
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
