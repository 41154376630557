import {
  Badge,
  LocalPolice,
  Person,
  PsychologyAlt,
  Shield
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import React from 'react';
import { FiEye } from 'react-icons/fi';
import {
  GrUser,
  GrUserAdmin,
  GrUserExpert,
  GrUserPolice
} from 'react-icons/gr';

export default function RoleBadgeIcon({ role }) {
  switch (role) {
    case 'Admin':
      return (
        <Tooltip title="Admin">
          <div>
            <LocalPolice fontSize="inherit" color="primary" />
          </div>
        </Tooltip>
      );
    case 'Moderator':
      return (
        <Tooltip title="Moderator">
          <div>
            <Shield fontSize="inherit" color="primary" />
          </div>
        </Tooltip>
      );
    case 'OutcomeOwner':
      return (
        <Tooltip title="Outcome Owner">
          <div>
            <Badge fontSize="inherit" color="primary" />
          </div>
        </Tooltip>
      );
    case 'Questioner':
      return (
        <Tooltip title="Questioner">
          <div>
            <PsychologyAlt fontSize="inherit" color="primary" />
          </div>
        </Tooltip>
      );
    case 'Observer':
      return (
        <Tooltip title="Observer" color="primary">
          <div>
            <FiEye />
          </div>
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="User">
          <div>
            <Person fontSize="inherit" color="primary" />
          </div>
        </Tooltip>
      );
  }
}
